import { Money } from '@wix/ambassador-restaurants-catalogs-v3-catalog/types';

/**
 * Simple rety mechanisem
 *
 * - The supplied function can be `async.
 * - The supplied function receives one parameters
 *    1. A `Function` you can invoke to abort retying (bail)
 *    1. A `Number` identifying the attempt. The absolute first attempt (before any retries) is `0`.
 *
 *  If the function throws an error, the next attempt will run, else the return result will be returned.
 *
 * - The `opts`
 * - `retries`: The maximum amount of times to retry the operation. Default is `2`.
 * - `factor`: The exponential factor to use. Default is `2`.
 * - `minTimeout`: The number of milliseconds before starting the first retry. Default is `500`.
 * - `maxTimeout`: The maximum number of milliseconds between two retries. Default is `Infinity`.
 * - `onRetry`: an optional `Function` that is invoked after a new retry is performed. It's passed the `Error` that triggered it as a parameter.
 *
 *
 * The formula used to calculate the individual timeouts is:
 * `Math.min(random * minTimeout * Math.pow(factor, attempt), maxTimeout`
 */

export async function retry<T>(
  fn: (bail: (error: unknown) => void, attempt: number) => T,
  opts: {
    retries?: number;
    onRetry?: (error: unknown, attempt: number) => void;
    minTimeout?: number;
    maxTimeout?: number;
  } = {},
): Promise<T> {
  const default_opts = {
    retries: 5,
    onRetry: () => {},
    minTimeout: 500,
    maxTimeout: Infinity,
    ...opts,
  };

  return new Promise((resolve, reject) => {
    function bail(err: unknown) {
      reject(err || new Error('Aborted'));
    }

    function onError(error: unknown, attempt: number) {
      if (attempt >= default_opts.retries) {
        reject(error);
      } else {
        default_opts.onRetry(error, attempt);
        _retry(attempt, error);
      }
    }

    function _retry(attempt: number, _error: any) {
      const delay = Math.min(
        default_opts.minTimeout * Math.pow(2, attempt),
        default_opts.maxTimeout,
      );
      attempt += 1;

      setTimeout(() => {
        runAttempt(attempt);
      }, delay);
    }

    function runAttempt(attempt: number) {
      let maybePromise;

      try {
        maybePromise = fn(bail, attempt);
      } catch (error) {
        onError(error, attempt);
        return;
      }

      Promise.resolve(maybePromise)
        .then(resolve)
        .catch((error) => {
          onError(error, attempt);
        });
    }

    runAttempt(0);
  });
}

export const getPriceInCents = (price?: Money): number => {
  return getSafePrice(+(Number(price?.value) * 100).toFixed());
};

export const getSafePrice = (price?: number): number => {
  return price || 0;
};

export function getSafeStringValue(str?: string | null) {
  return str ?? '';
}
