import { Restaurant } from '../types/Restaurant';
import {
  getPriceWithoutCurrencyDifferentCases,
  fromPriceListToPriceRange,
  getPriceRangeValuesDifferentCases,
} from './seoPrice';
import { getOpeningHours } from './seoOpeningHours';
import { MEDIA_BASE_URL } from '../constants';
import _ from 'lodash';
import {
  DisplayableMenu,
  DisplayableMenuItem,
  DisplayableSection,
  DisplayableSubMenu,
} from '../types/Menu';
import {
  SeoAddress,
  SeoGeo,
  SeoMenu,
  SeoMenuItem,
  SeoMenuSection,
  SeoRestaurant,
  PriceRangeValue,
} from '../types/Seo';
import { extractLocalizedString } from './i18nLogic';

export function getSeoRestaurant(
  restaurant: Restaurant,
  displayableMenu: DisplayableMenu,
  baseUrl: string,
  menuUrl: string,
): SeoRestaurant {
  const seoAddress: SeoAddress = {
    '@type': 'PostalAddress',
    addressCountry: _.get(restaurant, 'address.countryCode'),
    addressLocality: _.get(restaurant, 'address.city'),
    postalCode: _.get(restaurant, 'address.postalCode'),
    streetAddress: _.get(restaurant, 'address.formatted'),
  };

  const geo: SeoGeo = {
    '@type': 'GeoCoordinates',
    latitude: _.get(restaurant, 'address.latLng.lat'),
    longitude: _.get(restaurant, 'address.latLng.lng'),
  };
  const priceRangeStr = getPriceRange(displayableMenu);
  return {
    '@id': baseUrl,
    '@type': 'Restaurant',
    Address: [seoAddress],
    geo,
    hasMenu: getSeoMenu(restaurant, displayableMenu),
    image: getImages(displayableMenu),
    name: extractLocalizedString(restaurant.title, restaurant.locale),
    openingHoursSpecification: getOpeningHours(restaurant),
    priceRange: priceRangeStr === '' ? undefined : priceRangeStr,
    telephone: _.get(restaurant, 'contact.phone'),
    url: baseUrl,
    menu: menuUrl,
  };
}

function getSeoMenu(
  restaurant: Restaurant,
  displayableMenu: DisplayableMenu,
): SeoMenu {
  return {
    '@type': 'Menu',
    name: extractLocalizedString(restaurant.title, restaurant.locale),
    description: extractLocalizedString(
      restaurant.description,
      restaurant.locale,
    ),
    hasMenuSection: getSubMenu(displayableMenu),
  };
}

function getSeoSubMenu(displayableSubMenu: DisplayableSubMenu): SeoMenuSection {
  const menuSections: DisplayableSection[] = _.get(
    displayableSubMenu,
    'sections',
    [],
  );
  return {
    '@type': 'MenuSection',
    name: _.get(displayableSubMenu, 'displayableTitle', ''),
    description: _.get(displayableSubMenu, 'displayableDescription', ''),
    image: getImagesFromAllSections(displayableSubMenu),
    priceRangeValues: getPriceRangeValuesFromSubMenu(displayableSubMenu),
    hasMenuSection: menuSections.map(getSeoMenuSection),
    hasMenuItem: [],
  };
}

function getSeoMenuSection(
  displayableSection: DisplayableSection,
): SeoMenuSection {
  const menuItems: DisplayableMenuItem[] = _.get(
    displayableSection,
    'items',
    [],
  );
  return {
    '@type': 'MenuSection',
    name: _.get(displayableSection, 'displayableTitle', ''),
    description: _.get(displayableSection, 'displayableDescription', ''),
    image: getImagesFromSingleSection(displayableSection),
    priceRangeValues: getPriceRangeValuesFromSections(displayableSection),
    hasMenuSection: [],
    hasMenuItem: menuItems.map(getSeoMenuItem),
  };
}

function getSeoMenuItem(displayableMenuItem: DisplayableMenuItem): SeoMenuItem {
  const safeDisplayablePrice = _.get(
    displayableMenuItem,
    'displayablePrice',
    '',
  );
  const safeDisplayableMinPrice = _.get(
    displayableMenuItem,
    'displayableMinPrice',
    '',
  );
  const safeDisplayableMaxPrice = _.get(
    displayableMenuItem,
    'displayableMaxPrice',
    '',
  );
  const seoItemPrice = getPriceWithoutCurrencyDifferentCases(
    safeDisplayablePrice,
    safeDisplayableMinPrice,
    safeDisplayableMaxPrice,
  );
  return {
    '@type': 'MenuItem',
    name: _.get(displayableMenuItem, 'displayableTitle', ''),
    description: _.get(displayableMenuItem, 'displayableDescription', ''),
    image: getImageFullPath(_.get(displayableMenuItem, 'image', '')),
    priceRangeValues: getPriceRangeValuesDifferentCases(
      safeDisplayablePrice,
      safeDisplayableMinPrice,
      safeDisplayableMaxPrice,
    ),
    offers: [
      {
        '@type': 'Offer',
        price: seoItemPrice === '' ? undefined : seoItemPrice,
        priceCurrency: _.get(displayableMenuItem, 'currency', ''),
      },
    ],
  };
}
export function getImageFullPath(displayableMenuItemImage: string) {
  const image = displayableMenuItemImage
    ? MEDIA_BASE_URL + displayableMenuItemImage
    : '';
  return image;
}

export function getPriceRange(displayableMenu: DisplayableMenu) {
  const priceObjectsList: PriceRangeValue[] = displayableMenu
    .map(
      (displayableSubMenu) =>
        getSeoSubMenu(displayableSubMenu).priceRangeValues,
    )
    .flat();
  return fromPriceListToPriceRange(priceObjectsList);
}

function getImages(displayableMenu: DisplayableMenu) {
  const images = displayableMenu.map(
    (displayableSubMenu) => getSeoSubMenu(displayableSubMenu).image,
  );
  return images.flat().filter((word) => word.length > 0);
}

function getSubMenu(displayableMenu: DisplayableMenu) {
  const seoSubMenu = displayableMenu.map(
    (displayableSubMenu) => getSeoSubMenu(displayableSubMenu), // single sub menu
  );
  return seoSubMenu;
}

function getImagesFromAllSections(displayableSubMenu: DisplayableSubMenu) {
  const sections: DisplayableSection[] = _.get(
    displayableSubMenu,
    'sections',
    [],
  );
  const seoMenuSectionImages = sections.map(
    (section) => getSeoMenuSection(section).image,
  );
  const seoMenuSectionImagesFlat = seoMenuSectionImages.flat();
  return seoMenuSectionImagesFlat;
}
function getPriceRangeValuesFromSubMenu(
  displayableSubMenu: DisplayableSubMenu,
) {
  const sections: DisplayableSection[] = _.get(
    displayableSubMenu,
    'sections',
    [],
  );
  const seoMenuSubMenuPriceRangeValues = sections.map(
    (section) => getSeoMenuSection(section).priceRangeValues,
  );
  const seoMenuSectionRangePricesFlat = seoMenuSubMenuPriceRangeValues.flat();
  return seoMenuSectionRangePricesFlat;
}

function getImagesFromSingleSection(displayableSection: DisplayableSection) {
  const items: DisplayableMenuItem[] = _.get(displayableSection, 'items', []);
  const images = items.map((item) => getSeoMenuItem(item).image);
  return images;
}
function getPriceRangeValuesFromSections(
  displayableSection: DisplayableSection,
) {
  const items: DisplayableMenuItem[] = _.get(displayableSection, 'items', []);
  const seoMenuSectionRangePrices = items
    .map((item) => getSeoMenuItem(item).priceRangeValues)
    .flat()
    .filter((PriceRangeObject) => PriceRangeObject.priceStr !== '');
  return seoMenuSectionRangePrices;
}
