import { connect } from 'react-redux';
import I18nify from '@wix/restaurants-components/dist/shared/I18nify';
import * as MenuActions from '@wix/restaurants-redux/dist/actions/MenuActions';
import * as LocationsActions from '@wix/restaurants-redux/dist/actions/LocationsActions';
import { getOrganizationMenu } from '@wix/restaurants-client-logic';
import { BI } from '@wix/wixrest-bi';
import { getVisibleLocations } from '../utils/navigation';
import MenusApp from './MenusApp';

const mapStateToProps = (state, ownProps) => {
  const compactMenusList = state.locations.compactMenusList;
  const visibleLocations =
    compactMenusList &&
    state.locations.locations &&
    state.styles.styles &&
    getVisibleLocations(
      state.locations.locations,
      state.locations.compactMenusList,
      state.styles.styles,
      ownProps.compId,
    );

  let currentLocation = state.locations.currentLocation;

  if (
    visibleLocations &&
    !visibleLocations.find(({ locationId }) => currentLocation === locationId)
  ) {
    currentLocation = visibleLocations.length
      ? (
          visibleLocations.find(({ isDefault }) => isDefault) ||
          visibleLocations[0]
        ).locationId
      : undefined;
  }

  return {
    i18n: state.i18n,
    menu: state.menu.menu,
    organization: state.organization.organization,
    styles: state.styles.styles,
    locations: visibleLocations,
    showEmptyState:
      compactMenusList &&
      state.locations.locations &&
      state.locations.locations.length &&
      !currentLocation,
    currentLocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganizationMenu: async (orgId, locationId) => {
      dispatch(
        LocationsActions.actions.setCurrentLocation({
          currentLocation: locationId,
        }),
      );
      const menu = await getOrganizationMenu(orgId, locationId, undefined, {
        retry: { retries: 5 },
      });
      BI.event(BI.EVENTS.LIVE_SITE_MENU_LOAD, {
        is_server_side_rendering: true,
        loading_time: Math.round(window.performance.now()),
        product: 'wixmenus.client',
        visitorId: WixInstance.getVisitorId(),
        locationGuid: locationId,
      });
      dispatch(MenuActions.actions.menuLoad({ menu }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(I18nify(MenusApp));
