import dataCapsule from '../DataCapsule';

const Key = 'multilocation_lastLocationId';

export function saveLocation(msid: string, locationId: string) {
  return dataCapsule.setItem(`${Key}_${msid}`, locationId);
}

export function loadLocation(msid: string) {
  return dataCapsule.getItem(`${Key}_${msid}`);
}
