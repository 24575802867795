import { Reducer } from 'redux';
import { Action, ActionCreator, ActionHandlers } from '../types/Redux';

export function createReducer<ReducerState>(
  initialState: ReducerState,
  handlers: ActionHandlers<ReducerState>,
): Reducer<ReducerState, Action<any>> {
  return function reducer(state = initialState, action): ReducerState {
    const handler = handlers[action.type];
    return handler ? handler(state, action.payload) : state;
  };
}

export function insertItem<T = any>(array: T[], index: number, item: T) {
  const newArray = array.slice();
  newArray.splice(index, 0, item);
  return newArray;
}

export function replaceItem<T = any>(array: T[], index: number, item: T) {
  const newArray = array.slice();
  newArray.splice(index, 1, item);
  return newArray;
}

export function removeItem<T = any>(array: T[], index: number) {
  if (index === -1) {
    return array;
  }

  const newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
}

export function pushItem<T = any>(array: T[], item: T) {
  const newArray = array.slice();
  newArray.splice(newArray.length, 0, item);
  return newArray;
}

export function createAction<Payload = void>(
  type: string,
): ActionCreator<Payload> {
  const action: ActionCreator<Payload> = (payload: Payload) => ({
    type,
    payload,
  });

  action.toString = () => type;

  return action;
}
