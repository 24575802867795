import React from 'react';
import LegacySingleColumn from '../components/legacy/RestaurantMenuTableViewSingleColumn.js';
import LegacyDoubleColumn from '../components/legacy/RestaurantMenuTableViewDoubleColumn.js';
import LegacyDoubleColumnSectionImages from '../components/legacy/RestaurantMenuTableViewDoubleColumnSectionImages.js';
import LegacyTripleColumn from '../components/legacy/RestaurantMenuTableViewTripleColumn.js';
import LegacyCenter from '../components/legacy/RestaurantMenuTableViewCenter.js';
import LegacyCenterDouble from '../components/legacy/RestaurantMenuTableViewCenterDouble.js';
import LegacyImageTop from '../components/legacy/RestaurantMenuTableViewImageTop.js';
import LegacyImageTopMobile from '../components/legacy/RestaurantMenuTableViewImageTopMobile.js';

require('../components/WixRestaurantMenuApplicationView.less');

const layoutIdToComponentMobile = {
  '0': LegacySingleColumn,
  '1': LegacyImageTopMobile,
  '2': LegacyDoubleColumn,
  '3': LegacyDoubleColumnSectionImages,
  '4': LegacyTripleColumn,
  '5': LegacyCenterDouble,
  '6': LegacyCenterDouble,
  '7': LegacyImageTopMobile,
  '10': LegacyDoubleColumn,
};

const layoutIdToComponentDesktop = {
  '0': LegacySingleColumn,
  '1': LegacySingleColumn,
  '2': LegacyDoubleColumn,
  '3': LegacyDoubleColumnSectionImages,
  '4': LegacyTripleColumn,
  '5': LegacyCenter,
  '6': LegacyCenterDouble,
  '7': LegacyImageTop,
  '10': LegacyDoubleColumn,
};

export function legacyLayoutSelector({ layoutId, layoutProps, isMobile }) {
  const LegacyLayout = isMobile
    ? layoutIdToComponentMobile['' + layoutId]
    : layoutIdToComponentDesktop['' + layoutId];

  const props = {
    ...layoutProps,
    properties: {},
    pictures: !isMobile && layoutId === 1,
    center: layoutId === 10,
  };

  if (LegacyLayout) {
    return (
      <div className="openrest_wixrestaurantmenuapplicationview_wrapper">
        <div className="maincontainer">
          {isMobile ? <props.MobileTabsComponent /> : <props.TabsComponent />}
          <LegacyLayout {...props} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
