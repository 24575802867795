import {
  createCsvFromJson,
  generateListOrdersParams,
  getCsvParams,
  getOrders,
  parseFields,
} from './utils';
import { ExportToCsvData, ExportToCsvParams } from './types';

export const exportToCsv = async ({
  httpClient,
  fieldsToDisplay,
  restaurantTimezone,
  restaurantLocation,
  startTime,
}: ExportToCsvParams): Promise<ExportToCsvData> => {
  try {
    const { fieldsForServer, fieldsForCsv } = parseFields(
      fieldsToDisplay,
      restaurantTimezone,
    );
    const listOrdersParams = generateListOrdersParams(
      fieldsForServer,
      startTime,
      restaurantLocation,
    );
    const orders = await getOrders(httpClient, listOrdersParams);
    const { csvOrders, csvFields } = getCsvParams(orders, fieldsForCsv);
    const csvString = createCsvFromJson(csvOrders, csvFields);
    return { csvString, ordersAmount: orders.length };
  } catch (error) {
    throw new Error(`Export to csv Error!: ${error}`);
  }
};
