import {
  PhoneNumberFormat as PNF,
  PhoneNumberUtil,
  PhoneNumberType,
} from 'google-libphonenumber';

export default {
  getExampleNumberForType(
    countryCode: string,
    device = PhoneNumberType.FIXED_LINE_OR_MOBILE,
  ) {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const phoneExampleArr = phoneUtil
        .getExampleNumberForType(countryCode, device)
        .getNationalNumber()
        ?.toString();
      return this.displayPhone(phoneExampleArr, countryCode);
    } catch (e) {
      return null;
    }
  },
  getExampleNumber(countryCode: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneExampleArr =
        phoneUtil.getExampleNumber(countryCode) &&
        phoneUtil.getExampleNumber(countryCode).getNationalNumber()?.toString();
      return this.displayPhone(phoneExampleArr, countryCode);
    } catch (e) {
      return null;
    }
  },
  validatePhone(str: string, countryCode: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(str, countryCode);
      if (!phoneUtil.isValidNumber(number)) {
        return null;
      }
      let e164 = phoneUtil.format(number, PNF.E164);
      const extension = number.getExtension();
      if (extension) {
        e164 += ';ext=' + extension;
      }
      return e164;
    } catch (e) {
      return null;
    }
  },
  displayPhone(phone: any, countryCode: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, countryCode);
      const displayed = phoneUtil.format(
        number,
        countryCode === phoneUtil.getRegionCodeForNumber(number)
          ? PNF.NATIONAL
          : PNF.INTERNATIONAL,
      );
      return displayed.replace(' ', '-');
    } catch (e) {
      return null;
    }
  },
  internationalizePhone(phone: any, countryCode: any) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, countryCode);
      return phoneUtil.format(number, PNF.E164);
    } catch (e) {
      return null;
    }
  },
};
