import React from 'react';
import ArrowIcon from './ArrowIcon';
import './Dropdown.less';

export default ({
  options,
  value,
  onChange,
  isMobile,
  dataHook,
  className,
}) => (
  <div className={`dropdown-container ${isMobile && 'mobile'} ${className}`}>
    <select
      className="dropdown"
      data-hook={dataHook}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option
          data-hook={`dropdown-option-${option.id}`}
          key={option.id}
          value={option.id}
        >
          {option.title}
        </option>
      ))}
    </select>
    <div className="dropdown-arrow">
      <ArrowIcon />
    </div>
  </div>
);
