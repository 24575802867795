import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { extractLocalizedString } from './i18nLogic';
import {
  DisplayableOrderItem,
  getDisplayablePrice,
} from './displayableOrderItem';
import { getIdealDeliveryArea, IdealDeliveryArea } from './addressLogic';
import { DispatchInfo, Restaurant } from '../types/Restaurant';
import { Address } from '../types/Address';
import { DispatchType } from '../types/Dispatch';
import { OrderItem } from '../types/OrderItem';
import { ChargeV2, Menu } from '../types/Menu';
import { Tip, TipType } from '../types/Payment';
import { Order, OrderCharge } from '../types/Order';
import { ConditionParams, evaluateCondition } from './condition';
import { ConditionReason } from '../types/Condition';
import { getAvailableDeliveryInfos } from './dateTimeLogic';
import {
  applyOperatorToLoyaltyDiscounts,
  isLoyaltyDiscount,
} from './redeemChargeLogic';
import { get as getProperties } from './propertiesLogic';
import {
  getOrderCharges,
  calculateTotalOrder,
  sumTaxCharges,
} from './orderLogic';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';
import { ServiceFee } from '../types/ServiceFee';

function calcDeliveryFee({
  totalOrderPrice,
  restaurant,
  address,
  dispatchType,
  dispatchTime,
  deliveryPartnerProps,
}: {
  totalOrderPrice: number;
  restaurant: Restaurant;
  address?: Address;
  dispatchType: DispatchType;
  dispatchTime: Moment;
  deliveryPartnerProps?: {
    deliveryPartnerFee?: number;
    shouldConsiderDeliveryPartner: boolean;
  };
}) {
  if (dispatchType === 'takeout' || !address) {
    return 0;
  }
  const considerPartner =
    deliveryPartnerProps && deliveryPartnerProps.shouldConsiderDeliveryPartner;
  const deliveryAreas = getAvailableDeliveryInfos(
    restaurant.deliveryInfos.filter(
      (di) =>
        di.type === 'delivery' &&
        (!considerPartner ? !di.deliveryProviderInfo : true),
    ),
    restaurant.timezone,
  );
  const hasSingleDeliveryArea = deliveryAreas.length === 1;
  const idealDeliveryArea: IdealDeliveryArea = getIdealDeliveryArea({
    totalOrderPrice,
    dispatchTime: dispatchTime.valueOf(),
    address,
    restaurant,
    deliveryPartnerProps,
  });
  if (
    idealDeliveryArea.isValid ||
    (considerPartner &&
      _.get(idealDeliveryArea, 'dispatchInfo.deliveryProviderInfo'))
  ) {
    return _.get(idealDeliveryArea.dispatchInfo, 'charge', 0);
  } else if (
    hasSingleDeliveryArea ||
    doAllDeliveryAreasHaveTheSameFee(deliveryAreas)
  ) {
    return _.get(deliveryAreas[0], 'charge', 0);
  } else {
    return 0;
  }
}

export interface GetPriceComponentsAndOrderChargesArgs {
  dispatchType: DispatchType;
  dispatchTime: Moment;
  orderItems: OrderItem[];
  source?: string;
  platform: string;
  chargesV2: ChargeV2[];
  restaurant: Restaurant;
  address?: Address;
  couponHashCode?: string;
  tip?: Tip;
  redeemAmount?: number;
  deliveryPartnerProps?: {
    deliveryPartnerFee?: number;
    shouldConsiderDeliveryPartner: boolean;
  };
  shouldCalculateTipFromSubtotal?: boolean;
  calculatedFees?: CalculatedFee[];
}

export function getPriceComponentsAndOrderCharges({
  dispatchType,
  dispatchTime,
  orderItems,
  source = '',
  platform,
  chargesV2: rawChargesV2,
  restaurant,
  address,
  couponHashCode,
  tip,
  redeemAmount,
  deliveryPartnerProps,
  shouldCalculateTipFromSubtotal,
  calculatedFees,
}: GetPriceComponentsAndOrderChargesArgs) {
  const chargesV2 = applyOperatorToLoyaltyDiscounts(rawChargesV2, redeemAmount);

  const orderCharges: OrderCharge[] = getOrderCharges({
    dispatchType,
    dispatchTime,
    orderItems,
    tip: tip?.tipType === TipType.TipTypeCurrencyAmount ? tip.amount : 0,
    source,
    platform,
    chargesV2,
    couponHashCode,
    timezone: restaurant.timezone,
    roundingStrategy: restaurant?.orders?.chargeRoundingStrategy,
  });

  const priceComponents = getPriceComponents({
    chargesV2,
    orderCharges,
    orderItems,
    restaurant,
    address,
    tip: tip?.tipType === TipType.TipTypeCurrencyAmount ? tip.amount : 0,
    dispatchType,
    dispatchTime,
    deliveryPartnerProps,
    calculatedFees,
  });

  // Temporarily handle percentage tip logic in here, should be included in logic library
  if (tip?.amount && tip?.tipType === TipType.TipTypePercent) {
    const tipBaseAmount = shouldCalculateTipFromSubtotal
      ? priceComponents.subtotal
      : priceComponents.total;

    priceComponents.tip = Math.round(tipBaseAmount * (tip.amount / 100));
    priceComponents.total += priceComponents.tip;
    orderCharges.push({
      chargeId: chargesV2.find((charge) => charge.type === 'tip')!.id,
      amount: priceComponents.tip,
    });
  }

  const displayablePriceComponents = getDisplayablePriceComponents(
    priceComponents,
    restaurant.locale,
    restaurant.currency,
    dispatchType,
    true,
    dispatchTime.valueOf(),
    address,
    restaurant,
    deliveryPartnerProps,
    calculatedFees,
  );

  return { priceComponents, displayablePriceComponents, orderCharges };
}

interface GetPriceComponentsArgs {
  chargesV2: ChargeV2[];
  orderCharges: OrderCharge[];
  orderItems: OrderItem[];
  restaurant: Restaurant;
  address?: Address;
  tip: number;
  dispatchType: DispatchType;
  dispatchTime: Moment;
  deliveryPartnerProps?: {
    deliveryPartnerFee?: number;
    shouldConsiderDeliveryPartner: boolean;
  };
  calculatedFees?: CalculatedFee[];
}

export interface PriceComponents {
  subtotal: number;
  tax: number;
  deliveryFee: number;
  tip: number;
  total: number;
  discountSubtotal: number;
  taxesAndServiceFeesTotalAmount: number;
}

export function getTaxAndServiceFeePriceDetails({
  calculatedFees,
  tax,
}: {
  calculatedFees?: CalculatedFee[];
  tax: number;
}) {
  let serviceFeesTax = 0;
  let serviceFeesTotalAmount = 0;
  calculatedFees &&
    calculatedFees.forEach((calcFee) => {
      serviceFeesTotalAmount += parseFloat(calcFee.fee!.value!) * 100;
      serviceFeesTax += parseFloat(calcFee.tax?.value || '0') * 100;
    });

  const taxesAndServiceFeesTotalAmount =
    tax + serviceFeesTotalAmount + serviceFeesTax;

  return {
    serviceFeesTotalAmount,
    serviceFeesTax,
    taxesAndServiceFeesTotalAmount,
  };
}

function getPriceComponents({
  chargesV2,
  orderCharges,
  orderItems,
  restaurant,
  address,
  tip,
  dispatchType,
  dispatchTime,
  deliveryPartnerProps,
  calculatedFees,
}: GetPriceComponentsArgs): PriceComponents {
  const taxCharges = sumTaxCharges({ chargesV2, orderCharges });
  const tax = Math.max(taxCharges ?? 0, 0);
  const {
    serviceFeesTotalAmount,
    serviceFeesTax,
    taxesAndServiceFeesTotalAmount,
  } = getTaxAndServiceFeePriceDetails({ tax, calculatedFees });

  const total = Math.max(
    calculateTotalOrder({
      orderItems,
      orderCharges,
      serviceFeesTotalAmount,
      serviceFeesTax,
    }),
    0,
  );
  const discountSubtotal = orderCharges.reduce((pre, cur) => {
    const charge = _.find(chargesV2, (c) => c.id === cur.chargeId);
    return pre + (charge && charge.type === 'discount' ? cur.amount : 0);
  }, 0);

  const subtotal = total - taxesAndServiceFeesTotalAmount - tip;

  const deliveryFee = calcDeliveryFee({
    totalOrderPrice: subtotal,
    address,
    restaurant,
    dispatchType,
    dispatchTime,
    deliveryPartnerProps,
  });

  return {
    subtotal,
    tax: tax + serviceFeesTax,
    deliveryFee,
    tip,
    total: total + deliveryFee,
    discountSubtotal,
    taxesAndServiceFeesTotalAmount,
  };
}

interface GetRelevantChargesArgs {
  chargesV2: ChargeV2[];
  dispatchTime: Moment;
  dispatchType: DispatchType;
  totalOrderPrice: number;
  platform: string;
  couponHashCode?: string;
}

function getRelevantCharges({
  chargesV2,
  dispatchTime,
  dispatchType,
  totalOrderPrice,
  platform,
  couponHashCode,
}: GetRelevantChargesArgs): ChargeV2[] {
  return _(chargesV2)
    .filter((charge) => charge.type === 'discount')
    .filter((charge) => charge.state === 'operational')
    .filter((charge) =>
      isChargeRelevant(charge, {
        dispatchTime,
        dispatchType,
        totalOrderPrice,
        platform,
        couponHashCode,
      }),
    )
    .value();
}

function isChargeRelevant(
  charge: ChargeV2,
  conditionParams: ConditionParams,
): boolean {
  const { reasons } = evaluateCondition(charge.condition, conditionParams);
  return (
    reasons.length === 0 ||
    (reasons.length === 1 && reasons[0].type === 'order_items_price')
  );
}

function getServiceFees(
  calculatedFees: CalculatedFee[],
  currency: string,
  locale: string,
): ServiceFee[] {
  return calculatedFees.map((calcFee) => {
    return {
      amount: getDisplayablePrice(
        parseFloat(calcFee.fee?.value || '0') * 100,
        locale,
        currency,
      ),
      name: calcFee.name,
      id: calcFee.ruleId!,
      tax: getDisplayablePrice(
        parseFloat(calcFee.tax?.value || '0') * 100,
        locale,
        currency,
      ),
    };
  });
}
function getAmountByGroupId(
  chargesV2: ChargeV2[],
  orderCharges: OrderCharge[],
): Record<string, number> {
  const orderChargeById = _(orderCharges)
    .keyBy('chargeId')
    .mapValues((value) => value.amount || 0)
    .value();

  return _(chargesV2)
    .groupBy((charge) => getProperties(charge, 'groupId'))
    .mapValues((charges) =>
      _(charges)
        .map((charge) => orderChargeById[charge.id])
        .sum(),
    )
    .value();
}

export interface GetDisplayableDiscounts {
  menu?: Menu;
  chargesV2?: ChargeV2[];
  dispatchTime: Moment;
  dispatchType: DispatchType;
  totalOrderPrice: number;
  platform: string;
  couponHashCode?: string;
  orderCharges: OrderCharge[];
  restaurant: Restaurant;
}

export interface DisplayableDiscount {
  title: string;
  description: string;
  amount: string;
  isCoupon: boolean;
  isLoyalty: boolean;
  minPrice?: number;
  displayableMinPrice?: string;
  errors: ConditionReason[];
}

export function getDisplayableDiscounts({
  menu,
  chargesV2,
  dispatchTime,
  dispatchType,
  totalOrderPrice,
  platform,
  couponHashCode,
  orderCharges,
  restaurant,
}: GetDisplayableDiscounts): DisplayableDiscount[] {
  const _chargesV2 = menu?.chargesV2 || chargesV2 || [];
  const relevantCharges = getRelevantCharges({
    chargesV2: _chargesV2,
    dispatchTime,
    dispatchType,
    totalOrderPrice,
    platform,
    couponHashCode,
  });
  const amountByGroupId = getAmountByGroupId(_chargesV2, orderCharges);
  const { locale, currency } = restaurant;
  const result = _(relevantCharges)
    .map((charge) => {
      const minPrice = getMinPrice(charge);
      return {
        title: extractLocalizedString(charge.title, locale),
        description: extractLocalizedString(charge.description, locale),
        groupId: getProperties(charge, 'groupId'),
        isCoupon: isCoupon(charge),
        isLoyalty: isLoyaltyDiscount(charge),
        minPrice,
        displayableMinPrice:
          typeof minPrice === 'number'
            ? getDisplayablePrice(
                minPrice,
                restaurant.locale,
                restaurant.currency,
              )
            : undefined,
        restrictedByUserCouponCode:
          !!couponHashCode &&
          isChargeRestrictedByCouponHashCode(charge, couponHashCode),
        errors: evaluateCondition(charge.condition, {
          dispatchTime,
          dispatchType,
          totalOrderPrice,
          platform,
          couponHashCode,
        }).reasons,
      };
    })
    .uniqWith(_.isEqual)
    .map((charge) => ({
      ...charge,
      amount: amountByGroupId[charge.groupId] || 0,
    }))
    .filter(
      (charge) =>
        charge.restrictedByUserCouponCode ||
        charge.amount !== 0 ||
        !!charge.errors.find((error) => error.type === 'order_items_price'),
    )
    .map((charge) => ({
      ...charge,
      amount: getDisplayablePrice(charge.amount, locale, currency),
      groupId: undefined,
    }))
    .value();

  result.forEach((displayableDiscount) => {
    delete displayableDiscount.groupId;
  });

  result.sort((a, b) => {
    if (a.isCoupon && !b.isCoupon) {
      return 1;
    } else if (!a.isCoupon && b.isCoupon) {
      return -1;
    } else {
      return 0;
    }
  });

  return result;
}

// eslint-disable-next-line no-shadow
enum DisplayableSummaryPriceComponents {
  total = 'total',
  subtotal = 'subtotal',
}

export interface DisplayablePriceComponents {
  [DisplayableSummaryPriceComponents.subtotal]: string;
  tax: string;
  serviceFees?: ServiceFee[];
  taxesAndServiceFeesTotalAmount?: string;
  deliveryFee: string;
  tip: string;
  [DisplayableSummaryPriceComponents.total]: string;
  discountSubtotal: string;
  isEstimatedDeliveryFee: boolean;
}

function getDisplayablePriceComponents(
  priceComponents: PriceComponents,
  locale: string,
  currency: string,
  dispatchType: DispatchType,
  filterOutZeroPrice?: boolean,
  dispatchTime?: number,
  address?: Address,
  restaurant?: Restaurant,
  deliveryPartnerProps?: {
    deliveryPartnerFee?: number;
    shouldConsiderDeliveryPartner: boolean;
  },
  calculatedFees?: CalculatedFee[],
): DisplayablePriceComponents {
  const result: Partial<DisplayablePriceComponents> = {};
  const keys = Object.keys(priceComponents) as (keyof PriceComponents)[];
  const shouldFilterZeroValueComponent = (key: keyof PriceComponents) =>
    filterOutZeroPrice &&
    priceComponents[key] === 0 &&
    !Object.values(DisplayableSummaryPriceComponents).includes(key as any);

  keys.forEach((key) => {
    if (shouldFilterZeroValueComponent(key)) {
      return;
    }
    result[key] = getDisplayablePrice(priceComponents[key], locale, currency);
  });

  if (calculatedFees) {
    result.serviceFees = getServiceFees(calculatedFees, currency, locale);
  }

  const isEstimatedDeliveryFee = getIsEstimatedDeliveryFee({
    dispatchType,
    address,
    restaurant,
    dispatchTime,
    totalOrderPrice: priceComponents.total,
    deliveryPartnerProps,
  });

  return { ...result, isEstimatedDeliveryFee } as DisplayablePriceComponents;
}

function getIsEstimatedDeliveryFee({
  dispatchType,
  restaurant,
  address,
  dispatchTime,
  totalOrderPrice,
  deliveryPartnerProps,
}: {
  dispatchType: DispatchType;
  dispatchTime?: number;
  totalOrderPrice: number;
  restaurant?: Restaurant;
  address?: Address;
  deliveryPartnerProps?: {
    deliveryPartnerFee?: number;
    shouldConsiderDeliveryPartner: boolean;
  };
}): boolean {
  if (dispatchType !== 'delivery') {
    return false;
  }
  const idealDeliveryArea =
    address &&
    restaurant &&
    getIdealDeliveryArea({
      totalOrderPrice,
      dispatchTime,
      address,
      restaurant,
      deliveryPartnerProps,
    });
  if (
    idealDeliveryArea &&
    _.get(idealDeliveryArea, 'dispatchInfo.deliveryProviderInfo')
  ) {
    return _.get(deliveryPartnerProps, 'deliveryPartnerFee') === undefined;
  }
  const considerPartner =
    deliveryPartnerProps && deliveryPartnerProps.shouldConsiderDeliveryPartner;
  const availableDeliveryInfos = getAvailableDeliveryInfos(
    restaurant?.deliveryInfos.filter(
      (di) =>
        di.type === 'delivery' &&
        (!considerPartner ? !di.deliveryProviderInfo : true),
    ) || [],
    restaurant?.timezone || '',
  );
  const numberOfDeliveryAreas = availableDeliveryInfos.length || 0;

  if (
    numberOfDeliveryAreas === 1 ||
    doAllDeliveryAreasHaveTheSameFee(availableDeliveryInfos)
  ) {
    return false;
  }

  return dispatchType === 'delivery' && restaurant
    ? idealDeliveryArea
      ? !idealDeliveryArea.isValid
      : true
    : false;
}

export function countItemsInCart(
  displayableOrderItems: DisplayableOrderItem[],
) {
  return displayableOrderItems.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0,
  );
}

const meaninglessConditionParams: ConditionParams = {
  platform: '',
  dispatchType: 'takeout',
  dispatchTime: moment(),
};

export function isCoupon(charge: ChargeV2) {
  const { reasons } = evaluateCondition(
    charge.condition,
    meaninglessConditionParams,
  );

  return !!reasons.find((reason) => reason.type === 'order_coupon');
}

function getMinPrice(charge: ChargeV2): number | undefined {
  const { reasons } = evaluateCondition(
    charge.condition,
    meaninglessConditionParams,
  );

  const reason = reasons.find((r) => r.type === 'order_items_price');

  if (reason && reason.type === 'order_items_price' && reason.min) {
    return reason.min;
  } else {
    return undefined;
  }
}

export function isChargeRestrictedByCouponHashCode(
  charge: ChargeV2,
  couponHashCode: string,
): boolean {
  const { reasons } = evaluateCondition(charge.condition, {
    ...meaninglessConditionParams,
    couponHashCode,
  });

  const isRestrictedByGivenCoupon = !reasons.find(
    (reason) => reason.type === 'order_coupon',
  );

  return isCoupon(charge) && isRestrictedByGivenCoupon;
}

export function shouldVerifyOrderWithSms(order: Order) {
  const hasSingleOfflinePayment =
    order.payments?.length === 1 &&
    order.payments[0].type === 'cashier' &&
    order.payments[0].paymentMethod === 'offline';

  const isOrderPending = order.status === 'pending';

  return isOrderPending && hasSingleOfflinePayment;
}

function doAllDeliveryAreasHaveTheSameFee(deliveryAreas: DispatchInfo[]) {
  return (
    _(deliveryAreas)
      .map((di) => di.charge)
      .uniq()
      .value().length === 1
  );
}
