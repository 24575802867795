import { IWixWindowViewMode } from '@wix/native-components-infra/dist/es/src/types/types';

export interface Instance {
  metaSiteId: string;
  appDefId: string;
  instanceId: string;
  aid: string;
  siteOwnerId: string;
  siteIsTemplate?: boolean;
  uid?: string;
}

export interface Settings {
  showDishImages: boolean;
  showSectionImages: boolean;
  showDescriptions: boolean;
  showLabels: boolean;
  layout: 'side-by-side' | 'card';
  hasMembersAreaIntegration?: boolean;
  showMenuDescription: boolean;
  showSectionDescription: boolean;
  dishCardAlignment?: AlignmentOptions;
  menuHeaderAlignment?: AlignmentOptions;
}

// eslint-disable-next-line no-shadow
export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  EMPTY = '',
}

export interface LabelsSettings {
  iconTypeId: number;
  colorTypeId: number;
  iconPrimaryColor?: string;
  iconSecondaryColor?: string;
  iconCustomPrimaryColor?: string;
  iconCustomSecondaryColor?: string;
}

export interface PlatformParams {
  compClassName: string;
  isEditorX: boolean;
  isMobile: boolean;
  isRTL: boolean;
  compId: string;
  instance: Instance;
  settings: Settings;
  isViewer?: boolean;
  signedInstance?: string;
  viewMode: IWixWindowViewMode;
  labelsSettings?: LabelsSettings;
}
