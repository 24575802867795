require('./RestaurantMenuTableViewImageTop.less');

import ImageUtils from 'openrest4js/dist/openrest4js-helpers/Image';
import WixRestIcon from '@wix/restaurants-components/dist/web/WixRestIcon/WixRestIcon';

import clamp from 'clamp-js';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;
            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                properties={This.props.properties}
                picture={data.picture}
                children={children}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuSubCategory';

  state = {
    size: 'twos400',
  };

  render() {
    const This = this;
    const children = JSON.parse(JSON.stringify(this.props.children));
    const styles = this.props.styles;
    const properties = this.props.properties;

    let descriptionClass = 'description ';
    if (!this.props.description) {
      descriptionClass += 'hidden ';
    }

    if (this.props.picture) {
      const sectionImageStyle = {
        border: '1px solid',
        borderColor: styles.colors['wixmenu-subcategoryimageborder'],
      };

      const background = {
        backgroundImage:
          'url(' +
          ImageUtils.fillSharp({
            url: this.props.picture,
            width: 900,
            height: 295,
          }) +
          ')',
        margin: '7px',
      };

      var backgroundDiv = (
        <div className="background-wrapper" style={sectionImageStyle}>
          <div className="background" style={background}></div>
        </div>
      );
    } else {
      var backgroundDiv = <span></span>;
    }

    _.defer(() => {
      if (!This.wrapper) return;
      if (typeof window === 'undefined') return;
      const width = $(ReactDOM.findDOMNode(This.wrapper)).width();

      // console.log('[RestaurantMenuTableViewImageTop] Width', width);

      let newSize = 'ones';
      if (width > 900) {
        newSize = 'threes';
      } else if (width > 700) {
        newSize = 'threes';
      } else if (width > 600) {
        newSize = 'twos';
      } else if (width > 400) {
        newSize = 'twos';
      }

      if (newSize != This.state.size) {
        This.setState({ size: newSize });
      }
    });

    const titleStyle = {};

    if (!styles.fonts['wixmenu-subcategorytitlexl'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }

    return (
      <div
        className={'subcategory ' + this.state.size}
        data-id={this.props.id}
        ref={e => (this.wrapper = e)}
      >
        {backgroundDiv}
        <h1 className="title" style={titleStyle}>
          {this.props.title}
        </h1>
        <div className={descriptionClass}>{this.props.description}</div>
        <div className="titledescriptionpadding"></div>

        <div className="subcategorychildren">
          {_.map(children, function(item) {
            const data = item.data || {};
            const children = item.children;
            if (!data.title) {
              return <div></div>;
            } else {
              return (
                <REACT_MENU_ITEM
                  title={data.title}
                  picture={data.picture}
                  description={data.description}
                  price={data.price}
                  children={children}
                  styles={styles}
                  properties={properties}
                  featured={data.featured}
                  key={This.props.id + '-' + data.id}
                  id={This.props.id + '-' + data.id}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_ITEM extends React.Component {
  static displayName = 'legacy/ReactMenuItem';

  render() {
    const styles = this.props.styles;
    const properties = this.props.properties;
    const priceHidden = this.props.priceHidden;
    //var priceHidden = !styles.booleans['wixmenu.widgets.price'];

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({ item: true, featured: this.props.featured });
    const descriptionClasses = cx({ description: true });
    const priceClasses = cx({ price: true, hidden: priceHidden });

    const This = this;

    const titleStyle = {
      fontWeight: 'bold !important',
      position: 'relative',
    };

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    _.defer(() => {
      if (typeof window !== 'undefined') {
        const el = This.clamp;

        if (el) {
          clamp(ReactDOM.findDOMNode(el), { clamp: 3 });
        }
      }
    });

    return (
      <div
        className={itemClasses}
        data-is-last-item={this.props.isLastItem}
        style={this.props.itemstyle}
      >
        <REACT_MENU_ITEM_IMAGE
          src={this.props.picture}
          featured={this.props.featured}
          styles={styles}
          properties={properties}
        />
        <div className="titledescription">
          <div className="title" ref="title" style={titleStyle}>
            <span ref={e => (this.clamp = e)}>{this.props.title}</span>
          </div>
          <div className={descriptionClasses}>{this.props.description}</div>
          <div className={priceClasses} style={priceStyle}>
            {this.props.price}
          </div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                title={data.title}
                description={data.description}
                price={data.price}
                styles={styles}
                properties={properties}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_ITEM_IMAGE extends React.Component {
  static displayName = 'legacy/ReactMenuItemImage';

  render() {
    const styles = this.props.styles;

    const imageStyle = styles.numbers['wixmenu.widgets.imagesstyle'];

    let itemclasses = 'itemimage ';
    if (imageStyle === 1) {
      itemclasses += 'wide';
    }

    if (this.props.src) {
      const style = {
        backgroundImage:
          'url(' +
          ImageUtils.fillSharp({
            url: this.props.src,
            width: 225,
            height: 169,
          }) +
          ')',
      };

      if (this.props.featured) {
        return (
          <div>
            <REACT_MENU_ITEM_FEATURED />
            <div className={itemclasses} style={style} id="itemimage"></div>
          </div>
        );
      } else {
        return <div className={itemclasses} style={style}></div>;
      }
    } else {
      return (
        <div className={itemclasses + 'noimage'}>
          <WixRestIcon width={65} height={48} name="image" />
        </div>
      );
    }
  }
}

class REACT_MENU_ITEM_FEATURED extends React.Component {
  static displayName = 'legacy/ReactMenuItemFeatured';

  render() {
    return (
      <svg width="253px" height="255px" viewBox="0 0 250 255">
        <rect x="0" y="5" width="245" height="245" fill="none" />
        <g className="star">
          <path
            fillRule="evenodd"
            d="M 125 32C 125 32 103 43 103 43 103 43 103 0 103 0 103 0 147 0 147 0 147 0 147 43 147 43 147 43 125 32 125 32Z"
          />
          <path
            fillRule="evenodd"
            d="M 125.5 7C 125.5 7 127.59 13.87 127.59 13.87 127.59 13.87 135 13.88 135 13.88 135 13.88 128.88 17.65 128.88 17.65 128.88 17.65 131.37 25 131.37 25 131.37 25 125.5 19.98 125.5 19.98 125.5 19.98 119.63 25 119.63 25 119.63 25 122.12 17.65 122.12 17.65 122.12 17.65 116 13.88 116 13.88 116 13.88 123.41 13.87 123.41 13.87 123.41 13.87 125.5 7 125.5 7"
          />
        </g>
      </svg>
    );
  }
}

class REACT_MENU_VARIATIONITEM extends React.Component {
  static displayName = 'lagacy/ReactMenuVariationItem';

  render() {
    //var styles = this.props.styles;
    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    // let defaultStr = (Wix.Utils.getCompId() || Wix.Utils.getOrigCompId())
    // let priceHidden = !styles.booleans[defaultStr + '.wixmenu.widgets.price'];
    // let variationsHidden = !styles.booleans[defaultStr + '.wixmenu.widgets.variations'];

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const priceClasses = cx({ price: true, hidden: priceHidden });
    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });

    const titleStyle = {
      fontStyle: 'normal !important',
    };

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
      >
        <div className="title" style={titleStyle}>
          {this.props.title}
        </div>
        <div className={priceClasses} style={priceStyle}>
          {this.props.price}
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  static displayName = 'menus/web/legacy/RestaurantMenuTableViewImageTop.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    return (
      <div className="openrest_restaurantmenutableviewimagetop_legacy_wrapper">
        {_.map(menu, function(category) {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={This.props.styles}
              properties={This.props.properties}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewImageTopLegacy] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + '"]',
    );
    console.log(
      '[RestaurantMenuTableViewImageTopLegact] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewImageTopLegacy] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
