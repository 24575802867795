require('./RestaurantMenuTableViewSingleColumn.less');

import I18n from '@wix/wixrest-utils/dist/I18n';
import ImageUtils from 'openrest4js/dist/openrest4js-helpers/Image';
import WixRestIcon from '@wix/restaurants-components/dist/web/WixRestIcon/WixRestIcon';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import createReactClass from 'create-react-class';

import RestaurantMenuTableViewDottedLine from '../../logic/RestaurantMenuTableViewDottedLine.js';

const { I18N } = I18n.getComponentHeader('openrest_wixrestaurantmenuview_');

//* ************************ Templates
class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="title">{this.props.title}</div>
        <div className="description">{this.props.description}</div>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;

            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                picture={data.picture}
                children={children}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                sectionImagesHidden={This.props.sectionImagesHidden}
                isMobile={This.props.isMobile}
                pictures={This.props.pictures}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'ReactMenuSubCategory';

  render() {
    const This = this;
    const children = JSON.parse(JSON.stringify(this.props.children));

    const descriptionHidden = this.props.descriptionHidden;

    let titleClass = 'title topbottomborders ';

    let descriptionClass = 'description ';
    if (!this.props.description || descriptionHidden) {
      descriptionClass += 'hidden ';
      titleClass += 'nodescription';
    }

    const styles = this.props.styles;

    const titleStyle = {};
    if (!styles.fonts['wixmenu-subcategorytitle'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }

    return (
      <div className="subcategory" data-id={this.props.id} ref="wrapper">
        <div className="titledescription">
          <h1 className={titleClass} style={titleStyle}>
            {this.props.title}
          </h1>
          <div className={descriptionClass}>{this.props.description}</div>
        </div>
        <div className="subcategorychildren">
          {_.map(children, function(item, index) {
            const data = item.data || {};
            const _children = item.children;
            return (
              <REACT_MENU_ITEM
                title={data.title}
                description={data.description}
                price={data.price}
                children={_children}
                picture={data.picture}
                styles={styles}
                featured={data.featured}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                isMobile={This.props.isMobile}
                pictures={This.props.pictures}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
                isLastItem={index === children.length - 1}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const REACT_MENU_ITEM = createReactClass({
  mixins: [RestaurantMenuTableViewDottedLine],
  displayName: 'ReactMenuItem',
  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const styles = this.props.styles;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({
      item: true,
      pictures: this.props.pictures,
      featured: this.props.featured,
    });

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden,
    });

    const This = this;

    this._fixDottedLine({
      shouldFixFunc() {
        if (priceHidden || !This.props.price || This.props.isMobile) {
          return false;
        }
        return true;
      },

      alignLineRef:
        descriptionHidden || !This.props.description ? 'title' : 'description',
      priceRef: 'price',
    });

    let titleDots = null;
    let descriptionDots = null;

    if (!priceHidden && this.props.price && !this.props.isMobile) {
      if (!this.props.description || descriptionHidden) {
        titleDots = this._getDotsDom();
      } else {
        descriptionDots = this._getDotsDom();
      }
    }

    const bottomDottedLineStyle = {
      width: '100%',
      display: this.props.isMobile ? 'block' : 'none',
    };

    var svgDisplay = null;
    var picture = null;

    if (this.props.pictures) {
      var svgDisplay = this.props.featured ? (
        <REACT_MENU_ITEM_FEATURED_SVG />
      ) : null;
      var picture = (
        <REACT_MENU_ITEM_IMAGE
          src={this.props.picture}
          styles={this.props.styles}
          placeholderHidden={this.props.placeholderHidden}
        />
      );
    }

    const displayFeatured =
      this.props.featured && !this.props.pictures ? (
        <REACT_MENU_ITEM_FEATURED />
      ) : null;
    return (
      <div className={itemClasses} data-is-last-item={this.props.isLastItem}>
        {picture}
        <div className="titledescription">
          <div className="title" ref="title">
            {svgDisplay} {this.props.title} {displayFeatured}
            {titleDots}
          </div>
          <div className={descriptionClasses} ref="description">
            {this.props.description}
            {descriptionDots}
          </div>
          <div className={priceClasses} ref="price">
            {this.props.price}
          </div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                title={data.title}
                description={data.description}
                price={data.price}
                styles={styles}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                isMobile={This.props.isMobile}
                pictures={This.props.pictures}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </div>
        <div className="dottedline" style={bottomDottedLineStyle}>
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . .
        </div>
      </div>
    );
  },
});

class REACT_MENU_ITEM_FEATURED_SVG extends React.Component {
  static displayName = 'ReactMenuItemFeatured';

  render() {
    return (
      <svg width="23px" height="24px" viewBox="0 0 23 24">
        <path
          fillRule="evenodd"
          d="M 11.5 17.86C 11.5 17.86-0 24-0 24-0 24-0 0-0 0-0 0 23 0 23 0 23 0 23 24 23 24 23 24 11.5 17.86 11.5 17.86Z"
        />
        <path
          className="star"
          fillRule="evenodd"
          d="M 6.5 0C 6.5 0 7.93 4.58 7.93 4.58 7.93 4.58 12.99 4.58 12.99 4.58 12.99 4.58 8.81 7.09 8.81 7.09 8.81 7.09 10.51 11.98 10.51 11.98 10.51 11.98 6.5 8.64 6.5 8.64 6.5 8.64 2.49 11.98 2.49 11.98 2.49 11.98 4.2 7.09 4.2 7.09 4.2 7.09 0.01 4.58 0.01 4.58 0.01 4.58 5.08 4.58 5.08 4.58 5.08 4.58 6.5 0 6.5 0"
        />
      </svg>
    );
  }
}

class REACT_MENU_ITEM_FEATURED extends React.Component {
  static displayName = 'ReactMenuItemFeatured';

  render() {
    return <span className="special">{I18N('special', 'Special')}</span>;
  }
}

class REACT_MENU_ITEM_IMAGE extends React.Component {
  static displayName = 'ReactMenuItemImage';

  render() {
    const styles = this.props.styles;

    const imageStyle = styles.numbers['wixmenu.widgets.imagesstyle'];

    let itemclasses = 'itemimage ';
    if (imageStyle === 1) {
      itemclasses += 'wide';
    }

    if (this.props.src) {
      const style = {
        backgroundImage:
          'url(' +
          ImageUtils.fillSharp({
            url: this.props.src,
            width: 120,
            height: 120,
          }) +
          ')',
      };

      return <div className="itemimage" style={style}></div>;
    } else {
      return (
        <div className={itemclasses + 'noimage'}>
          <WixRestIcon width={55} height={41} name="image" />
        </div>
      );
    }
  }
}

const REACT_MENU_VARIATIONITEM = createReactClass({
  displayName: 'ReactMenuVariationItem',
  mixins: [RestaurantMenuTableViewDottedLine],
  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden || this.props.isMobile,
    });

    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });

    const This = this;

    let titleDots = null;
    let descriptionDots = null;

    if (!priceHidden && this.props.price && !this.props.isMobile) {
      if (!this.props.description || descriptionHidden) {
        titleDots = this._getDotsDom();
      } else {
        descriptionDots = this._getDotsDom();
      }
    }

    this._fixDottedLine({
      shouldFixFunc() {
        if (priceHidden || !This.props.price || This.props.isMobile) {
          return false;
        }
        return true;
      },

      alignLineRef:
        descriptionHidden || !This.props.description ? 'title' : 'description',
      priceRef: 'price',
    });

    if (this.props.isMobile) {
      var title = (
        <span>
          <span>{this.props.title}</span>
          <span style={{ padding: '0px 5px' }}></span>
          <span>{this.props.price}</span>
        </span>
      );
    } else {
      var title = this.props.title;
    }

    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
      >
        <div className="titledescription">
          <div className="title" ref="title">
            {title}
            {titleDots}
          </div>
          <div className={descriptionClasses} ref="description">
            {this.props.description}
            {descriptionDots}
          </div>
        </div>
        <div className={priceClasses} ref="price">
          {this.props.price}
        </div>
      </div>
    );
  },
});

export default class extends React.Component {
  static displayName = 'menus/web/RestaurantMenuTableViewSingleColumn.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    const styles = this.props.styles;

    const mode = this.props.isMobile ? 'mobile' : 'web';

    return (
      <div
        className={
          'openrest_restaurantmenutableviewsinglecolumn_legacy_wrapper ' + mode
        }
        ref="wrapper"
      >
        {_.map(menu, function(category) {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={styles}
              descriptionHidden={This.props.descriptionHidden}
              priceHidden={This.props.priceHidden}
              variationsHidden={This.props.variationsHidden}
              sectionImagesHidden={This.props.sectionImagesHidden}
              isMobile={This.props.isMobile}
              pictures={This.props.pictures}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewSingleColumn] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + ']',
    );
    console.log(
      '[RestaurantMenuTableViewSingleColumn] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewSingleColumn] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
