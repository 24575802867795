/**
 * Return an instance id for the default menu to display if none exist.
 */
export const getDefaultInstanceId = (uxLanguage: string) => {
  switch (uxLanguage) {
    case 'pt':
      return '8b4b3788-fe3b-4a18-9f66-d8511619f3d8';

    case 'ru':
      return '134a723c-7688-4ff0-ba95-d95bb8c7d16d';

    case 'ko':
      return 'a4a59b58-3afa-422b-8123-1984ddb2d1db';

    case 'de':
      return '98b295c5-9aff-4225-8649-12f878c24df1';

    case 'tr':
      return 'e4d96b9a-88e3-4047-af83-6b5be0af05c4';

    case 'no':
      return 'f3bade9a-4e54-4b9b-9926-f379828191b4';

    case 'da':
      return '6261eac0-3f3a-472e-84f1-d3ca67e348cb';

    case 'pl':
      return '25347fe6-27c3-4663-ac2b-9182e1b10898';

    case 'nl':
      return '8fc7ffc0-5a50-4177-8a6f-95233d5807a3';

    case 'jp':
      return 'd7156318-5caa-47c7-8834-e3d1284c1ea6';

    case 'sv':
      return 'a7f52b3a-3b39-45b6-aa1d-129f2b969497';

    case 'it':
      return 'bec0af05-6d96-40c7-8fd1-a5609caecae0';

    case 'es':
      return '01a48c8d-77f6-427a-a0ee-928f519a0099';

    case 'fr':
      return 'e9cb49b7-36bd-4fc0-885c-55571a5cb1e9';

    default:
      return 'c28faa57-83f9-44db-8ce5-7273bd6fca53';
  }
};
