require('./RestaurantMenuTableViewTripleColumn.less');

import Masonry from 'react-masonry-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

const masonryOptions = {
  transitionDuration: 0,
  columnWidth: '.grid-sizer',
  itemSelector: '.grid-item',
  gutter: '.gutter-sizer',
  percentPosition: true,
};

//************************* Templates
class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="title">{this.props.title}</div>
        <div className="description">{this.props.description}</div>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;
            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                picture={data.picture}
                children={children}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                sectionImagesHidden={This.props.sectionImagesHidden}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuSubCategory';

  render() {
    const This = this;
    const children = this.props.children;

    const descriptionHidden = this.props.descriptionHidden;

    let descriptionClass = 'description ';
    if (!this.props.description || descriptionHidden) {
      descriptionClass += 'hidden ';
    }

    const styles = this.props.styles;

    const titleStyle = {};

    if (!styles.fonts['wixmenu-subcategorytitle'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }

    return (
      <div className="subcategory" data-id={this.props.id} ref="wrapper">
        <div className="titledescription">
          <h1 className="title singlebottomborder" style={titleStyle}>
            {this.props.title}
          </h1>
          <div className={descriptionClass}>{this.props.description}</div>
        </div>
        <Masonry className="subcategorychildren" options={masonryOptions}>
          <div className="grid-sizer"></div>
          <div className="gutter-sizer"></div>
          {_.map(children, function(item, index) {
            const data = item.data || {};
            const _children = item.children;
            return (
              <REACT_MENU_ITEM
                title={data.title}
                description={data.description}
                price={data.price}
                children={_children}
                featured={data.featured}
                styles={styles}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
                isLastItem={index === children.length - 1}
              />
            );
          })}
        </Masonry>
      </div>
    );
  }
}

class REACT_MENU_ITEM extends React.Component {
  static displayName = 'legacy/ReactMenuItem';

  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const styles = this.props.styles;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({
      item: true,
      'grid-item': true,
      featured: this.props.featured,
    });

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden || !this.props.price,
    });

    const This = this;

    const bottomDottedLineStyle = { width: '100%' };

    const displayFeatured = this.props.featured ? (
      <REACT_MENU_ITEM_FEATURED />
    ) : null;

    return (
      <div className={itemClasses} data-is-last-item={this.props.isLastItem}>
        <div className="titledescription">
          <div className="title">
            {displayFeatured}
            {this.props.title}
          </div>
          <div className={descriptionClasses}>{this.props.description}</div>
          <div className={priceClasses}>{this.props.price}</div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                styles={styles}
                title={data.title}
                description={data.description}
                price={data.price}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </div>

        <div className="dottedline" style={bottomDottedLineStyle}>
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . .
        </div>
      </div>
    );
  }
}

class REACT_MENU_ITEM_FEATURED extends React.Component {
  static displayName = 'legacy/ReactMenuItemFeatured';

  render() {
    return (
      <svg width="23px" height="24px" viewBox="0 0 23 24">
        <path
          fillRule="evenodd"
          d="M 11.5 17.86C 11.5 17.86-0 24-0 24-0 24-0 0-0 0-0 0 23 0 23 0 23 0 23 24 23 24 23 24 11.5 17.86 11.5 17.86Z"
        />
        <path
          className="star"
          fillRule="evenodd"
          d="M 6.5 0C 6.5 0 7.93 4.58 7.93 4.58 7.93 4.58 12.99 4.58 12.99 4.58 12.99 4.58 8.81 7.09 8.81 7.09 8.81 7.09 10.51 11.98 10.51 11.98 10.51 11.98 6.5 8.64 6.5 8.64 6.5 8.64 2.49 11.98 2.49 11.98 2.49 11.98 4.2 7.09 4.2 7.09 4.2 7.09 0.01 4.58 0.01 4.58 0.01 4.58 5.08 4.58 5.08 4.58 5.08 4.58 6.5 0 6.5 0"
        />
      </svg>
    );
  }
}

class REACT_MENU_VARIATIONITEM extends React.Component {
  static displayName = 'legacy/ReactMenuVariationItem';

  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden,
    });

    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });

    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
      >
        <div className="titledescription">
          <div className="title">
            <span>{this.props.title}</span>
            &nbsp;&nbsp;
            <span className={priceClasses}>{this.props.price}</span>
          </div>
          <div className={descriptionClasses}>{this.props.description}</div>
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  static displayName =
    'menus/web/legacy/RestaurantMenuTableViewTripleColumn.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    const styles = this.props.styles;

    const mode = this.props.isMobile ? 'mobile' : 'web';

    return (
      <div
        className={
          'openrest_restaurantmenutableviewtriplecolumn_legacy_wrapper ' + mode
        }
        ref="wrapper"
      >
        {_.map(menu, function(category) {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={styles}
              descriptionHidden={This.props.descriptionHidden}
              priceHidden={This.props.priceHidden}
              variationsHidden={This.props.variationsHidden}
              sectionImagesHidden={This.props.sectionImagesHidden}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewTripleColumn] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + '"]',
    );
    console.log(
      '[RestaurantMenuTableViewTripleColumn] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewTripleColumn] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
