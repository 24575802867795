import dataCapsule from './DataCapsule';

export default {
  ...dataCapsule,

  async getItem(key: string) {
    const value = await dataCapsule.getItem(key);

    if (typeof value !== 'string') {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },

  setItem(key: string, value: any, ...rest: any) {
    let stringified;

    try {
      stringified = JSON.stringify(value);
    } catch (e) {
      console.error('DataCapsuleJson', e);
    }
    return dataCapsule.setItem(key, stringified, ...rest);
  },
};
