import React from 'react';

import Yummy from '@wix/restaurants-menus-layout-yummy';
import '@wix/restaurants-menus-layout-yummy/dist/styles.css';
import Catalog from '@wix/restaurants-menus-layout-catalog';
import '@wix/restaurants-menus-layout-catalog/dist/styles.css';
import Hearty from '@wix/restaurants-menus-layout-hearty';
import '@wix/restaurants-menus-layout-hearty/dist/styles.css';
import Classic from '@wix/restaurants-menus-layout-classic';
import '@wix/restaurants-menus-layout-classic/dist/styles.css';
import Express from '@wix/restaurants-menus-layout-express';
import '@wix/restaurants-menus-layout-express/dist/styles.css';
import Fresh from '@wix/restaurants-menus-layout-fresh';
import '@wix/restaurants-menus-layout-fresh/dist/styles.css';
import Gourmet from '@wix/restaurants-menus-layout-gourmet';
import '@wix/restaurants-menus-layout-gourmet/dist/styles.css';
import Rich from '@wix/restaurants-menus-layout-rich';
import '@wix/restaurants-menus-layout-rich/dist/styles.css';
import Slim from '@wix/restaurants-menus-layout-slim';
import '@wix/restaurants-menus-layout-slim/dist/styles.css';
import FacebookLayout from '@wix/restaurants-menus-layout-facebook';
import '@wix/restaurants-menus-layout-facebook/dist/styles.css';

import { legacyLayoutSelector } from './legacyLayoutSelector';

import {
  CLASSIC,
  CATALOG,
  HEARTY,
  SLIM,
  GOURMET,
  RICH,
  EXPRESS,
  FRESH,
  YUMMY,
} from '@wix/wixrest-utils/dist/MenuSettingsUtils';

const layoutIdToComponent = {
  '101': Yummy,
  '102': Catalog,
  '103': Hearty,
  '104': Classic,
  '105': Express,
  '106': Fresh,
  '107': Gourmet,
  '108': Rich,
  '109': Slim,
  [CLASSIC]: Classic,
  [CATALOG]: Catalog,
  [HEARTY]: Hearty,
  [SLIM]: Slim,
  [GOURMET]: Gourmet,
  [RICH]: Rich,
  [EXPRESS]: Express,
  [FRESH]: Fresh,
  [YUMMY]: Yummy,
};

export function getLayout({ layoutId, layoutProps, isFacebook, isMobile }) {
  const Layout = layoutIdToComponent['' + layoutId];

  if (isFacebook) {
    return <FacebookLayout {...layoutProps} />;
  } else if (Layout) {
    return <Layout {...layoutProps} />;
  } else if (isLegacyLayout(layoutId)) {
    return legacyLayoutSelector({ layoutId, layoutProps, isMobile });
  } else {
    return null;
  }
}

export function validateLayout(layoutId) {
  return !!layoutIdToComponent['' + layoutId];
}

export function isLegacyLayout(layoutId) {
  return parseInt(layoutId, 10) <= 10;
}
