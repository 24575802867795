import { getFinalAvailability } from './dateTimeLogic';
import { WeeklyTimeWindow } from '../types/Availability';
import { SeoOpeningHoursSpecification } from '../types/Seo';
import { Restaurant } from '../types/Restaurant';

export function getOpeningHours(restaurant: Restaurant) {
  const openingHours: SeoOpeningHoursSpecification[] = [];
  const avail = getFinalAvailability(restaurant, 'takeout');
  let dayOfWeek: string;
  let opensList: string;
  let closesList: string;
  if (avail.weekly) {
    const weekDays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const eachDayMinutes = getEachDayMinutes(avail.weekly);
    for (const timeWindow of eachDayMinutes) {
      const numberOfDay = Math.floor(timeWindow.minuteOfWeek / 1440);
      dayOfWeek = weekDays[numberOfDay];
      opensList = minutesOpeningHoursConvert(timeWindow.minuteOfWeek);
      closesList = minutesOpeningHoursConvert(
        timeWindow.durationMins + timeWindow.minuteOfWeek,
      );
      openingHours.push({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek,
        opens: opensList,
        closes: closesList === '00:00:00' ? '23:59:59' : closesList,
      });
    }
  }
  return openingHours;
}
export function minutesOpeningHoursConvert(minutes: number) {
  const minutesOfDay = minutes - 24 * 60 * Math.floor(minutes / 24 / 60);
  const hours = minutesOfDay / 60;
  const rhours = Math.floor(hours);
  minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return (
    (rhours < 10 ? '0' : '') +
    rhours +
    (rminutes < 10 ? ':0' : ':') +
    rminutes +
    ':00'
  );
}
export function getEachDayMinutes(weekly: WeeklyTimeWindow[]) {
  const eachDayMinutes: WeeklyTimeWindow[] = [];
  for (const timeWindow of weekly) {
    let durationMins = timeWindow.durationMins;
    let minuteOfWeek = timeWindow.minuteOfWeek;
    while (durationMins > 0) {
      const numberOfDay = Math.floor(minuteOfWeek / 1440);
      const dayMinutes =
        durationMins + minuteOfWeek < 1440 * (numberOfDay + 1)
          ? durationMins
          : 1440 * (numberOfDay + 1) - minuteOfWeek;
      eachDayMinutes.push({
        minuteOfWeek,
        durationMins: dayMinutes,
      });
      minuteOfWeek = (numberOfDay + 1) * 1440;
      durationMins = durationMins - dayMinutes;
    }
  }
  return eachDayMinutes;
}
