import each from 'lodash/each';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import map from 'lodash/map';
import includes from 'lodash/includes';
import get from 'lodash/get';
import set from 'lodash/set';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import first from 'lodash/first';
import zipObject from 'lodash/zipObject';
import every from 'lodash/every';
import CurrencyUtils from '@wix/wixrest-utils/dist/CurrencyUtils';
import ItemHelper from '@wix/wixrest-utils/dist/openrest-helpers/Item';
import moment from 'moment-timezone';
import { helpers } from 'wix-restaurants-js-sdk';

function getPrice(itemsHash, item, currency, priceRange) {
  if (priceRange === 0 || priceRange === '0') {
    return ItemHelper.getPriceStr({ itemsHash, currency, item });
  } else {
    return item.price ? CurrencyUtils.toStr(item.price / 100, currency) : '';
  }
}

export function checkCondition(displayCondition, platform) {
  return helpers.Condition.checkCondition({
    condition: displayCondition || { type: 'true' },
    deliveryType: 'any',
    platform: platform,
    source: 'any',
  });
}

function isVisible(entity, isMobile) {
  return checkCondition(
    entity.displayCondition,
    isMobile ? 'mobileweb' : 'web',
  );
}

export function convertItems(
  menu,
  items,
  currency,
  locale,
  priceRange,
  isMobile,
) {
  const result = {};

  const visibleItems = filter(items, item => isVisible(item, isMobile));
  const itemsHash = zipObject(map(menu.items, 'id'), menu.items);

  each(visibleItems, item => {
    result[item.id] = {
      data: omitBy(
        {
          id: item.id,
          title: item.title[locale],
          description: item.description ? item.description[locale] : '',
          picture: item.picture || (item.media || {}).logo,
          price: getPrice(itemsHash, item, currency, priceRange),
          featured: includes(item.labels, 'featured'),
          labels: item.labels || [],
        },
        isUndefined,
      ),
      children: [],
    };
  });

  each(visibleItems, item => {
    const _item = result[item.id];
    const variation = first(item.variations);

    if (variation) {
      _item.children = map(variation.itemIds, itemId => {
        const omitted = omit(
          result[itemId.itemId],
          'data.picture',
          'data.featured',
          'data.labels',
          'children',
        );
        const price = get(variation, `prices.${itemId.itemId}['1']`);
        const prefix = _item.data.price && priceRange === 11 ? '+' : '';
        return set(
          omitted,
          'data.price',
          price ? prefix + CurrencyUtils.toStr(price / 100, currency) : '',
        );
      });
    }
  });

  return result;
}

export function getDisplayableSection({
  menu,
  currency,
  locale,
  sectionId,
  priceRange,
  isMobile = false,
  convertedItems,
} = {}) {
  const _rawMenu = find(menu.sections, section => section.id === sectionId);

  if (!_rawMenu || !isVisible(_rawMenu, isMobile)) {
    return {};
  }

  const _menu = {
    data: {
      id: sectionId,
      title: _rawMenu.title[locale],
      description: get(_rawMenu, `description[${locale}]`) || '',
    },
  };

  const placeholderImage = get(_rawMenu, 'media["placeholder"]', null);

  _menu.children = compact(
    map(_rawMenu.children, section => {
      if (!isVisible(section, isMobile)) {
        return null;
      }

      return {
        data: omitBy(
          {
            id: section.id,
            title: section.title[locale],
            description: get(section, `description[${locale}]`) || '',
            picture: section.picture || (section.media || {}).logo,
          },
          isUndefined,
        ),
        children: compact(
          map(section.itemIds, itemId => {
            // In cases where it is needed, add the placeholder image
            const item = convertedItems[itemId];
            if (!placeholderImage) return item;
            if (!item) return null;
            if (!item.data) return item;
            if (item.data.picture) return item;
            const clone = JSON.parse(JSON.stringify(item));
            clone.data.picture = placeholderImage;
            return clone;
          }),
        ),
      };
    }),
  );

  return _menu;
}
