import { IHttpClient } from '@wix/http-client';
import { Location } from '../types/Location';
import _ from 'lodash';
import { SERVERLESS_BASEURL } from '../constants';

export async function getOrganizationLocations(
  signedInstance: string,
  httpClient?: IHttpClient,
) {
  const url = `https://${SERVERLESS_BASEURL}/_api/restaurants/v2.1/settings/locations`;

  try {
    if (!httpClient) {
      const response = await fetch(url, {
        headers: {
          authorization: signedInstance,
        },
      });
      const { locations }: { locations: Location[] } = await response.json();

      return {
        locations: locations || [],
      };
    } else {
      const response = await httpClient.get<{ locations: Location[] }>(url, {
        headers: {
          authorization: signedInstance,
        },
      });
      const { locations } = response.data;

      return {
        locations: locations || [],
      };
    }
  } catch (e) {
    return { locations: [] };
  }
}
