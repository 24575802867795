import get from 'lodash/get';
import some from 'lodash/some';
import { shouldDisplaySectionImages } from '@wix/wixrest-utils/dist/MenuSettingsUtils';
import {
  iconTypeIdToName,
  colorTypeNameToId,
} from '@wix/restaurants-settings-common/dist/utils/settingsEnum';

export function getStyleSettings(styles, compId, organization) {
  const priceRange = get(styles, "numbers['wixmenu.priceRange']");
  const layoutId = get(styles, "numbers['wixmenu.menuview']");
  const priceHidden = !get(
    styles,
    `booleans['${compId}.wixmenu.widgets.price']`,
  );
  const variationsHidden = !get(
    styles,
    `booleans['${compId}.wixmenu.widgets.variations']`,
  );
  const displaySectionImagesRaw = get(
    styles,
    `booleans['${compId}.wixmenu.widgets.displaySectionImages']`,
  );
  const displayIcons = !!get(
    styles,
    `booleans['${compId}.wixmenu.widgets.displaydishicons']`,
  );
  const colorType = get(styles, "numbers['wixmenu.colortype']");
  const rawIconType =
    iconTypeIdToName[get(styles, "numbers['wixmenu.icontype']")];
  const customIconType =
    iconTypeIdToName[get(styles, "numbers['wixmenu.customicontype']")];
  const iconType =
    colorType === colorTypeNameToId.custom ? customIconType : rawIconType;
  const iconPrimaryColor = get(styles, "colors['wixmenu-iconprimarycolor']");
  const iconSecondaryColor = get(
    styles,
    "colors['wixmenu-iconsecondarycolor']",
  );
  const iconCustomPrimaryColor = get(
    styles,
    "colors['wixmenu-iconcustomprimarycolor']",
  );
  const iconCustomSecondaryColor = get(
    styles,
    "colors['wixmenu-iconcustomsecondarycolor']",
  );

  const primaryColor = (() => {
    switch (colorType) {
      case colorTypeNameToId.custom:
        return iconCustomPrimaryColor;
      case colorTypeNameToId.colors:
        return iconPrimaryColor;
      default:
        return null;
    }
  })();

  const secondaryColor = (() => {
    switch (colorType) {
      case colorTypeNameToId.custom:
        return iconCustomSecondaryColor;
      case colorTypeNameToId.colors:
        return iconSecondaryColor;
      default:
        return null;
    }
  })();

  // Legacy properties
  const descriptionHidden = !get(
    styles,
    "booleans['wixmenu.widgets.description']",
  );
  const sectionImagesHidden = !get(
    styles,
    "booleans['wixmenu.widgets.sectionimages']",
  );

  const displaySectionImages = shouldDisplaySectionImages({
    displaySectionImages: displaySectionImagesRaw,
    organization,
    window: typeof window !== 'undefined' ? window : undefined,
  });

  return {
    priceRange,
    layoutId,
    priceHidden,
    variationsHidden,
    displaySectionImages,
    descriptionHidden,
    sectionImagesHidden,
    displayIcons,
    iconType,
    primaryColor,
    secondaryColor,
  };
}

export function validateProps(props) {
  return (
    !!props.menu &&
    !!props.organization &&
    !!props.styles &&
    (!!props.compId || !!props.isFacebook) &&
    !!props.i18n
  );
}

export function hasPermiumProducts(organization) {
  return some(organization.products || [], {
    id: 'com.wix.restaurants.menus',
    param: 'premium',
  });
}
