import React from 'react';
import { BI } from '@wix/wixrest-bi';
import Dropdown from './Dropdown';

export default ({
  locations,
  currentLocation,
  orgId,
  isMobile,
  getOrganizationMenu,
  className,
}) => (
  <Dropdown
    options={locations.map(({ locationId, name }) => ({
      id: locationId,
      title: name,
    }))}
    value={currentLocation}
    onChange={(e) => {
      BI.event(BI.EVENTS.LIVE_SITE_MENU_LOCATION_PICKER_INTERACTION, {
        locationGuid: e.target.value,
      });
      getOrganizationMenu(orgId, e.target.value);
    }}
    dataHook="locations-dropdown"
    isMobile={isMobile}
    className={className}
  />
);
