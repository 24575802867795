import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import first from 'lodash/first';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { checkCondition } from './dataStructures';

function formatSection(locale, section) {
  return {
    id: section.id,
    title: section.title[locale],
    description: get(section, `description[${locale}]`) || '',
  };
}

function isSectionDisplayable(section, isMobile) {
  const platform = isMobile ? 'mobileweb' : 'web';
  return checkCondition(section.displayCondition, platform);
}

export function getNavigateableSections({
  menu,
  styles,
  locale,
  compId,
  isMobile = false,
}) {
  const showAllMenus =
    styles.booleans[`${compId}.wixmenu.widgets.showallmenus`];

  const displayableSections = filter(menu.sections, (section) =>
    isSectionDisplayable(section, isMobile),
  );

  if (showAllMenus !== false) {
    return map(displayableSections, formatSection.bind(null, locale));
  }

  const orderedMenus = (styles.data[`wixmenu.orderedmenus.${compId}`] || {})
    .orderedMenus;

  const sections = compact(
    map(orderedMenus, (orderedMenu) => {
      // Make sure that menu is active, and defined correctly
      return (
        orderedMenu.active &&
        find(displayableSections, (section) => section.id === orderedMenu.id)
      );
    }),
  );

  return map(sections, formatSection.bind(null, locale));
}

export function getSelectedSection({ navigateableSections, sectionId }) {
  const found = find(
    navigateableSections,
    (section) => section.id === sectionId,
  );
  return found || first(navigateableSections);
}

export function shouldBeCentered(layoutId, isFacebook) {
  return (
    !isFacebook && includes([5, 6, 7, 16, 17, 18, 21], parseInt(layoutId, 10))
  );
}

export function getVisibleLocations(
  locations,
  compactMenusList,
  styles,
  compId,
) {
  const hiddenLocations =
    styles.data['wixmenu.hiddenlocations.' + compId] || {};
  const showAllMenus =
    styles.booleans[`${compId}.wixmenu.widgets.showallmenus`];
  const orderedMenus = (styles.data[`wixmenu.orderedmenus.${compId}`] || {})
    .orderedMenus;

  return locations.filter(
    ({ locationId: currLocationId }) =>
      !hiddenLocations[currLocationId] &&
      (!compactMenusList ||
        compactMenusList
          .find(({ locationId }) => locationId === currLocationId)
          .compactMenus.filter((menu) => {
            const orderedMenu = orderedMenus.find(({ id }) => id === menu.id);
            return (
              !menu.isHiddenForCatalog &&
              (showAllMenus || !orderedMenu || orderedMenu.active) &&
              menu.sections.filter((section) => !section.isHiddenForCatalog).length
            );
          }).length),
  );
}
