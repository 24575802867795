import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames';
import LocationsDropdown from './LocationsDropdown';
import './MenusNavigation.less';

const MenusNavigation = (props) => {
  const {
    navigateableSections,
    selectedSection,
    onChange,
    isFacebook,
    centered,
    isLegacy,
    orgId,
    locations,
    currentLocation,
    headingText = 'Menu',
    getOrganizationMenu,
  } = props;

  const sectionLength = navigateableSections.length;
  const singleSectionWithoutDescription =
    sectionLength === 1 && !navigateableSections[0].description;
  if (
    (sectionLength === 0 || singleSectionWithoutDescription) &&
    (!locations || locations.length === 0)
  ) {
    return (
      <header>
        <h2 className="sr-only">{headingText}</h2>
      </header>
    );
  }

  const links = map(navigateableSections, (navigateableSection, index) => {
    const { id, title } = navigateableSection;
    const isSelected = id === selectedSection.id;
    const anchorProps = {
      key: id,
      href: '#', // `#${title}_${index}`//a name use ?
      onClick: (event) => {
        event.preventDefault();

        onChange(navigateableSection);
      },
      className: classNames([
        'MenusNavigation_item',
        isSelected && 'MenusNavigation_selected',
      ]),
    };

    return (
      <li key={index}>
        <a
          data-hook="nav-link"
          {...anchorProps}
          data-section-id={id}
          id={`menu${id}`}
        >
          {title}
        </a>
      </li>
    );
  });
  // Deprecated
  // if (isFacebook) links = flatten(map(links, (link, index) => {
  //     const divider = <li key={`divider${index}`} className="MenusNavigation_divider"/>;
  //     return [link, (index < links.length - 1) ? divider:''];
  // }));

  const className = classNames([
    'MenusNavigation',
    isFacebook && 'MenusNavigationFacebook',
    isLegacy && 'MenusNavigationLegacy',
  ]);

  const sectionSelectedDesc = selectedSection.description || null;
  const selectedId = selectedSection.id;
  return (
    <header className={centered ? 'MenusNavigationCentered' : ''}>
      <nav className={className} aria-label="Menus">
        <ul className="MenusNavigation_items">{links}</ul>
        {locations && locations.length > 1 ? (
          <LocationsDropdown
            locations={locations}
            currentLocation={currentLocation}
            orgId={orgId}
            getOrganizationMenu={getOrganizationMenu}
          />
        ) : undefined}
      </nav>
      {sectionSelectedDesc && (
        <div
          className="menusNavigation_description"
          id={`desc${selectedId}`}
          data-hook="nav-description"
        >
          {sectionSelectedDesc}
        </div>
      )}
      <h2 className="sr-only">{headingText}</h2>
    </header>
  );
};

MenusNavigation.displayName = 'MenusNavigation';

MenusNavigation.propTypes = {
  navigateableSections: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isFacebook: PropTypes.bool,
  centered: PropTypes.bool,
  isLegacy: PropTypes.bool,
  orgId: PropTypes.string,
};

MenusNavigation.defaultProps = {
  navigateableSections: [],
  selectedSection: { id: '', title: '' },
  isFacebook: false,
  centered: false,
  isLegacy: false,
};

export default MenusNavigation;
