import _ from 'lodash';

interface ParamsType {
  [key: string]: any;
}

export const findMatchingTemplate = (charge: any, templates: any) => {
  return _.first(
    _.compact(
      _.map(templates, (template, templateId) => {
        const result = matchTemplate(charge, template);
        return result.success
          ? { type: templateId, params: result.params }
          : null;
      }),
    ),
  );
};

export const matchTemplate = (charge: any, template: any) => {
  if (typeof charge !== typeof template) {
    return { success: false, params: {} };
  }

  let params: ParamsType = {};
  let success = true;

  _.each(template, (v, k) => {
    if (_.isString(v) && v.indexOf('%{') === 0) {
      params[v.slice(2, -1)] = charge[k];
    } else if (_.isArray(v) || _.isObject(v)) {
      const ret = matchTemplate(charge[k], v);
      success = success && ret.success;
      params = { ...params, ...ret.params };
    } else {
      success = success && charge[k] === v;
    }
  });

  return { params, success };
};

export const fillTemplate = ({
  template,
  params,
}: {
  template: any;
  params: any;
}) => {
  const parse: any = (v: any) => {
    if (_.isString(v) && v.indexOf('%{') === 0) {
      return params[v.slice(2, -1)];
    } else if (_.isArray(v) || _.isObject(v)) {
      return fillTemplate({ template: v, params });
    } else {
      return v;
    }
  };

  if (_.isArray(template)) {
    return _.map(template, parse);
  } else {
    const ret: ParamsType = {};
    _.each(template, (v, k) => {
      ret[k] = parse(v);
    });
    return ret;
  }
};
