// ------------------------------- GLOBAL OBJECTS COMMONLY USED (CSS) --------------------------------------
// require('../../../lib/bootstrap/css/bootstrap.min.css');
// require('../../../lib/wixrest-icons/wixrest-icons.css');

require('./defaults.less');

import React from 'react';
import { clients } from 'wix-restaurants-js-sdk';
import QueryUtils from '@wix/wixrest-utils/dist/QueryUtils';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import FacebookSignedRequest from '@wix/wixrest-utils/dist/FacebookSignedRequest';
import Wss from '@wix/wixrest-utils/dist/Wss';
import { Provider, connect } from 'react-redux';
import createConfiguredStore from '@wix/restaurants-redux/dist/store/ConfigureReduxStore';
import * as WebInitActions from '@wix/restaurants-redux/dist/actions/WebInitActions';
import CssProvider from '@wix/restaurants-components/dist/web/CssProvider/CssProvider';
import I18nWebProvider from '@wix/restaurants-components/dist/shared/I18nProvider/I18nWebProvider';
import I18nify from '@wix/restaurants-components/dist/shared/I18nify';
import { BI } from '@wix/wixrest-bi';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import MenusAppContainer from './components/MenusAppContainer';
import { setHeight, verifyFBHeight } from './utils/documentLogic';
import fedopsJson from '../fedops.json';
import { create } from '@wix/fedops-logger';

const fedopsLogger = create(fedopsJson.app_name);

import wixFullstoryLoader from '@wix/wix-fullstory-loader';

wixFullstoryLoader({
  label: 'restaurants-menus-ssr',
  sample: window.spiceProduction ? 0.02 : 0,
});

/** ****** REDUCERS ***********/
const reducers = {
  jointLoginInfo: require('@wix/restaurants-redux/dist/reducers/JointLoginReducer')
    .default,
  wixInstance: require('@wix/restaurants-redux/dist/reducers/WixInstanceReducer')
    .default,
  wixInstalledApps: require('@wix/restaurants-redux/dist/reducers/WixInstalledAppsReducer')
    .default,
  i18nData: require('@wix/restaurants-redux/dist/sagas/I18nSaga').default
    .reducer,
  organization: require('@wix/restaurants-redux/dist/reducers/OrganizationReducer')
    .default,
  menu: require('@wix/restaurants-redux/dist/reducers/MenuReducer').default,
  styles: require('@wix/restaurants-redux/dist/reducers/StylesReducer').default,
  wixBridge: require('@wix/restaurants-redux/dist/reducers/WixBridgeReducer')
    .default,
  locations: require('@wix/restaurants-redux/dist/reducers/LocationsReducer')
    .default,
};

/** ****** SAGAS ***********/
const sagas = [
  require('@wix/restaurants-redux/dist/sagas/MenuSaga').default,
  require('@wix/restaurants-redux/dist/sagas/OrganizationSaga').default,
  require('@wix/restaurants-redux/dist/sagas/JointLoginSaga').default,
  require('@wix/restaurants-redux/dist/sagas/WebInitSaga').default,
  require('@wix/restaurants-redux/dist/sagas/WixInstalledAppsSaga').default,
  require('@wix/restaurants-redux/dist/sagas/FeedbackSaga').default,
  require('@wix/restaurants-redux/dist/sagas/WixStylesSaga').default,
  require('@wix/restaurants-redux/dist/sagas/WixBridgeSaga').default,
  require('@wix/restaurants-redux/dist/sagas/I18nSaga').default.saga,
  require('@wix/restaurants-redux/dist/sagas/FedopsSaga').default,
  require('@wix/restaurants-redux/dist/sagas/LocationsSaga').default,
  require('@wix/wixrest-bi').redux.Saga,
];

/** ****** SAGA PARAMS ***********/
import { WixApi } from '@wix/wixrest-wix-api';
import OpenrestService from 'openrest';
import { v4 as uuid } from 'uuid';

const WIX_APP_IDS = WixInstance.getAllAppIds();
const wixApi = new WixApi({ wixRestAppIds: WIX_APP_IDS });
const FB = window.FB;
const Wix = window.Wix;

const css = _.map($('style'), (style) =>
  $(style).text().split('\n').join(' '),
).join(' ');

const wss = window.wssSerialize
  ? new Wss(window.wssSerialize)
  : new Wss({ css });

const i18nUrl = 'https://apps.wixrestaurants.com/i18n';

const queryHash = window.queryHash || QueryUtils.buildQueryHash();

const sagaParams = {
  restClient: clients.createRestClient({ timeout: 0 }),
  mappingClient: clients.createRestClient({
    timeout: 0,
    baseUrl: 'https://mapping.wixrestaurants.com/v1',
  }),
  authentication: clients.createRestClient({
    timeout: 0,
    baseUrl: 'https://auth.wixrestaurants.com/v2',
  }),
  WIX_APP_IDS,
  wixApi,
  OpenrestService,
  fetch,
  FB,
  Wix,
  uuid,
  window,
  wss,
  i18nUrl,
  type: queryHash.type,
  fedopsJson,
  fedopsLogger,
};

const initialState = window.__INITIAL_STATE__;

const store = createConfiguredStore({
  reducers,
  sagas,
  sagaParams,
  middlewares: [],
  useLogger: !window.spiceProduction,
  initialState,
});

const reportFirstRender = _.once(() => {
  if (
    typeof performance !== 'undefined' &&
    typeof performance.mark === 'function'
  ) {
    performance.mark('[perfer] app-loaded');
  }
});

function start() {
  window.START_APP_TIME = new Date().getTime();
  // console.log('[Performance] Start app time', window.START_APP_TIME - window.LOAD_TIME);

  $('body').css('background-color', 'transparent');
  moment.relativeTimeThreshold('m', 10000); // No threshold to show minutes

  let facebookPageId = null;

  if (window.facebookRequest) {
    FacebookSignedRequest.init(window.facebookRequest);
    facebookPageId = FacebookSignedRequest.getPageId();
  }

  // Get the type of system to open
  const type = (() => {
    if (queryHash.type === 'popup-web') {
      return 'popup';
    } else if (facebookPageId) {
      return 'wix-menus-facebook';
    } else if (queryHash.type === 'wixmenus.client') {
      return 'wix-menu';
    } else if (queryHash.type === 'wixorders.client') {
      return 'wix-orders';
    }
  })();

  store.dispatch(
    WebInitActions.actions.initWeb({
      i18nUxMode: false,
      queryHash,
      appId: WixInstance.MENUS_APP_ID,
      system: 'client',
      facebookPageId,
      type,
      loadCompactMenus: true,
    }),
  );

  const state = store.getState();
  const wixInstance = _.get(state, 'wixInstance.instance');
  const organization = _.get(state, 'organization.organization');

  if (organization && wixInstance) {
    BI.init({
      organizationId: organization.id,
      locationGuid: organization.currentLocationId,
      useIframeLogger: true,
      metasiteId: wixInstance.getMetasiteId(),
      biToken: wixInstance.getBiToken(),
      instanceId: wixInstance.getInstanceId(),
      ownerId: wixInstance.getSiteOwnerId(),
      roles: wixInstance.getPermissions() || '',
    });
  }

  window.openrest = require('openrest'); // For debugging
  window.WixInstance = WixInstance;

  const isDemo = false; // TODO WixInstance.getIsDemo();

  const I18nWebProviderConnected = connect((state) => ({
    localeData: state.i18nData.localeData,
  }))(I18nWebProvider);
  const I18nifiedCssProvider = I18nify(CssProvider);

  const compId = Wix.Utils.getOrigCompId() || Wix.Utils.getCompId();
  const viewMode = Wix.Utils.getViewMode();
  const isChrome = !!window.chrome && !!window.chrome.webstore;
  const webpEnabled = isChrome;

  const preloaderElement = document.getElementById('preloader');
  if (preloaderElement) {
    preloaderElement.parentNode.removeChild(preloaderElement);
  }

  const isFacebook = !!facebookPageId;

  if (isFacebook && typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
  }

  function onMount() {
    const height = setHeight();

    window.addEventListener(
      'resize',
      _.throttle(() => {
        setHeight();
      }),
      100,
    );

    try {
      if (window.__SHOULD_REPORT_PERFORMANCE_MANUALLY__) {
        Wix.Performance.applicationLoaded();
      }
      BI.event(BI.EVENTS.LIVE_SITE_MENU_PAGE_LOAD);
      fedopsLogger.appLoaded();
      reportFirstRender();
      const params = {
        is_server_side_rendering: true,
        loading_time: Math.round(window.performance.now()),
        product: 'wixmenus.client',
        visitorId: WixInstance.getVisitorId(),
      };

      BI.event(BI.EVENTS.LIVE_SITE_MENU_LOAD, params);
    } catch (e) {
      console.warn('[app loaded]: Exception caught', e);
    }

    if (height && isFacebook) {
      verifyFBHeight(height);
    }
  }

  ReactDOM.render(
    <Provider store={store}>
      <I18nWebProviderConnected
        Intl={Intl}
        googleMapsSdk={false}
        fbJsSdk={isFacebook}
      >
        <I18nifiedCssProvider wss={wss}>
          <MenusAppContainer
            isDemo={isDemo}
            isFacebook={isFacebook}
            compId={compId}
            viewMode={viewMode}
            deviceType={queryHash.deviceType}
            webpEnabled={webpEnabled}
            setHeight={setHeight}
            onMount={onMount}
          />
        </I18nifiedCssProvider>
      </I18nWebProviderConnected>
    </Provider>,
    $('.mainDiv')[0],
  );
}

start();
