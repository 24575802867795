import React from 'react';

function ArrowSmallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <g>
                <path
                  d="M11.999 9L6 14.241 6.657 15 11.999 10.334 17.343 15 18 14.241z"
                  transform="translate(-295 -156) translate(42 113) translate(34 35) translate(219 8) matrix(1 0 0 -1 0 24)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

ArrowSmallIcon.displayName = 'ArrowSmallIcon';

export default ArrowSmallIcon;
