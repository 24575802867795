import { LatLng } from '../types/Address';

const EARTH_RADIUS_IN_METERS = 6371000;

function degreesToRadians(deg: number) {
  return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(source: LatLng, destination: LatLng) {
  const latDifference = degreesToRadians(destination.lat - source.lat) / 2;
  const lngDifference = degreesToRadians(destination.lng - source.lng) / 2;

  const temp =
    Math.sin(latDifference) * Math.sin(latDifference) +
    Math.cos(degreesToRadians(source.lat)) *
      Math.cos(degreesToRadians(destination.lat)) *
      Math.sin(lngDifference) *
      Math.sin(lngDifference);

  const distance = 2 * Math.atan2(Math.sqrt(temp), Math.sqrt(1 - temp));
  return Math.round(EARTH_RADIUS_IN_METERS * distance);
}

export function formatDistance(
  meters: number,
  unit: 'miles' | 'meters' = 'meters',
) {
  if (unit === 'miles') {
    return {
      distance: ((meters / 1000) * 0.62).toFixed(2).replace(/.00$/, ''),
      unit: 'ml',
    };
  }

  if (meters >= 1000) {
    return {
      distance: (meters / 1000).toFixed(1).replace(/.0$/, ''),
      unit: 'km',
    };
  }

  return { distance: meters, unit: 'm' };
}
