import {
  AnyAction,
  Middleware,
  MiddlewareAPI,
  Dispatch,
  Unsubscribe,
} from 'redux';

export type ListenerCallback<A extends AnyAction, S, C> = (
  action: A,
  getState: () => S,
  dispatch: Dispatch<A>,
  probeContext: C,
) => void;

export interface Listener<A extends AnyAction, S, C> {
  type: string;
  callback: ListenerCallback<A, S, C>;
}

export type ProbeOnAction<A extends AnyAction, S, C> = (
  type: string,
  callback: ListenerCallback<A, S, C>,
) => Unsubscribe;
export type ProbeOnActionOnce<A extends AnyAction, S, C> = (
  type: string,
  callback: ListenerCallback<A, S, C>,
) => void;
export type ProbeWaitForAction<A> = (type: string | string[]) => Promise<A>;
export type Probe<A extends AnyAction = AnyAction, S = any, C = any> = (
  probe: ProbeArgument<A, S, C>,
) => void;
export type ProbeMiddleware = Middleware & { isActive: () => boolean };

export interface ProbeArgument<
  A extends AnyAction = AnyAction,
  S = any,
  C = any
> {
  onAction: ProbeOnAction<A, S, C>;
  onActionOnce: ProbeOnActionOnce<A, S, C>;
  waitForAction: ProbeWaitForAction<A>;
}

export function reduxProbeFactory<
  A extends AnyAction = AnyAction,
  S = any,
  C = any
>(probes: Probe<A>[] = [], probeContext: C): ProbeMiddleware {
  const listeners: { [type: string]: Listener<A, S, C>[] } = {};

  const probeMiddleware: ProbeMiddleware = (api: MiddlewareAPI) => (
    next: Dispatch,
  ) => (action: A) => {
    const result = next(action);

    if (Array.isArray(listeners[action.type])) {
      listeners[action.type].forEach(({ callback }) => {
        callback(action, api.getState, api.dispatch, probeContext);
      });
    }

    return result;
  };

  probeMiddleware.isActive = () => {
    return Object.values(listeners).some((callbacks) => callbacks.length > 0);
  };

  const onAction = (type: string, callback: ListenerCallback<A, S, C>) => {
    const listener = { type, callback };

    if (!listeners[type]) {
      listeners[type] = [];
    }

    listeners[type].push(listener);

    return function unsubscribe() {
      listeners[type].splice(listeners[type].indexOf(listener), 1);
    };
  };

  const onActionOnce = (type: string, callback: ListenerCallback<A, S, C>) => {
    const unsubscribe = onAction(type, (...args) => {
      callback(...args);
      unsubscribe();
    });
  };

  const waitForAction = (type: string | string[]): Promise<A> => {
    const types = Array.isArray(type) ? type : [type];
    const unsubscribeFns: (() => void)[] = [];

    return new Promise((resolve) => {
      for (const aType of types) {
        const fn = onAction(aType, (action: A) => {
          unsubscribeFns.forEach((unsubscribeFn) => unsubscribeFn());
          resolve(action);
        });

        unsubscribeFns.push(fn);
      }
    });
  };

  const probeArgument: ProbeArgument<A> = {
    onAction,
    onActionOnce,
    waitForAction,
  };

  probes.forEach((probe) => probe(probeArgument));

  return probeMiddleware;
}
