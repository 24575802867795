import {
  DeliveryDispatchInfo,
  PickupDispatchInfo,
  Restaurant,
} from '../types/Restaurant';
import { DispatchType, VirtualDispatchType } from '../types/Dispatch';
import moment, { Moment } from 'moment-timezone';
import {
  DisjunctiveAvailabilityIterator,
  ConjunctiveTimeWindowsIterator,
  AvailabilityIterator,
} from 'availability';
import {
  Availability,
  AvailabilityDate,
  IAvailabilityIterator,
} from '../types/Availability';
import { isSupportsFutureOrder } from './restaurantLogic';
import { getDateOptionsV2, getTimeOptions } from './dateTimeLogic';

interface dtlv2_getSupportedDispatchTypesInnerArgs {
  restaurant: Restaurant;
  when?: number;
}

export interface DispatchTypeAvailability {
  isAvailable: boolean;
  willBeAvailable: boolean;
}

export type SupportedDispatchTypes = Partial<
  Record<VirtualDispatchType, DispatchTypeAvailability>
>;

function dtlv2_getSupportedDispatchTypesInner({
  restaurant,
  when,
}: dtlv2_getSupportedDispatchTypesInnerArgs): SupportedDispatchTypes {
  const cal = moment(when).tz(restaurant.timezone);
  const result: SupportedDispatchTypes = {};

  ['takeout', 'dine-in', 'delivery'].forEach((dt) => {
    const {
      asapIterator,
      futureOrdersIterator,
    } = getCombinedAvailabilityIteratorByDispatchType(
      cal,
      restaurant,
      dt as VirtualDispatchType,
    );

    const { status } = asapIterator.next();
    const {
      status: futureStatus,
      until: futureUntil,
    } = futureOrdersIterator.next();

    result[dt as VirtualDispatchType] = {
      isAvailable: status === 'available',
      willBeAvailable:
        isSupportsFutureOrder(restaurant) &&
        (futureStatus === 'available' || Boolean(futureUntil)),
    };
  });

  return result;
}

export function dtlv2_getSupportedDispatchTypesSet(
  args: dtlv2_getSupportedDispatchTypesArgs,
) {
  return toSet(dtlv2_getSupportedDispatchTypes(args));
}

function getIterators(
  cal: Moment,
  restaurant: Restaurant,
  dispatchType: VirtualDispatchType,
) {
  return [
    getAvailabilityIteratorByDispatchType(cal, restaurant, dispatchType),
    new AvailabilityIterator({
      availability: restaurant.openTimes,
      cal,
    }),
    new AvailabilityIterator({
      availability: restaurant.orders.availability,
      cal,
    }),
  ];
}

function getCombinedAvailabilityIteratorByDispatchType(
  cal: Moment,
  restaurant: Restaurant,
  dispatchType: VirtualDispatchType,
) {
  const iterators = getIterators(cal, restaurant, dispatchType);
  const iterators2 = getIterators(cal, restaurant, dispatchType);

  const asapIterator = new ConjunctiveTimeWindowsIterator({
    cal,
    iterators,
  });

  const futureOrdersIterator = new ConjunctiveTimeWindowsIterator({
    cal,
    iterators: iterators2.concat(getFutureOrdersAvailability(cal, restaurant)),
  });

  return { asapIterator, futureOrdersIterator };
}

function getAvailabilityIteratorByDispatchType(
  cal: Moment,
  restaurant: Restaurant,
  dispatchType: VirtualDispatchType,
) {
  if (dispatchType === 'takeout') {
    const takeout = restaurant.deliveryInfos.find(
      (di) => di.type === 'takeout',
    ) as PickupDispatchInfo;

    if (!takeout || takeout.inactive) {
      return getUnavailableIteratorLikeObject();
    } else {
      return new AvailabilityIterator({
        availability: takeout.availability,
        cal,
      });
    }
  } else if (dispatchType === 'dine-in') {
    const takeout = restaurant.deliveryInfos.find(
      (di) => di.type === 'takeout',
    ) as PickupDispatchInfo;

    if (
      !takeout ||
      takeout.inactive ||
      !takeout.contactlessDineInInfo?.enabled
    ) {
      return getUnavailableIteratorLikeObject();
    } else {
      return new AvailabilityIterator({
        availability: takeout.availability,
        cal,
      });
    }
  } else if (dispatchType === 'delivery') {
    const deliveryInfos = restaurant.deliveryInfos.filter(
      (di) => di.type === 'delivery',
    ) as DeliveryDispatchInfo[];

    if (deliveryInfos.length === 0) {
      return getUnavailableIteratorLikeObject();
    } else {
      return new DisjunctiveAvailabilityIterator({
        availabilities: deliveryInfos.map((di) => di.availability),
        cal,
      });
    }
  }
}

function getUnavailableIteratorLikeObject() {
  return {
    hasNext: () => false,
    next: () => ({ status: 'unavailable', until: null }),
  } as IAvailabilityIterator;
}

interface dtlv2_getDefaultDispatchTypeArgs {
  restaurants: Restaurant[];
  when?: number;
}

export function dtlv2_getDefaultDispatchType({
  restaurants,
  when,
}: dtlv2_getDefaultDispatchTypeArgs) {
  const set = dtlv2_getSupportedDispatchTypesSet({
    restaurants,
    when,
  });

  if (set.has('delivery')) {
    return 'delivery';
  } else if (set.has('takeout')) {
    return 'takeout';
  } else if (set.has('dine-in')) {
    return 'dine-in';
  } else {
    throw new Error('No supported dispatch types');
  }
}

function toSet(supportedDispatchTypes: SupportedDispatchTypes) {
  const set = new Set<VirtualDispatchType>();

  Object.keys(supportedDispatchTypes).forEach((dt) => {
    const dta = supportedDispatchTypes[dt as VirtualDispatchType];
    if (dta?.isAvailable || dta?.willBeAvailable) {
      set.add(dt as VirtualDispatchType);
    }
  });

  return set;
}

function toAvailabilityDate(cal: Moment): AvailabilityDate {
  return {
    year: cal.get('year'),
    month: cal.get('month') + 1,
    day: cal.get('date'),
    hour: cal.get('hour'),
    minute: cal.get('minute'),
  };
}

function getFutureOrdersAvailability(cal: Moment, restaurant: Restaurant) {
  const now = moment().tz(restaurant.timezone);

  const nowPlusMinTime = moment()
    .tz(restaurant.timezone)
    .add(restaurant.orders.future.delayMins.min, 'minutes');

  const nowPlusMaxTime = moment()
    .tz(restaurant.timezone)
    .add(restaurant.orders.future.delayMins.max, 'minutes');

  const availability: Availability = {
    exceptions: [
      {
        available: false,
        start: toAvailabilityDate(now),
        end: toAvailabilityDate(nowPlusMinTime),
      },
      {
        available: false,
        start: toAvailabilityDate(nowPlusMaxTime),
      },
    ],
  };

  return new AvailabilityIterator({ availability, cal });
}

function isAsapAvailable(restaurant: Restaurant, dispatchType: DispatchType) {
  const cal = moment().tz(restaurant.timezone);
  const { asapIterator } = getCombinedAvailabilityIteratorByDispatchType(
    cal,
    restaurant,
    dispatchType,
  );

  const { status: asapStatus } = asapIterator.next();

  return asapStatus === 'available';
}

export function dtlv2_getEarliestDispatchTime(
  restaurant: Restaurant,
  dispatchType: DispatchType,
) {
  if (isAsapAvailable(restaurant, dispatchType)) {
    return { timingOption: 'asap' };
  }

  const cal = moment().tz(restaurant.timezone);

  const availabilityIteratorFactory = (c: Moment) => {
    return getCombinedAvailabilityIteratorByDispatchType(
      c,
      restaurant,
      dispatchType,
    ).futureOrdersIterator;
  };

  const dateOptions = getDateOptionsV2(
    availabilityIteratorFactory,
    cal,
    restaurant.orders.future.delayMins.max / 1440,
    restaurant.timezone,
    0,
  );

  const timeOptions = getTimeOptions({
    day: dateOptions[0].timestamp,
    locale: restaurant.locale,
    timezone: restaurant.timezone,
    availabilityIteratorFactory,
    breakOnFirst: true,
  });

  return { timingOption: 'future', timestamp: timeOptions[0].timestamp };
}

interface dtlv2_getSupportedDispatchTypesArgs {
  restaurants: Restaurant[];
  when?: number;
}

export function dtlv2_getSupportedDispatchTypes({
  restaurants,
  when,
}: dtlv2_getSupportedDispatchTypesArgs): SupportedDispatchTypes {
  return restaurants
    .map((restaurant) =>
      dtlv2_getSupportedDispatchTypesInner({ restaurant, when }),
    )
    .reduce((previousValue, currentValue) => {
      const result: SupportedDispatchTypes = {};
      ['takeout', 'dine-in', 'delivery'].forEach((dt) => {
        const dispatchType: VirtualDispatchType = dt as VirtualDispatchType;
        result[dispatchType] = {
          isAvailable: Boolean(
            previousValue[dispatchType]?.isAvailable ||
              currentValue[dispatchType]?.isAvailable,
          ),
          willBeAvailable: Boolean(
            previousValue[dispatchType]?.willBeAvailable ||
              currentValue[dispatchType]?.willBeAvailable,
          ),
        };
      });
      return result;
    }, {});
}
