import throttle from 'lodash/throttle';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

export default function documentLogic() {
  onMount();

  forEach(document.querySelectorAll('[data-hook="nav-link"]'), (element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      const sectionId = event.currentTarget.getAttribute('data-section-id');
      hideAllWrappersBut(sectionId);
      setHeight();
    });
  });

  forEach(
    document.querySelectorAll('[data-hook="section-select"]'),
    (element) => {
      element.addEventListener('change', (event) => {
        event.preventDefault();
        const sectionId = event.currentTarget.value;
        hideAllWrappersBut(sectionId);
        setHeight();
      });
    },
  );
}

function hideAllWrappersBut(sectionId) {
  forEach(
    document.querySelectorAll('[data-hook="wixrest-menus-section-wrapper"]'),
    (element) => {
      const wrapperId = element.getAttribute('data-wrapper-id');
      if (wrapperId === sectionId) {
        element.style.display = '';
        element.setAttribute('aria-hidden', false);
      } else {
        element.style.display = 'none';
        element.setAttribute('aria-hidden', true);
      }
    },
  );
}

export function setHeight() {
  const menusAppElement = document.querySelector('.MenusApp');

  if (menusAppElement) {
    const height = menusAppElement.offsetHeight;

    if (typeof Wix !== 'undefined') {
      Wix.setHeight(height + 10);
    }

    if (typeof FB !== 'undefined' && FB.Canvas) {
      FB.init();
      FB.Canvas.setSize({ height: height + 80 });
    }

    return height;
  }
}

export function verifyFBHeight(desiredHeight) {
  if (desiredHeight + 80 > window.innerHeight) {
    setHeight();

    if (!window.verifyFBHeightTimeout) {
      window.verifyFBHeightRetry = window.verifyFBHeightRetry || 0;
      window.verifyFBHeightTimeout = setTimeout(() => {
        window.verifyFBHeightTimeout = null;
        window.verifyFBHeightRetry += 1;

        if (window.verifyFBHeightRetry <= 10) {
          verifyFBHeight(desiredHeight);
        }
      }, 500);
    }
  }
}

export function verifyHeight(userAgent) {
  if (!isAppleDevice(userAgent)) {
    return;
  }

  if (typeof window.verifyHeightRetryCount !== 'number') {
    window.verifyHeightRetryCount = 0;
  }

  setTimeout(() => {
    window.verifyHeightRetryCount += 1;

    if (window.verifyHeightRetryCount <= 20) {
      setHeight();
      verifyHeight(userAgent);
    }
  }, 1000);
}

function onMount() {
  const userAgent = get(window, 'navigator.userAgent');
  const height = setHeight();
  verifyFBHeight(height);

  window.addEventListener(
    'resize',
    throttle(() => {
      setHeight();
    }),
    100,
  );

  applyPositionFixed(userAgent);
  verifyHeight(userAgent);
}

export function applyPositionFixed(userAgent) {
  // Required to fix strange ios bug where iframe is rendered blank
  if (isAppleDevice(userAgent) && !isSafari(userAgent)) {
    try {
      document.body.classList.add('MenusApp_PositionFixed');
    } catch (e) {
      console.log('[documentLogic] Error applying class name to body element');
    }
  }
}

export function isAppleDevice(userAgent) {
  if (typeof userAgent !== 'string') {
    return false;
  }

  return (
    userAgent.indexOf('iPhone') !== -1 ||
    userAgent.indexOf('iPad') !== -1 ||
    userAgent.indexOf('iPod') !== -1
  );
}

export function isSafari(userAgent) {
  return (
    typeof userAgent === 'string' &&
    userAgent.indexOf('Safari') !== -1 &&
    userAgent.indexOf('Chrome') === -1 &&
    userAgent.indexOf('CriOS') === -1
  );
}
