interface Currency {
  [key: string]: {
    currency: string;
    symbol: string;
    name: string;
  };
}

const _cachedAllCurrencies: Currency = {
  AED: { currency: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  AFN: { currency: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  ALL: { currency: 'ALL', symbol: 'L', name: 'Albanian lek' },
  AMD: { currency: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  ANG: {
    currency: 'ANG',
    symbol: 'NA\u0192',
    name: 'Netherlands Antillean gulden',
  },
  AOA: { currency: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  ARS: { currency: 'ARS', symbol: '$', name: 'Argentine peso' },
  AUD: { currency: 'AUD', symbol: '$', name: 'Australian dollar' },
  AWG: { currency: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  AZN: { currency: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  BAM: {
    currency: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka',
  },
  BBD: { currency: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  BDT: { currency: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  BGN: { currency: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  BHD: { currency: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  BIF: { currency: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  BMD: { currency: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  BND: { currency: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  BOB: { currency: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  BRL: { currency: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  BSD: { currency: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  BTN: { currency: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  BWP: { currency: 'BWP', symbol: 'P', name: 'Botswana pula' },
  BYN: { currency: 'BYN', symbol: 'Br', name: 'Belarusian ruble' },
  BYR: { currency: 'BYR', symbol: 'Br', name: 'Belarusian ruble (old)' },
  BZD: { currency: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  CAD: { currency: 'CAD', symbol: '$', name: 'Canadian dollar' },
  CDF: { currency: 'CDF', symbol: 'F', name: 'Congolese franc' },
  CHF: { currency: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  CLP: { currency: 'CLP', symbol: '$', name: 'Chilean peso' },
  CNY: { currency: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  COP: { currency: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  CRC: { currency: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  CUC: { currency: 'CUC', symbol: '$', name: 'Cuban peso' },
  CVE: { currency: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  CZK: { currency: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  DJF: { currency: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  DKK: { currency: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  DOP: { currency: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  DZD: { currency: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  EEK: { currency: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  EGP: { currency: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  ERN: { currency: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  ETB: { currency: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  EUR: { currency: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  FJD: { currency: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  FKP: { currency: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  GBP: { currency: 'GBP', symbol: '\u00a3', name: 'British pound' },
  GEL: { currency: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  GHS: { currency: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  GIP: { currency: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  GMD: { currency: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  GNF: { currency: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  GQE: { currency: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  GTQ: { currency: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  GYD: { currency: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  HKD: { currency: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  HNL: { currency: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  HRK: { currency: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  HTG: { currency: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  HUF: { currency: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  IDR: { currency: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  ILS: { currency: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  INR: { currency: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  IQD: { currency: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  IRR: { currency: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  ISK: { currency: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  JMD: { currency: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  JOD: { currency: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  JPY: { currency: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  KES: { currency: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  KGS: {
    currency: 'KGS',
    symbol: '\u0441\u043e\u043c',
    name: 'Kyrgyzstani som',
  },
  KHR: { currency: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  KMF: { currency: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  KPW: { currency: 'KPW', symbol: 'W', name: 'North Korean won' },
  KRW: { currency: 'KRW', symbol: 'W', name: 'South Korean won' },
  KWD: { currency: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  KYD: { currency: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  KZT: { currency: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  LAK: { currency: 'LAK', symbol: 'KN', name: 'Lao kip' },
  LBP: { currency: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  LKR: { currency: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  LRD: { currency: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  LSL: { currency: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  LTL: { currency: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  LVL: { currency: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  LYD: { currency: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  MAD: { currency: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  MDL: { currency: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  MGA: { currency: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  MKD: { currency: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  MMK: { currency: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  MNT: { currency: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  MOP: { currency: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  MRO: { currency: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  MUR: { currency: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  MVR: { currency: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  MWK: { currency: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  MXN: { currency: 'MXN', symbol: '$', name: 'Mexican peso' },
  MYR: { currency: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  MZN: { currency: 'MZN', symbol: 'MTn', name: 'Mozambican metical' },
  NAD: { currency: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  NGN: { currency: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  NIO: { currency: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  NOK: { currency: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  NPR: { currency: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  NZD: { currency: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  OMR: { currency: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  PAB: { currency: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  PEN: { currency: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  PGK: { currency: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  PHP: { currency: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  PKR: { currency: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  PLN: { currency: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  PYG: { currency: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  QAR: { currency: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  RON: { currency: 'RON', symbol: 'L', name: 'Romanian leu' },
  RSD: { currency: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  RUB: { currency: 'RUB', symbol: 'руб', name: 'Russian ruble' },
  RWF: { currency: 'RWF', symbol: 'Fr', name: 'Rwandan franc' },
  SAR: { currency: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  SBD: { currency: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  SCR: { currency: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  SDG: { currency: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  SEK: { currency: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  SGD: { currency: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  SHP: { currency: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  SLL: { currency: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  SOS: { currency: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  SRD: { currency: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  SSP: { currency: 'SSP', symbol: '£', name: 'South Sudanese pound' },
  STD: { currency: 'STD', symbol: 'Db', name: 'São Tomé and Príncipe dobra' },
  SYP: { currency: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  SZL: { currency: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  THB: { currency: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  TJS: { currency: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  TMT: { currency: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  TND: { currency: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  TOP: { currency: 'TOP', symbol: 'T$', name: 'Tongan paʻanga' },
  TRY: { currency: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  TTD: { currency: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  TWD: { currency: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  TZS: { currency: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  UAH: { currency: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  UGX: { currency: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  USD: { currency: 'USD', symbol: '$', name: 'United States dollar' },
  UYU: { currency: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  UZS: { currency: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  VEF: { currency: 'VEF', symbol: 'Bs', name: 'Venezuelan bolivar' },
  VND: { currency: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  VUV: { currency: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  WST: { currency: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  XAF: { currency: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  XCD: { currency: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  XDR: { currency: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  XOF: { currency: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  XPF: { currency: 'XPF', symbol: 'F', name: 'CFP franc' },
  YER: { currency: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  ZAR: { currency: 'ZAR', symbol: 'R', name: 'South African rand' },
  ZMW: { currency: 'ZMW', symbol: 'ZK', name: 'Zambian kwacha' },
  ZWR: { currency: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' },
};

export const currencySymbol = (currency: string) => {
  return (_cachedAllCurrencies[currency] || {}).symbol;
};
