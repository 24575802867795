import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Item, LocationMenu, Menu, MenuSection } from '../types/Menu';

function isDefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

function getDishVariationsIds(menu: Menu, item: Item) {
  const dishIds = new Set([item.id]);

  dishIds.forEach((dishId) => {
    const variations = menu.items.find((currItem) => currItem.id === dishId)
      ?.variations;

    if (variations) {
      variations.forEach((variation) =>
        variation.itemIds.forEach((id) => dishIds.add(id)),
      );
    }
  });

  dishIds.delete(item.id);
  return Array.from(dishIds);
}

function getSectionDishes(menu: Menu, section: MenuSection): Item[] {
  const dishIds = new Set([
    ...(section.itemIds || []),
    ...(section.children?.flatMap((child) => child.itemIds || []) || []),
  ]);

  dishIds.forEach((dishId) => {
    const dish = menu.items.find((item) => item.id === dishId);

    if (dish) {
      getDishVariationsIds(menu, dish).forEach((id) => dishIds.add(id));
    }
  });

  return Array.from(dishIds)
    .map((id) => menu.items.find((item) => item.id === id))
    .filter(isDefined);
}

function getNewId(oldId: string, dishCopies: Map<string, Item>) {
  const newId = dishCopies.get(oldId)?.id;

  if (!newId) {
    throw new Error(`Id '${oldId}' has no matching dish copy`);
  }

  return newId;
}

function copyDishesToMenu(destMenu: Menu, dishCopies: Map<string, Item>) {
  dishCopies.forEach((newDish) => {
    destMenu.items.push({
      ...newDish,
      variations: newDish.variations?.map((variation) => ({
        ...variation,
        itemIds: variation.itemIds.map((id) => getNewId(id, dishCopies)),
        defaults: variation.defaults?.map((id) => getNewId(id, dishCopies)),
        prices:
          variation.prices &&
          Object.entries(variation.prices).reduce(
            (res, [id, val]) =>
              dishCopies.has(id)
                ? {
                    ...res,
                    [getNewId(id, dishCopies)]: val,
                  }
                : res,
            {},
          ),
      })),
    });
  });
}

export function copySectionsToMenu(
  origMenu: LocationMenu,
  destMenu: LocationMenu,
  sectionsMap: Map<string, Partial<MenuSection>>,
): Menu {
  const isSameMenu = origMenu.locationId === destMenu.locationId;
  const destMenuCopy = _.cloneDeep(destMenu);
  const sections = origMenu.sections
    .filter((currSection) => sectionsMap.has(currSection.id))
    .map((section) => ({
      ...section,
      ...sectionsMap.get(section.id),
    }));

  if (sections.length !== sectionsMap.size) {
    throw new Error(
      `Some of the sections ${Array.from(
        sectionsMap.keys(),
      )} were not found in menu`,
    );
  }

  const sectionsDishes = sections.reduce<Item[]>(
    (res, section) =>
      Array.from(new Set([...res, ...getSectionDishes(origMenu, section)])),
    [],
  );
  const dishCopies = new Map(
    sectionsDishes.map((dish) => [
      dish.id,
      isSameMenu ? dish : { ...dish, id: uuidv4() },
    ]),
  );

  sections.forEach((section) => {
    destMenuCopy.sections.push({
      ...section,
      id: uuidv4(),
      itemIds: section.itemIds?.map((id) => getNewId(id, dishCopies)),
      children: section.children?.map((child) => ({
        ...child,
        id: uuidv4(),
        itemIds: child.itemIds?.map((id) => getNewId(id, dishCopies)),
      })),
    });
  });

  if (!isSameMenu) {
    copyDishesToMenu(destMenuCopy, dishCopies);
  }

  return destMenuCopy;
}

export function copySubSectionToMenuSections(
  origMenu: LocationMenu,
  sectionId: string,
  subSectionId: string,
  destMenu: LocationMenu,
  destSectionIds: string[],
  subSectionData: Partial<MenuSection>,
): Menu {
  const isSameMenu = origMenu.locationId === destMenu.locationId;
  const destMenuCopy = _.cloneDeep(destMenu);
  const section = origMenu.sections.find(
    (currSection) => currSection.id === sectionId,
  );
  const subSection = section?.children?.find(
    (child) => child.id === subSectionId,
  );

  if (!subSection) {
    throw new Error(`Sub section '${subSectionId}' was not found in menu`);
  }

  const destSections = destMenuCopy.sections.filter((currSection) =>
    destSectionIds.includes(currSection.id),
  );

  if (destSections.length !== destSectionIds.length) {
    throw new Error(
      `Some of the sections '${destSectionIds}' were not found in the destination menu`,
    );
  }

  const sectionDishes = getSectionDishes(origMenu, subSection);
  const dishCopies = new Map(
    sectionDishes.map((dish) => [
      dish.id,
      isSameMenu ? dish : { ...dish, id: uuidv4() },
    ]),
  );

  const destSubSection = {
    ...subSection,
    ...subSectionData,
    itemIds: subSection.itemIds?.map((id) => getNewId(id, dishCopies)),
    children: subSection.children?.map((child) => ({
      ...child,
      id: uuidv4(),
      itemIds: child.itemIds?.map((id) => getNewId(id, dishCopies)),
    })),
  };

  destSections.forEach((destSection) => {
    if (destSection.children) {
      destSection.children.push({
        ...destSubSection,
        id: uuidv4(),
      });
    } else {
      destSection.children = [
        {
          ...destSubSection,
          id: uuidv4(),
        },
      ];
    }
  });

  if (!isSameMenu) {
    copyDishesToMenu(destMenuCopy, dishCopies);
  }

  return destMenuCopy;
}

export function copyDishToMenuSubSections(
  origMenu: LocationMenu,
  dishId: string,
  destMenu: LocationMenu,
  destSubSectionIds: { sectionId: string; subSectionIds: string[] }[],
  dishData: Partial<Item>,
): Menu {
  const isSameMenu = origMenu.locationId === destMenu.locationId;
  const destMenuCopy = _.cloneDeep(destMenu);
  const origDish = origMenu.items.find((currItem) => currItem.id === dishId);

  if (!origDish) {
    throw new Error(`Item '${dishId}' was not found in menu`);
  }

  const dish = { ...origDish, ...dishData };
  const destSections = destMenu.sections.filter((section) =>
    destSubSectionIds.find(({ sectionId }) => sectionId === section.id),
  );

  if (destSections.length !== destSubSectionIds.length) {
    throw new Error(
      `Some of the sections [${destSubSectionIds.map(
        ({ sectionId }) => sectionId,
      )}] were not found in the destination menu`,
    );
  }

  const dishCopy = { ...dish, ...dishData, id: uuidv4() };

  destMenuCopy.sections = destMenuCopy.sections.map((section) => {
    const currDestSubSectionIds = destSubSectionIds.find(
      ({ sectionId }) => sectionId === section.id,
    );

    if (currDestSubSectionIds) {
      return {
        ...section,
        children: section.children?.map((subSection) => {
          if (
            currDestSubSectionIds.subSectionIds.find(
              (subSectionId) => subSectionId === subSection.id,
            )
          ) {
            return {
              ...subSection,
              itemIds: [...(subSection.itemIds || []), dishCopy.id],
            };
          }

          return subSection;
        }),
      };
    }

    return section;
  });

  if (!isSameMenu) {
    const dishCopies: Map<string, Item> = new Map();
    dishCopies.set(dish.id, dishCopy);

    const variationIds = getDishVariationsIds(origMenu, dish);

    for (const id of variationIds) {
      const variationDish = origMenu.items.find((item) => item.id === id);

      if (variationDish) {
        dishCopies.set(id, { ...variationDish, id: uuidv4() });
      }
    }

    copyDishesToMenu(destMenuCopy, dishCopies);
  } else {
    destMenuCopy.items.push(dishCopy);
  }

  return destMenuCopy;
}

export function removeSectionFromMenu(menu: Menu, sectionId: string): Menu {
  return {
    ...menu,
    sections: menu.sections.filter((section) => section.id !== sectionId),
  };
}

export function removeSubSectionFromMenu(
  menu: Menu,
  sectionId: string,
  subSectionId: string,
): Menu {
  return {
    ...menu,
    sections: menu.sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          children: section.children?.filter(
            (subSection) => subSection.id !== subSectionId,
          ),
        };
      }

      return section;
    }),
  };
}
