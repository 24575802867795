import { IHttpClient } from '@wix/http-client';
import { retry } from '../utils';
import { Menu, LocationMenu } from '../types/Menu';

export const fallbackMenu = {
  sections: [],
  items: [],
  chargesV2: [],
};

export const getMenusUrl = (orgId: string, locationId?: string) => {
  if (locationId) {
    return `https://api.wixrestaurants.com/v2/organizations/${orgId}/menu?locationId=${locationId}`;
  }

  return `https://api.wixrestaurants.com/v2/organizations/${orgId}/defaultMenu`;
};

export async function getOrganizationMenu(
  orgId: string,
  locationId?: string,
  httpClient?: IHttpClient,
  options?: {
    retry: Parameters<typeof retry>[1];
  },
) {
  let menu: LocationMenu = {
    sections: [],
    items: [],
    chargesV2: [],
    locationId,
  };
  return retry(
    async (bail, _attempt) => {
      if (!httpClient) {
        try {
          const response = await fetch(getMenusUrl(orgId, locationId));

          if (response.status === 200) {
            menu = (await response.json()) as LocationMenu;
          } else {
            bail(new Error(response.statusText));
            return;
          }
        } catch (error) {
          bail(error);
          return;
        }

        if (menu.items === undefined) {
          throw new Error('Menu Not Ready');
        }
      } else {
        try {
          const response = await httpClient.get<LocationMenu>(
            getMenusUrl(orgId, locationId),
          );

          if (response.status === 200) {
            menu = response.data;
          } else {
            bail(new Error(response.statusText));
            return;
          }
        } catch (error) {
          bail(error);
          return;
        }

        if (menu.items === undefined) {
          throw new Error('Menu Not Ready');
        }
      }

      return menu;
    },
    { retries: 0, ...options?.retry },
  ).catch((error) => {
    if (error.message === 'Menu Not Ready') {
      return Object.assign(fallbackMenu, menu);
    }
    throw error;
  });
}

export async function updateOrganizationMenu(
  orgId: string,
  menu: Menu,
  authorization: string,
  locationId?: string,
  httpClient?: IHttpClient,
): Promise<LocationMenu> {
  const url = `https://api.wixrestaurants.com/v2/organizations/${orgId}/menu${
    locationId ? `?locationId=${locationId}` : ''
  }`;

  if (!httpClient) {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(menu),
      headers: {
        authorization,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return {
        sections: [],
        items: [],
        chargesV2: [],
        locationId,
        ...(await response.json()),
      };
    }

    throw new Error(response.statusText);
  } else {
    const response = await httpClient.put<Partial<Menu>>(url, menu, {
      headers: {
        authorization,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return {
        sections: [],
        items: [],
        chargesV2: [],
        locationId,
        ...response.data,
      };
    }

    throw new Error(response.statusText);
  }
}
