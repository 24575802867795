require('./RestaurantMenuTableViewImageTopMobile.less');

import I18n from '@wix/wixrest-utils/dist/I18n';
import ImageUtils from 'openrest4js/dist/openrest4js-helpers/Image';

import clamp from 'clamp-js';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

const { I18N } = I18n.getComponentHeader('openrest_wixrestaurantmenuview_');

class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;
            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                properties={This.props.properties}
                picture={data.picture}
                children={children}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuSubCategory';

  state = {
    size: 'twos400',
  };

  render() {
    const This = this;
    const children = JSON.parse(JSON.stringify(this.props.children));
    const styles = this.props.styles;
    const properties = this.props.properties;

    let descriptionClass = 'description ';
    if (!this.props.description) {
      descriptionClass += 'hidden ';
    }

    const sectionImageStyle = {
      border: 'none',
    };

    const backgroundDiv = (() => {
      if (this.props.picture) {
        const background = {
          backgroundImage:
            'url(' +
            ImageUtils.fillSharp({
              url: this.props.picture,
              width: 900,
              height: 225,
            }) +
            ')',
          margin: '0px',
        };
        return (
          <div className="background-wrapper" style={sectionImageStyle}>
            <div className="background" style={background}></div>
          </div>
        );
      } else {
        return <span></span>;
      }
    })();

    _.defer(() => {
      if (!This.refs.wrapper) return;
      const width = $(ReactDOM.findDOMNode(This.refs.wrapper)).width();

      let newSize = 'ones';
      if (width > 900) {
        newSize = 'threes';
      } else if (width > 700) {
        newSize = 'threes';
      } else if (width > 600) {
        newSize = 'twos';
      } else if (width > 400) {
        newSize = 'twos';
      }

      if (newSize != This.state.size) {
        This.setState({ size: newSize });
      }
    });

    const titleStyle = {};

    if (!styles.fonts['wixmenu-subcategorytitlexlmobile'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }

    return (
      <div
        className={'subcategory ' + this.state.size}
        data-id={this.props.id}
        ref="wrapper"
      >
        {backgroundDiv}
        <h1 className="title" style={titleStyle}>
          {this.props.title}
        </h1>
        <div className={descriptionClass}>{this.props.description}</div>
        <div className="titledescriptionpadding"></div>

        <div className="subcategorychildren">
          {_.map(children, function(item) {
            const data = item.data || {};
            const children = item.children;
            if (!data.title) {
              return <div></div>;
            } else {
              return (
                <REACT_MENU_ITEM
                  title={data.title}
                  picture={data.picture}
                  description={data.description}
                  featured={data.featured}
                  price={data.price}
                  children={children}
                  styles={styles}
                  properties={properties}
                  key={This.props.id + '-' + data.id}
                  id={This.props.id + '-' + data.id}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_ITEM extends React.Component {
  static displayName = 'legacy/ReactMenuItem';

  render() {
    const styles = this.props.styles;
    const properties = this.props.properties;

    const priceHidden = this.props.priceHidden || !this.props.price;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({ item: true, featured: this.props.featured });
    const descriptionClasses = cx({ description: true });
    const priceClasses = cx({ price: true, hidden: priceHidden });
    const titleClasses = cx({ title: true, full: priceHidden });

    const This = this;

    const titleStyle = {
      fontWeight: 'bold !important',
      position: 'relative',
    };

    const displayFeatured = this.props.featured ? (
      <REACT_MENU_ITEM_FEATURED />
    ) : null;

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    _.defer(() => {
      if (typeof window === 'undefined') return;
      try {
        clamp(ReactDOM.findDOMNode(This.refs.clamp), { clamp: 3 });
      } catch (e) {
        console.log('lagacy/RestaurantMenuTableViewImageTopMobile clamp error');
      }
    });

    return (
      <div
        className={itemClasses}
        data-is-last-item={this.props.isLastItem}
        style={this.props.itemstyle}
      >
        <REACT_MENU_ITEM_IMAGE
          src={this.props.picture}
          styles={styles}
          properties={properties}
        />
        <div className="titledescription">
          <div className={titleClasses} ref="title">
            <div className="background"></div>
            <div ref="clamp" style={titleStyle}>
              {this.props.title} {displayFeatured}
            </div>
          </div>
          <div className={priceClasses}>
            <div className="background"></div>
            <div style={priceStyle}>{this.props.price}</div>
          </div>
          <div className={descriptionClasses}>{this.props.description}</div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                title={data.title}
                description={data.description}
                price={data.price}
                styles={styles}
                properties={properties}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_ITEM_FEATURED extends React.Component {
  static displayName = 'legacy/ReactMenuItemFeatured';

  render() {
    return <span className="special">{I18N('special', 'Special')}</span>;
  }
}

class REACT_MENU_ITEM_IMAGE extends React.Component {
  static displayName = 'legacy/ReactMenuItemImage';

  render() {
    const styles = this.props.styles;

    const placeholderHidden = !styles.booleans[
      'wixmenu.widgets.placeholderimages'
    ];
    const imageStyle = styles.numbers['wixmenu.widgets.imagesstyle'];

    let itemclasses = 'itemimage ';
    if (imageStyle === 1) {
      itemclasses += 'wide';
    }

    if (this.props.src) {
      var style = {
        backgroundImage:
          'url(' +
          ImageUtils.fillSharp({
            url: this.props.src,
            width: 225,
            height: 169,
          }) +
          ')',
      };

      return <div className={itemclasses} style={style}></div>;
    } else {
      var style = {
        backgroundColor: styles.colors['wixmenu-itemplaceholder'],
        visibility: placeholderHidden ? 'hidden' : '',
      };

      return <div className={itemclasses} style={style}></div>;
    }
  }
}

class REACT_MENU_VARIATIONITEM extends React.Component {
  static displayName = 'legacy/ReactMenuVariationItem';

  render() {
    //var styles = this.props.styles;

    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    // let defaultStr = (Wix.Utils.getCompId() || Wix.Utils.getOrigCompId())
    // let priceHidden = !styles.booleans[defaultStr + '.wixmenu.widgets.price'];
    // let variationsHidden = !styles.booleans[defaultStr + '.wixmenu.widgets.variations'];

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const priceClasses = cx({ price: true, hidden: priceHidden });
    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });

    const titleStyle = {
      fontStyle: 'normal !important',
    };

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
      >
        <div className="title" style={titleStyle}>
          {this.props.title}
        </div>
        <div className={priceClasses} style={priceStyle}>
          {this.props.price}
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  static displayName =
    'menus/web/legacy/RestaurantMenuTableViewImageTopMobile.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    return (
      <div className="openrest_restaurantmenutableviewimagetopmobile_legacy_wrapper">
        {_.map(menu, function(category) {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={This.props.styles}
              properties={This.props.properties}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewImageTopMobile] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + '"]',
    );
    console.log(
      '[RestaurantMenuTableViewImageTopMobile] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewImageTopMobile] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
