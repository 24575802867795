import { listOrders } from '@wix/ambassador-restaurants-v3-order/http';
import {
  Order,
  ListOrdersRequest,
  LineItem,
  Status,
  SortOrder,
  FieldName,
  ListOrdersResponse,
} from '@wix/ambassador-restaurants-v3-order/types';
import type { FieldInfo } from 'json2csv';
import { Parser } from 'json2csv';
import { camelCase, uniq } from 'lodash';
import { formatField } from './fieldsFormatter';
import type {
  BasicFieldInfo,
  CsvField,
  CsvParams,
  FieldToDisplay,
  OrderWithDishes,
  ParsedDishes,
  ParsedFields,
} from './types';
import { IHttpClient } from '@wix/http-client';

const GREATER_THAN = 'gte:';
const LINE_ITEMS_FIELD = 'lineItems';
const CAMEL_CASE_FIELD_SEPARATOR = '.';

function addCursorToParams(
  listOrdersParams: ListOrdersRequest,
  next?: string | null,
): ListOrdersRequest {
  return next
    ? { ...listOrdersParams, cursorPaging: { cursor: next } }
    : listOrdersParams;
}

function getLineItemName(
  item: LineItem | undefined,
): string | undefined | null {
  return item?.catalogReference?.catalogItemName;
}

function parseDishesDetails(orders: Order[]): ParsedDishes {
  const dishesSet = new Set<string>();
  const ordersWithDishes = orders.map((order) => {
    const dishes: Record<string, number | undefined> = {};
    const modifiers: string[] = [];
    const orderDishes = order.lineItems ?? [];
    for (const dish of orderDishes) {
      const dishName = getLineItemName(dish);
      const dishQuantity = dish?.quantity || 0;
      if (dishName) {
        dishesSet.add(dishName);
        dishes[dishName] = Number(dishes[dishName] || 0) + dishQuantity;
      }
      if (dish.dishOptions) {
        const selectedChoices: string[] = dish.dishOptions
          .flatMap((dishOption) => dishOption.selectedChoices)
          .filter((choice) => Boolean(getLineItemName(choice)))
          .map((choice) => getLineItemName(choice)!);
        modifiers.push(...selectedChoices);
      }
    }

    return { ...order, ...dishes, modifiers: modifiers.join(', ') };
  });
  const dishesFields = Array.from(dishesSet).map((dishField) => ({
    value: dishField,
    default: '0',
  }));
  return {
    ordersWithDishes,
    dishesFields: [...dishesFields, { value: 'modifiers', default: '' }],
  };
}

function snakeCase(field: string): string {
  return field
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

function convertTextFormat(
  text: string,
  formatter: (field: string) => string,
): string {
  // This function convert string format
  // CamelCase: 'customer.first_name' -> 'customer.firstName'
  // SnakeCase: 'customer.firstName' -> 'customer.first_name'
  // We can't use the normal lodash.FORMATTER because it converts '.' as well.
  // We want to preserve the dot notation.
  return text
    .split(CAMEL_CASE_FIELD_SEPARATOR)
    .map((item) => formatter(item))
    .join(CAMEL_CASE_FIELD_SEPARATOR);
}

function generateCsvField(
  { serverField, title, defaultValue }: FieldToDisplay,
  timeZone?: string,
): FieldInfo<Order>[] | FieldInfo<Order> {
  const formattedFieldName = convertTextFormat(serverField, camelCase);
  const label = title ?? formattedFieldName;
  const fieldInfo: BasicFieldInfo<Order> = {
    label,
    value: formattedFieldName,
    default: defaultValue ?? '',
  };
  return formatField({ fieldInfo, timeZone });
}

export function parseFields(
  fieldsToDisplay: FieldToDisplay[],
  timeZone: string,
): ParsedFields {
  const fieldsForServer = uniq(
    fieldsToDisplay.map((field) =>
      convertTextFormat(field.serverField, snakeCase),
    ),
  );
  const fieldsForCsv = fieldsToDisplay.flatMap((field) =>
    generateCsvField(field, timeZone),
  );
  return { fieldsForServer, fieldsForCsv };
}

export function generateListOrdersParams(
  fieldMask: string[],
  startTime: number,
  restaurantLocation?: string,
): ListOrdersRequest {
  const startDate = new Date(startTime);

  const requestObject: ListOrdersRequest = {
    fieldMask,
    sort: {
      order: SortOrder.ASC,
      fieldName: FieldName.CREATED_DATE,
    },
    createdDate: `${GREATER_THAN}${startDate.toISOString()}`,
    cursorPaging: { limit: 100 },
  };
  if (restaurantLocation) {
    requestObject.locationIds = [restaurantLocation];
  }

  return requestObject;
}

export async function getOrders(
  httpClient: IHttpClient,
  listOrdersParams: ListOrdersRequest,
  nextCursor?: string | null,
): Promise<Order[]> {
  const paramsWithCursor = addCursorToParams(listOrdersParams, nextCursor);
  const {
    data: { orders, pagingMetadata },
  } = await httpClient.request<ListOrdersResponse>(
    listOrders(paramsWithCursor),
  );
  if (orders) {
    const filteredOrders = orders.filter(
      (order) =>
        order.status !== Status.PENDING &&
        order.status !== Status.UNSPECIFIED_ORDER_STATUS,
    );
    const hasNext = pagingMetadata?.hasNext;
    if (hasNext) {
      const next = pagingMetadata?.cursors?.next;
      if (next) {
        const nextOrders = await getOrders(httpClient, listOrdersParams, next);
        return [...filteredOrders, ...nextOrders];
      }
      throw new Error(
        'Server Failed to provider next option for listOrder request',
      );
    }
    return filteredOrders;
  }
  return [];
}

export function getCsvParams(
  orders: Order[],
  fields: FieldInfo<Order>[],
): CsvParams {
  let csvOrders: OrderWithDishes[] = orders;
  let csvFields: CsvField[] = fields;
  const lineItemsFieldIndex: number = fields.findIndex(
    (field) => field.value === LINE_ITEMS_FIELD,
  );
  if (lineItemsFieldIndex > -1) {
    const { ordersWithDishes, dishesFields } = parseDishesDetails(orders);
    const fieldsWithoutLineItems = fields.filter(
      (field) => field.value !== LINE_ITEMS_FIELD,
    );
    csvOrders = ordersWithDishes;
    csvFields = [...fieldsWithoutLineItems, ...dishesFields];
  }
  return { csvOrders, csvFields };
}

export function createCsvFromJson(
  orders: OrderWithDishes[],
  fields: CsvField[],
): string {
  const json2csvParser = new Parser({ fields, excelStrings: true });
  return json2csvParser.parse(orders);
}
