// copied from https://github.com/wix/wix-restaurants-js-sdk/blob/master/src/helpers/Order.js
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import {
  isApplicable as isApplicableCharge,
  calculateAmount,
} from './chargesV2';
import { DispatchType } from '../types/Dispatch';
import { OrderItem } from '../types/OrderItem';
import { ChargeV2 } from '../types/Menu';
import { OrderCharge } from '../types/Order';
import { calcOrderItemGrandTotal } from './orderItemLogic';

/**
 * @return The orderCharges (ChargesV2) that should be added to the order
 */
export const getOrderCharges = ({
  dispatchType,
  dispatchTime,
  orderItems,
  tip,
  source,
  platform,
  chargesV2,
  timezone,
  couponHashCode,
  roundingStrategy,
}: {
  dispatchType: DispatchType;
  dispatchTime: Moment;
  orderItems: OrderItem[];
  tip?: number;
  source?: string;
  platform: string;
  chargesV2: ChargeV2[];
  timezone: string;
  couponHashCode?: string;
  roundingStrategy?: string;
}) => {
  let orderCharges: OrderCharge[] = [];
  let prevOrderCharges = [];

  const _dispatchTime = moment(dispatchTime).tz(timezone);
  const totalOrderPrice = orderItems.reduce(
    (acc: number, curr: OrderItem) => acc + calcOrderItemGrandTotal(curr),
    0,
  );

  do {
    prevOrderCharges = orderCharges;

    orderCharges = _.compact(
      _.map(chargesV2, (charge) => {
        const isApplicable = isApplicableCharge({
          charge,
          dispatchTime: _dispatchTime,
          dispatchType,
          orderItems,
          source,
          platform,
          couponHashCode,
          totalOrderPrice,
        });

        if (!isApplicable) {
          if (charge.mandatory && charge.state !== 'closed') {
            return { chargeId: charge.id, amount: 0 };
          } else {
            return null;
          }
        } else {
          const amount = calculateAmount({
            charge,
            dispatchTime: _dispatchTime,
            dispatchType,
            source,
            tip,
            platform,
            orderItems,
            orderCharges,
            couponHashCode,
            roundingStrategy,
            totalOrderPrice,
          });

          if (charge.mandatory || amount !== 0) {
            return {
              chargeId: charge.id,
              amount: calculateAmount({
                charge,
                dispatchTime: _dispatchTime,
                dispatchType,
                source,
                tip,
                platform,
                orderItems,
                orderCharges,
                couponHashCode,
                roundingStrategy,
                totalOrderPrice,
              }),
            };
          } else {
            return null;
          }
        }
      }),
    );

    orderCharges = _.sortBy(orderCharges, 'chargeId');
    prevOrderCharges = _.sortBy(prevOrderCharges, 'chargeId');
  } while (!_.isEqual(orderCharges, prevOrderCharges));

  return orderCharges;
};

export const calculateTotalOrder = ({
  orderItems,
  orderCharges,
  dispatchCharge = 0,
  serviceFeesTotalAmount = 0,
  serviceFeesTax = 0,
}: {
  orderItems: OrderItem[];
  orderCharges: OrderCharge[];
  dispatchCharge?: number;
  serviceFeesTotalAmount?: number;
  serviceFeesTax?: number;
}) => {
  let price = dispatchCharge + serviceFeesTotalAmount + serviceFeesTax;

  _.each(
    orderItems,
    (orderItem) => (price += calcOrderItemGrandTotal(orderItem)),
  );
  _.each(orderCharges, (orderCharge) => (price += orderCharge.amount));

  return price;
};

export const sumTaxCharges = ({
  chargesV2,
  orderCharges,
}: {
  chargesV2: ChargeV2[];
  orderCharges: OrderCharge[];
}) => {
  if (
    !_.find(
      chargesV2,
      (charge) => charge.type === 'tax' && charge.state !== 'closed',
    )
  ) {
    return null;
  }

  const taxOrderCharges = _.filter(orderCharges, (orderCharge) => {
    const charge = _.find(chargesV2, (c) => c.id === orderCharge.chargeId) || {
      type: '',
      state: '',
    };
    return charge.type === 'tax' && charge.state !== 'closed';
  });
  return _.sumBy(taxOrderCharges, 'amount') || 0;
};
