require('./RestaurantMenuTableViewDoubleColumn.less');

import I18n from '@wix/wixrest-utils/dist/I18n';
import RestaurantMenuTableViewDottedLine from '../../logic/RestaurantMenuTableViewDottedLine.js';

const { I18N } = I18n.getComponentHeader('openrest_wixrestaurantmenuview_');

import Masonry from 'react-masonry-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import createReactClass from 'create-react-class';

const masonryOptions = {
  transitionDuration: 0,
  columnWidth: '.grid-sizer',
  itemSelector: '.grid-item',
  gutter: '.gutter-sizer',
  percentPosition: true,
};

//************************* Templates
class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="title">{this.props.title}</div>
        <div className="description">{this.props.description}</div>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;
            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                picture={data.picture}
                children={children}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                sectionImagesHidden={This.props.sectionImagesHidden}
                isMobile={This.props.isMobile}
                center={This.props.center}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'ReactMenuSubCategory';

  render() {
    const This = this;
    const children = JSON.parse(JSON.stringify(this.props.children));
    if (children.length % 2 === 1 && !this.props.isMobile) {
      children.push({});
    }

    const descriptionHidden = this.props.descriptionHidden;

    let descriptionClass = 'description ';
    if (!this.props.description || descriptionHidden) {
      descriptionClass += 'hidden ';
    }

    const styles = this.props.styles;

    const titleStyle = {};

    if (!styles.fonts['wixmenu-subcategorytitle'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }
    return (
      <div className="subcategory" data-id={this.props.id} ref="wrapper">
        <div className="titledescription">
          <h1 className="title doublebottomborders" style={titleStyle}>
            {this.props.title}
          </h1>
          <div className={descriptionClass}>{this.props.description}</div>
        </div>
        <Masonry className="subcategorychildren" options={masonryOptions}>
          <div className="grid-sizer"></div>
          <div className="gutter-sizer"></div>
          {_.map(children, function(item, index) {
            const data = item.data || {};
            const _children = item.children;
            return (
              <REACT_MENU_ITEM
                title={data.title}
                description={data.description}
                price={data.price}
                children={_children}
                featured={data.featured}
                styles={styles}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                center={This.props.center}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
                isMobile={This.props.isMobile}
                isLastItem={index === children.length - 1}
              />
            );
          })}
        </Masonry>
      </div>
    );
  }
}

const REACT_MENU_ITEM = createReactClass({
  displayName: 'ReactMenuItem',
  mixins: [RestaurantMenuTableViewDottedLine],
  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const styles = this.props.styles;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({
      item: true,
      'grid-item': true,
      featured: this.props.featured,
    });

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden,
    });

    const This = this;

    this._fixDottedLine({
      shouldFixFunc() {
        if (
          priceHidden ||
          !This.props.price ||
          This.props.center ||
          This.props.isMobile
        )
          return false;
        return true;
      },

      alignLineRef:
        descriptionHidden || !This.props.description ? 'title' : 'description',
      priceRef: 'price',
    });

    let titleDots = null;
    let descriptionDots = null;

    if (
      !priceHidden &&
      this.props.price &&
      !this.props.center &&
      !this.props.isMobile
    ) {
      if (!this.props.description || descriptionHidden) {
        titleDots = this._getDotsDom();
      } else {
        descriptionDots = this._getDotsDom();
      }
    }

    let priceStyle = {};

    let displaySpecial = null;
    if (this.props.center) {
      priceStyle = {
        position: 'relative',
        marginTop: '14px',
        marginBottom: '0px',
      };
    } else {
      displaySpecial = this.props.featured ? (
        <span className="special">{I18N('special', 'Special')}</span>
      ) : null;
    }

    const bottomDottedLineStyle = {
      width: '100%',
      color: styles.colors['wixmenu-bordersandseparators'],
      display: this.props.isMobile ? 'block' : 'none',
    };

    return (
      <div className={itemClasses} data-is-last-item={this.props.isLastItem}>
        <div className="titledescription">
          <div className="title" ref="title">
            {this.props.title} {displaySpecial}
            {titleDots}
          </div>
          <div className={descriptionClasses} ref="description">
            {this.props.description}
            {descriptionDots}
          </div>
          <div className={priceClasses} style={priceStyle} ref="price">
            {this.props.price}
          </div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                styles={styles}
                title={data.title}
                description={data.description}
                price={data.price}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                center={This.props.center}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
                isMobile={This.props.isMobile}
              />
            );
          })}
        </div>
        <div className="dottedline" style={bottomDottedLineStyle}>
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . . . . . . . . . . . . . . . .
        </div>
      </div>
    );
  },
});

const REACT_MENU_VARIATIONITEM = createReactClass({
  displayName: 'ReactMenuVariationItem',
  mixins: [RestaurantMenuTableViewDottedLine],
  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden || this.props.center || this.props.isMobile,
    });

    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });

    const This = this;

    let titleDots = null;
    let descriptionDots = null;

    if (
      !priceHidden &&
      this.props.price &&
      !this.props.center &&
      !this.props.isMobile
    ) {
      if (!this.props.description || descriptionHidden) {
        titleDots = this._getDotsDom();
      } else {
        descriptionDots = this._getDotsDom();
      }
    }

    this._fixDottedLine({
      shouldFixFunc() {
        if (
          priceHidden ||
          !This.props.price ||
          This.props.center ||
          This.props.isMobile
        )
          return false;
        return true;
      },

      alignLineRef:
        descriptionHidden || !This.props.description ? 'title' : 'description',
      priceRef: 'price',
    });

    if (this.props.center || this.props.isMobile) {
      var title = (
        <span>
          <span>{this.props.title}</span>
          <span style={{ padding: '0px 5px' }}></span>
          <span>{this.props.price}</span>
        </span>
      );
    } else {
      var title = this.props.title;
    }

    //let
    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
      >
        <div className="titledescription">
          <div className="title" ref="title">
            {title}
            {titleDots}
          </div>
          <div className={descriptionClasses} ref="description">
            {this.props.description}
            {descriptionDots}
          </div>
        </div>
        <div className={priceClasses} ref="price">
          {this.props.price}
        </div>
      </div>
    );
  },
});

export default class extends React.Component {
  static displayName = 'menus/web/RestaurantMenuTableViewDoubleColumn.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    const styles = this.props.styles;

    if (this.props.center) {
      var style = { textAlign: 'center' };
    } else {
      var style = {};
    }

    const mode = this.props.isMobile ? 'mobile' : 'web';

    return (
      <div
        className={
          'openrest_restaurantmenutableviewdoublecolumn_legacy_wrapper ' +
          mode +
          (this.props.center ? ' center' : ' noncenter')
        }
        ref="wrapper"
        style={style}
      >
        {_.map(menu, function(category) {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={styles}
              descriptionHidden={This.props.descriptionHidden}
              priceHidden={This.props.priceHidden}
              variationsHidden={This.props.variationsHidden}
              sectionImagesHidden={This.props.sectionImagesHidden}
              center={This.props.center}
              isMobile={This.props.isMobile}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewDoubleColumn] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + '"]',
    );
    console.log(
      '[RestaurantMenuTableViewDoubleColumn] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewDoubleColumn] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
