import { IHttpClient } from '@wix/http-client';
import { Restaurant } from '../types/Restaurant';
import { LocationMenu, Menu } from '../types/Menu';
import { Location } from '../types/Location';
import { retry } from '../utils';
import { SERVERLESS_BASEURL } from '../constants';

const getSettingsUrl = (locationId?: string) =>
  `https://${SERVERLESS_BASEURL}/_api/restaurants/v2.1/settings${
    locationId ? `?locationId=${locationId}` : ''
  }`;

export async function getOrganizationFull(
  organizationId: string,
  httpClient?: IHttpClient,
) {
  let restaurant, menu;
  const url = `https://api.wixrestaurants.com/v2/organizations/${organizationId}/full`;

  if (!httpClient) {
    const response = await fetch(url);
    const json: {
      restaurant: Restaurant;
      menu: Menu;
    } = await response.json();
    restaurant = json.restaurant;
    menu = json.menu;
  } else {
    const response = await httpClient.get<{
      restaurant: Restaurant;
      menu: Menu;
    }>(url);
    restaurant = response.data.restaurant;
    menu = response.data.menu;
  }

  return {
    restaurant,
    menu,
  };
}

export async function getOrganizationAndMenu(
  signedInstance: string,
  locationId: string | undefined,
  httpClient?: IHttpClient,
  options?: {
    retry: Parameters<typeof retry>[1];
  },
) {
  const url =
    `https://apps.wixrestaurants.com/organization/get?instance=${signedInstance}` +
    (locationId ? `&locationId=${locationId}` : ``);
  let data: {
    value?: {
      restaurant: Restaurant;
      menu: Menu;
      locations: Location[];
    };
    fromSite: boolean;
  };

  return retry(
    async (bail, _attempt) => {
      // This is needed for Yoshi 4 SSR to work since it doesn't use HttpClient, when migrating olo-client to Yoshi 5 we can remove this
      if (!httpClient) {
        try {
          data = await fetch(url).then((response) => response.json());
        } catch (error) {
          bail(error);
          return;
        }

        if (!data.value || !data.value.menu?.items) {
          throw new Error('No organization');
        }
      } else {
        let response;
        try {
          response = await httpClient.get<typeof data>(url);
        } catch (error) {
          bail(error);
          return;
        }

        if (
          response.status !== 200 ||
          !response.data.value ||
          !response.data.value.menu?.items
        ) {
          throw new Error('No organization');
        }

        data = response.data;
      }

      const { restaurant, menu, locations } = data.value!;

      return {
        restaurant,
        menu,
        locations: locations || [],
        fromSite: data.fromSite,
      };
    },
    { retries: 0, ...options?.retry },
  ).catch((error) => {
    if (error.message === 'No organization') {
      return null;
    }
    throw error;
  });
}

export async function getOrganizationFullByMsid(
  msid: string,
  baseUrl: string,
  httpClient?: IHttpClient,
) {
  const url = `${baseUrl}/_serverless/restaurants-organization-service-msid/v1/organization-full/${msid}`;

  if (!httpClient) {
    const response = await fetch(url);
    if (response.status === 200) {
      const {
        restaurant,
        menu,
      }: {
        restaurant: Restaurant;
        menu: Menu;
      } = await response.json();
      return {
        restaurant,
        menu,
      };
    }
  } else {
    const response = await httpClient.get<{
      restaurant: Restaurant;
      menu: Menu;
    }>(url);
    if (response.status === 200) {
      const { restaurant, menu } = response.data;
      return {
        restaurant,
        menu,
      };
    }
  }

  return undefined;
}

export async function getOrganizationFullByMsidV2(
  msid: string,
  baseUrl: string,
  httpClient?: IHttpClient,
) {
  const url = `${baseUrl}/_serverless/restaurants-organization-service-msid/v2/organization-full/${msid}`;

  if (!httpClient) {
    const response = await fetch(url);
    if (response.status === 200) {
      const { restaurant, menu } = await response.json();
      return {
        restaurant,
        menu,
      };
    }
  } else {
    const response = await httpClient.get<{
      restaurant: Restaurant;
      menu: Menu;
    }>(url);
    if (response.status === 200) {
      const { restaurant, menu } = response.data;
      return {
        restaurant,
        menu,
      };
    }
  }
  return undefined;
}

export async function getOrganizationFullByAppDefAndInstance(
  instanceId: string,
  appId: string,
  httpClient?: IHttpClient,
) {
  const url = `https://apps.wixrestaurants.com/organization/get/?instanceId=${instanceId}&appId=${appId}`;

  if (!httpClient) {
    const response = await fetch(url);
    if (response.status === 200) {
      const { value } = await response.json();
      const {
        restaurant,
        menu,
      }: { restaurant: Restaurant; menu: Menu } = value;
      return {
        restaurant,
        menu,
      };
    }
  } else {
    const response = await httpClient.get<{
      value?: { restaurant: Restaurant; menu: Menu };
    }>(url);
    if (response.status === 200 && response.data.value) {
      const { value } = response.data;
      const {
        restaurant,
        menu,
      }: { restaurant: Restaurant; menu: Menu } = value;
      return {
        restaurant,
        menu,
      };
    }
  }
  return undefined;
}

export async function getSettings(
  signedInstance: string,
  locationId?: string,
  httpClient?: IHttpClient,
) {
  if (!httpClient) {
    const { settings }: { settings: Restaurant } = await fetch(
      getSettingsUrl(locationId),
      {
        headers: {
          Authorization: signedInstance,
        },
      },
    ).then((r) => r.json());
    return settings;
  } else {
    const { settings } = await httpClient
      .get<{ settings: Restaurant }>(getSettingsUrl(locationId), {
        headers: {
          Authorization: signedInstance,
        },
      })
      .then((r) => r.data);
    return settings;
  }
}

/**
 * Fetch a restaurant.
 *
 * ## Options
 *  - host: should be set based on the environment this function is executed from, Editor/Viewer/BM. default is BM.
 *  - preloadMenus: set to true, if you need the restaurant menus.
 *  - retry: retry options
 *
 * -- In parallel
 *  * fetch organization using signedInstance
 *  * fetch locations using signedInstance
 *  * fetch menus using signedInstance
 *
 * The function will retry to fetch the restaurant in two cases:
 * * Get settings request returns 403 status and the reason description is `Failed to resolve restaurant by the provided instances ids`.
 * * Get menu request returns a menu without items property.
 */

export function fetchRestaurant<B extends boolean>(
  signedInstance: string,
  locationId?: string,
  options?: {
    host?: string;
    preloadMenus?: B;
    retry?: Parameters<typeof retry>[1];
  },
): B extends true
  ? Promise<
      | { restaurant: Restaurant; menu: LocationMenu; locations: Location[] }
      | undefined
    >
  : Promise<{ restaurant: Restaurant; locations: Location[] } | undefined>;

export async function fetchRestaurant(
  signedInstance: string,
  locationId?: string,
  options?: {
    host?: string;
    preloadMenus?: boolean;
    retry?: Parameters<typeof retry>[1];
  },
) {
  const default_options = {
    host: `https://${SERVERLESS_BASEURL}`,
    preloadMenus: false,
    retry: {},
    ...options,
  };
  const settingsURL = new URL(
    '/_api/restaurants/v2.1/settings',
    default_options.host,
  );
  settingsURL.search = locationId ? `locationId=${locationId}` : '';

  const locationsURL = new URL(
    '/_api/restaurants/v2.1/settings/locations',
    default_options.host,
  );

  const menusURL = new URL(
    '/_api/restaurants/v2.1/menus',
    default_options.host,
  );
  menusURL.search = locationId ? `locationId=${locationId}` : '';

  let restaurant: Restaurant | undefined;
  let locations: Location[] | undefined;
  let menu: LocationMenu | undefined;

  return retry(
    async (bail, _attempt) => {
      const [
        restaurantResult,
        locationsResult,
        menusResult,
      ] = await Promise.allSettled([
        restaurant === undefined
          ? fetch(settingsURL.toString(), {
              headers: { Authorization: signedInstance },
            })
          : Promise.resolve(undefined),
        locations === undefined
          ? fetch(locationsURL.toString(), {
              headers: { Authorization: signedInstance },
            })
          : Promise.resolve(undefined),
        default_options.preloadMenus && menu === undefined
          ? fetch(menusURL.toString(), {
              headers: { Authorization: signedInstance },
            })
          : Promise.resolve(undefined),
      ]);

      // handle promise rejects
      // if one of the calls rejected we would not continue to retry
      const rejects = [restaurantResult, locationsResult, menusResult].find(
        (result) => result.status === 'rejected',
      );
      if (rejects && rejects.status === 'rejected') {
        bail(rejects.reason);
        return;
      }

      if (restaurantResult.status === 'fulfilled' && restaurantResult.value) {
        const response = restaurantResult.value;
        const body = await response.json();

        if (response.ok) {
          restaurant = body.settings;
        } else if (
          response.status === 403 &&
          body.details.applicationError.description.includes(
            'Failed to resolve restaurant by the provided instances ids',
          )
        ) {
          throw new Error('No organization');
        } else {
          bail(body);
          return;
        }
      }

      if (locationsResult.status === 'fulfilled' && locationsResult.value) {
        const response = locationsResult.value;
        const body = await response.json();

        if (response.ok) {
          locations = body.locations;
        } else {
          locations = [];
        }
      }

      if (menusResult.status === 'fulfilled' && menusResult.value) {
        const response = menusResult.value;
        const body = await response.json();

        if (response.ok) {
          if (body.menu.items === undefined) {
            throw new Error('No organization');
          } else {
            menu = body.menu;
          }
        } else {
          bail(body);
          return;
        }
      }

      return {
        restaurant,
        locations,
        ...(default_options.preloadMenus ? { menu } : {}),
      };
    },
    { retries: 6, ...default_options.retry },
  ).catch((error) => {
    if (error.message === 'No organization') {
      return undefined;
    }
    throw new Error(error);
  });
}
