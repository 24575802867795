import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import { getLayout, isLegacyLayout } from '../utils/layoutSelector';
import { getDisplayableSection, convertItems } from '../utils/dataStructures';
import {
  getNavigateableSections,
  getSelectedSection,
  shouldBeCentered,
} from '../utils/navigation';
import {
  hasPermiumProducts,
  getStyleSettings,
  validateProps,
} from '../utils/menusAppHelper';
import MenusNavigation from './MenusNavigation';
import MenusNavigationMobile from './MenusNavigationMobile';
import './MenusApp.less';
import documentLogic from '../utils/documentLogic';

class MenusApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedSection: null };
  }

  componentDidMount() {
    this.props.onMount();
    documentLogic();

    if (this.props.organization && this.props.currentLocation) {
      this.props.getOrganizationMenu(
        this.props.organization.id,
        this.props.currentLocation,
      );
    }
  }

  componentDidUpdate(prevProps) {
    this.props.setHeight();
    documentLogic();

    if (
      // if it is the first time we have an organization fetch the menus or if the current location changed
      (this.props.organization && !prevProps.organization) ||
      (this.props.organization &&
        this.props.currentLocation &&
        this.props.currentLocation !== prevProps.currentLocation)
    ) {
      this.props.getOrganizationMenu(
        this.props.organization.id,
        this.props.currentLocation,
      );
    }
  }

  render() {
    if (!validateProps(this.props)) {
      return null;
    }

    const {
      menu,
      organization,
      styles,
      compId,
      isFacebook,
      deviceType,
      webpEnabled,
      locations,
      currentLocation,
      showEmptyState,
      i18n,
      getOrganizationMenu,
    } = this.props;
    const { currency, locale } = organization;
    const {
      priceRange,
      layoutId,
      priceHidden,
      variationsHidden,
      displaySectionImages,
      descriptionHidden,
      sectionImagesHidden,
      displayIcons,
      iconType,
      primaryColor,
      secondaryColor,
    } = getStyleSettings(styles, compId, organization);
    const isMobile = deviceType === 'mobile';
    const navigateableSections = getNavigateableSections({
      menu,
      styles,
      locale,
      compId,
      isMobile,
    });
    const defaultSection = getSelectedSection({
      navigateableSections,
      sectionId: null,
    });
    const shouldShowEmptyState =
      showEmptyState || navigateableSections.length === 0;
    const isPremium = hasPermiumProducts(organization);
    const isLegacy = isLegacyLayout(layoutId);

    if (shouldShowEmptyState) {
      return (
        <div className="MenusApp_EmptyState">
          {i18n.get(
            'openrest_wixrestaurantmenuapplicationview_nomenustodisplay',
            'There are currently no menus to display',
          )}
        </div>
      );
    }

    const convertedItems = convertItems(
      menu,
      menu.items,
      currency,
      locale,
      priceRange,
      isMobile,
    );

    return (
      <div
        className={classNames(['MenusApp', isLegacy && 'MenusAppLegacy'])}
        data-hook="wixrest-menus-app"
      >
        {map(navigateableSections, (selectedSection) => {
          const section = getDisplayableSection({
            menu,
            currency,
            locale,
            sectionId: selectedSection.id,
            priceRange,
            isMobile,
            convertedItems,
          });

          const navigationProps = {
            navigateableSections,
            selectedSection,
            isFacebook,
            isLegacy,
            centered: shouldBeCentered(layoutId, isFacebook),
            onChange: (selectedSection) => this.setState({ selectedSection }),
            orgId: organization.id,
            locations,
            currentLocation,
            getOrganizationMenu,
            i18n,
          };

          const layoutProps = {
            menu: [section],
            priceHidden,
            webpEnabled,
            variationsHidden,
            isMobile,
            isRtl: i18n.isRtlDir(),
            TabsComponent: ({ headingText }) => (
              <MenusNavigation {...navigationProps} headingText={headingText} />
            ),
            MobileTabsComponent: ({ headingText }) => (
              <MenusNavigationMobile
                {...navigationProps}
                headingText={headingText}
              />
            ),
            i18n,
            displaySectionImages,
            styles,
            compId,
            isPremium,
            descriptionHidden,
            sectionImagesHidden,
            displayIcons,
            iconType,
            primaryColor,
            secondaryColor,
          };

          const style = {};
          if (selectedSection.id !== defaultSection.id) {
            style.display = 'none';
          }

          return (
            <div
              data-hook="wixrest-menus-section-wrapper"
              data-wrapper-id={selectedSection.id}
              key={selectedSection.id}
              style={style}
            >
              {getLayout({ layoutId, layoutProps, isFacebook, isMobile })}
            </div>
          );
        })}
      </div>
    );
  }
}

MenusApp.displayName = 'MenusApp';
MenusApp.propTypes = {
  isDemo: PropTypes.bool,
  isFacebook: PropTypes.bool,
  compId: PropTypes.string,
  viewMode: PropTypes.string,
  deviceType: PropTypes.string,
  webpEnabled: PropTypes.bool,
  i18n: PropTypes.object,
  menu: PropTypes.object,
  organization: PropTypes.object,
  styles: PropTypes.object,
  setHeight: PropTypes.func,
  onMount: PropTypes.func,
};
MenusApp.defaultProps = {
  webpEnabled: false,
  requestedSectionId: '',
  onMount: () => {},
};

export default MenusApp;
