import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './MenusNavigationMobile.less';
import Dropdown from './Dropdown';
import LocationsDropdown from './LocationsDropdown';

export default function MenusNavigationMobile(props) {
  const {
    navigateableSections,
    centered,
    orgId,
    locations,
    currentLocation,
    getOrganizationMenu,
    i18n,
  } = props;
  const singleSectionWithoutDescription =
    navigateableSections.length === 1 && !navigateableSections[0].description;

  if (
    (navigateableSections.length === 0 || singleSectionWithoutDescription) &&
    (!locations || locations.length === 0)
  ) {
    return null;
  }

  function handleChange(event) {
    props.onChange(
      navigateableSections.find((section) => section.id === event.target.value),
    );
  }

  const selectedSection = props.selectedSection || {};
  const selectedSectionTitle = selectedSection.title;
  const selectedSectionDescription = selectedSection.description;
  const hasMultipleOptions = navigateableSections.length >= 2;

  const className = classNames([
    'MenusNavigationMobile',
    centered && !hasMultipleOptions && 'MenusNavigationMobileCentered',
  ]);

  return (
    <div className={className}>
      {locations && locations.length > 1 ? (
        <div className="dropdown-wrapper">
          <div className="dropdown-description">
            {i18n.get(
              'openrest_wixrestaurantmenuview_location_label',
              'Location',
            )}
          </div>
          <LocationsDropdown
            isMobile
            locations={locations}
            currentLocation={currentLocation}
            orgId={orgId}
            getOrganizationMenu={getOrganizationMenu}
          />
        </div>
      ) : undefined}
      {hasMultipleOptions ? (
        <div className="dropdown-wrapper">
          <div className="dropdown-description">
            {i18n.get('openrest_wixrestaurantmenuview_menu_label', 'Menu')}
          </div>
          <Dropdown
            dataHook="section-select"
            isMobile
            value={selectedSection.id}
            onChange={handleChange}
            options={navigateableSections}
          />
        </div>
      ) : (
        <div className="section-title-wrapper">
          <div className="selected-section-title">{selectedSectionTitle}</div>
        </div>
      )}
      {selectedSectionDescription && (
        <div className="section-title-description" data-hook="nav-description">
          {selectedSectionDescription}
        </div>
      )}
    </div>
  );
}

MenusNavigationMobile.displayName = 'MenusNavigationMobile';
MenusNavigationMobile.propTypes = {
  navigateableSections: PropTypes.array,
  selectedSection: PropTypes.object,
  onChange: PropTypes.func,
  centered: PropTypes.bool,
};
