require('./RestaurantMenuTableViewDoubleColumnSectionImages.less');

import I18n from '@wix/wixrest-utils/dist/I18n';
import ImageUtils from 'openrest4js/dist/openrest4js-helpers/Image';
import RestaurantMenuTableViewDottedLine from '../../logic/RestaurantMenuTableViewDottedLine.js';
import Masonry from 'react-masonry-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import createReactClass from 'create-react-class';

const { i18n } = I18n.getComponentHeader('');

const masonryOptions = {
  transitionDuration: 0,
  columnWidth: '.grid-sizer',
  itemSelector: '.grid-item',
  gutter: '.gutter-sizer',
  percentPosition: true,
};

//************************* Templates
class REACT_MENU_CATEGORY extends React.Component {
  static displayName = 'legacy/ReactMenuCategory';

  render() {
    const This = this;
    return (
      <div className="category" data-id={this.props.id}>
        <div className="title">{this.props.title}</div>
        <div className="description">{this.props.description}</div>
        <div className="categorychildren">
          {_.map(this.props.children, function(subcategory) {
            const data = subcategory.data;
            const children = subcategory.children;
            return (
              <REACT_MENU_SUBCATEGORY
                title={data.title}
                description={data.description}
                id={data.id}
                styles={This.props.styles}
                picture={data.picture}
                children={children}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                sectionImagesHidden={This.props.sectionImagesHidden}
                isMobile={This.props.isMobile}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class REACT_MENU_SUBCATEGORY extends React.Component {
  static displayName = 'ReactMenuSubCategory';

  render() {
    const This = this;
    const children = JSON.parse(JSON.stringify(this.props.children));
    if (!this.props.isMobile && children.length % 2 === 1) {
      children.push({});
    }

    const descriptionHidden = this.props.descriptionHidden;

    let descriptionClass = 'description ';
    if (!this.props.description || descriptionHidden) {
      descriptionClass += 'hidden ';
    }

    const styles = this.props.styles;

    const titleStyle = {
      fontWeight: 'normal !important',
    };

    if (!styles.fonts['wixmenu-subcategorytitle'].supports.uppercase) {
      titleStyle['text-transform'] = 'none';
    }

    const descriptionStyle = {
      fontStyle: 'normal !important',
      width: this.props.isMobile ? '100%' : '50%',
      margin: 'auto',
    };

    const placeholderHidden = !styles.booleans[
      'wixmenu.widgets.placeholderimages'
    ];

    const backgroundDiv = (() => {
      if (this.props.picture && !this.props.sectionImagesHidden) {
        const background = {
          backgroundImage:
            'url(' +
            ImageUtils.fillSharp({
              url: this.props.picture,
              width: 900,
              height: 295,
            }) +
            ')',
        };
        return (
          <div className="background-wrapper">
            <div className="background" style={background}></div>
          </div>
        );
      } else if (!placeholderHidden) {
        return (
          <div className="background-wrapper">
            <div className="background empty"></div>
          </div>
        );
      } else {
        return null;
      }
    })();

    const preTitleBackgroundDiv = this.props.isMobile ? null : backgroundDiv;
    const postTitleBackgroundDiv = this.props.isMobile ? backgroundDiv : null;

    return (
      <div className={'subcategory'} data-id={this.props.id} ref="wrapper">
        <div className="titledescription" style={{ textAlign: 'center' }}>
          {preTitleBackgroundDiv}
          <h1 className="title" style={titleStyle}>
            {this.props.title}
          </h1>
          <div className={descriptionClass} style={descriptionStyle}>
            {this.props.description}
          </div>
          {postTitleBackgroundDiv}
        </div>
        <Masonry className="subcategorychildren" options={masonryOptions}>
          <div className="grid-sizer"></div>
          <div className="gutter-sizer"></div>
          {_.map(children, function(item) {
            const data = item.data || {};
            const children = item.children;
            return (
              <REACT_MENU_ITEM
                title={data.title}
                description={data.description}
                price={data.price}
                children={children}
                styles={styles}
                featured={data.featured}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                isMobile={This.props.isMobile}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </Masonry>
      </div>
    );
  }
}

const REACT_MENU_ITEM = createReactClass({
  displayName: 'ReactMenuItem',
  mixins: [RestaurantMenuTableViewDottedLine],
  render() {
    const descriptionHidden = this.props.descriptionHidden;
    const priceHidden = this.props.priceHidden;
    const styles = this.props.styles;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const itemClasses = cx({
      item: true,
      'grid-item': true,
      featured: this.props.featured,
    });

    const descriptionClasses = cx({
      description: true,
      hidden: descriptionHidden || !this.props.description,
    });

    const priceClasses = cx({
      price: true,
      hidden: priceHidden,
    });

    const This = this;

    this._fixDottedLine({
      shouldFixFunc() {
        if (priceHidden || !This.props.price) return false;
        return true;
      },

      alignLineRef:
        descriptionHidden || !This.props.description ? 'title' : 'description',
      priceRef: 'price',
    });

    const titleDots = this._getDotsDom();

    const titleStyle = {
      fontWeight: 'bold !important',
      position: 'relative',
    };

    const descriptionStyle = {
      fontStyle: 'normal !important',
    };

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    _.defer(() => {
      if (typeof window !== 'undefined') {
        // Fix width so price doesn't overlap the first line
        const titleNode = $(ReactDOM.findDOMNode(This.refs.title));
        const priceNode = $(ReactDOM.findDOMNode(This.refs.price));

        if (priceHidden || !This.props.price) {
          titleNode.css({ 'padding-right': '0px', 'padding-left': '0px' });
        } else {
          if (i18n.isRtlDir()) {
            titleNode.css({
              'padding-right': '0px',
              'padding-left': priceNode.width() + 10 + 'px',
            });
          } else {
            titleNode.css({
              'padding-left': '0px',
              'padding-right': priceNode.width() + 10 + 'px',
            });
          }
        }
      }
    });

    const displayFeatured = this.props.featured ? (
      <REACT_MENU_ITEM_FEATURED />
    ) : null;
    return (
      <div
        className={itemClasses}
        data-is-last-item={this.props.isLastItem}
        ref="wrapper"
      >
        <div className="titledescription">
          <div className="title" style={titleStyle}>
            <div ref="title">
              <span
                style={{ fontWeight: '800 !important', textTransform: 'none' }}
              >
                {displayFeatured}
                {this.props.title}
              </span>
              {titleDots}
            </div>

            <div className={priceClasses} style={priceStyle} ref="price">
              {this.props.price}
            </div>
          </div>
          <div
            className={descriptionClasses}
            ref="description"
            style={descriptionStyle}
          >
            {this.props.description}
          </div>
        </div>
        <div className="itemchildren">
          {_.map(this.props.children, function(item) {
            const data = item.data;
            return (
              <REACT_MENU_VARIATIONITEM
                styles={styles}
                title={data.title}
                description={data.description}
                price={data.price}
                descriptionHidden={This.props.descriptionHidden}
                priceHidden={This.props.priceHidden}
                variationsHidden={This.props.variationsHidden}
                isMobile={This.props.isMobile}
                key={This.props.id + '-' + data.id}
                id={This.props.id + '-' + data.id}
              />
            );
          })}
        </div>
      </div>
    );
  },
});

class REACT_MENU_ITEM_FEATURED extends React.Component {
  static displayName = 'ReactMenuItemFeatured';

  render() {
    return (
      <svg width="23px" height="24px" viewBox="0 0 23 24">
        <path
          fillRule="evenodd"
          d="M 11.5 17.86C 11.5 17.86-0 24-0 24-0 24-0 0-0 0-0 0 23 0 23 0 23 0 23 24 23 24 23 24 11.5 17.86 11.5 17.86Z"
        />
        <path
          className="star"
          fillRule="evenodd"
          d="M 6.5 0C 6.5 0 7.93 4.58 7.93 4.58 7.93 4.58 12.99 4.58 12.99 4.58 12.99 4.58 8.81 7.09 8.81 7.09 8.81 7.09 10.51 11.98 10.51 11.98 10.51 11.98 6.5 8.64 6.5 8.64 6.5 8.64 2.49 11.98 2.49 11.98 2.49 11.98 4.2 7.09 4.2 7.09 4.2 7.09 0.01 4.58 0.01 4.58 0.01 4.58 5.08 4.58 5.08 4.58 5.08 4.58 6.5 0 6.5 0"
        />
      </svg>
    );
  }
}

class REACT_MENU_VARIATIONITEM extends React.Component {
  static displayName = 'ReactMenuVariationItem';

  render() {
    const priceHidden = this.props.priceHidden;
    const variationsHidden = this.props.variationsHidden;

    function cx(params) {
      return _.map(params, function(val, key) {
        if (val) {
          return key;
        } else {
          return '';
        }
      }).join(' ');
    }

    const priceClasses = cx({
      price: true,
      hidden: priceHidden || !this.props.price,
    });
    const variationsClasses = cx({
      variationitem: true,
      hidden: variationsHidden,
    });
    const titleStyle = {
      fontStyle: 'normal !important',
    };

    const priceStyle = {
      fontStyle: 'normal !important',
    };

    return (
      <div
        className={variationsClasses}
        data-is-last-variation-item={this.props.isLastVariationItem}
        style={titleStyle}
      >
        <span className="title" style={titleStyle}>
          {this.props.title}
        </span>
        <span className={priceClasses} style={priceStyle}>
          {this.props.price}
        </span>
      </div>
    );
  }
}

export default class extends React.Component {
  static displayName =
    'menus/web/RestaurantMenuTableViewDoubleColumnSectionImages.js';

  render() {
    const This = this;
    const menu = this.props.menu;
    const styles = this.props.styles;

    const mode = this.props.isMobile ? 'mobile' : 'web';

    return (
      <div
        className={
          'openrest_restaurantmenutableviewdoublecolumnwithsectionimages_legacy_wrapper noncenter ' +
          mode
        }
      >
        {_.map(menu, category => {
          const data = category.data;
          const children = category.children;
          return (
            <REACT_MENU_CATEGORY
              title={data.title}
              description={data.description}
              id={data.id}
              children={children}
              styles={styles}
              descriptionHidden={This.props.descriptionHidden}
              priceHidden={This.props.priceHidden}
              variationsHidden={This.props.variationsHidden}
              sectionImagesHidden={This.props.sectionImagesHidden}
              isMobile={This.props.isMobile}
              key={data.id}
              id={data.id}
            />
          );
        })}
      </div>
    );
  }

  scrollToCategory = id => {
    console.log(
      '[RestaurantMenuTableViewDoubleColumnSectionImages] scrollToCategory. id=',
      id,
    );
    const dom = $(ReactDOM.findDOMNode(this)).find(
      '.subcategory[data-id="' + id + '"]',
    );
    console.log(
      '[RestaurantMenuTableViewDoubleColumnSectionImages] scrollToCategory. Dom=',
      dom,
    );

    Wix.getBoundingRectAndOffsets(data => {
      console.log(
        '[RestaurantMenuTableViewDoubleColumnSectionImages] scrollToCategory. Bounding=',
        data,
      );
      Wix.scrollTo(0, data.offsets.y + dom.offset().top - 20);
    });
  };
}
