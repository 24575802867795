import I18n from '@wix/wixrest-utils/dist/I18n';
import _ from 'lodash';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

const { i18n } = I18n.getComponentHeader('');

export default {
  _fixDottedLine(params) {
    const shouldFixFunc =
      params.shouldFixFunc ||
      (() => {
        return true;
      });

    const priceRef = params.priceRef || 'price';
    const dottedlineRef = params.dottedlineRef || 'dottedline';
    const dottedlineMarkerRef = params.dottedlineRef || 'dottedlinemarker';
    const alignLineRef = params.alignLineRef || 'title';
    const runAgain = params.runAgain;

    const This = this;

    _.defer(() => {
      if (!shouldFixFunc()) {
        return;
      }

      if (!This.refs[priceRef]) return;
      if (!This.refs[dottedlineMarkerRef]) return;
      if (!This.refs[dottedlineRef]) return;

      // Step 1: Make sure the dotted lines are correct
      let a = $(ReactDOM.findDOMNode(This.refs[priceRef])).position().left;

      if (i18n.isRtlDir()) {
        a += $(ReactDOM.findDOMNode(This.refs[priceRef])).width();
      }

      let b = $(ReactDOM.findDOMNode(This.refs[dottedlineMarkerRef])).position()
        .left;
      if (i18n.isRtlDir()) {
        b += $(ReactDOM.findDOMNode(This.refs[dottedlineMarkerRef])).width();
      }

      let left = Math.floor(b + 5);
      const width = Math.floor(Math.abs(a - b) - 10);

      if (i18n.isRtlDir()) {
        left = Math.floor(a + 5);
      }

      $(ReactDOM.findDOMNode(This.refs[dottedlineRef])).css({
        left: left + 'px',
        width: width + 'px',
      });
      $(ReactDOM.findDOMNode(This.refs[dottedlineRef])).text(
        '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .',
      );

      // Step 2: Make sure the price is aligned correctly to the bottom
      const dom = $(ReactDOM.findDOMNode(This.refs[alignLineRef]));

      const bottomPadding = parseInt(
        (parseInt(dom.css('lineHeight')) - parseInt(dom.css('fontSize'))) / 2,
      );
      $(ReactDOM.findDOMNode(This.refs[priceRef])).css(
        'paddingBottom',
        bottomPadding + 'px',
      );
      $(ReactDOM.findDOMNode(This.refs[dottedlineRef])).css(
        'paddingBottom',
        bottomPadding + 'px',
      );
    });

    // Ugly patch, but needed. Run twice to make sure the new calculations took place...
    if (runAgain != false) {
      setTimeout(() => {
        This._fixDottedLine(_.extend({}, { runAgain: false }, params));
      }, 100);
    }
  },

  _getDotsDom() {
    return (
      <span role="presentation" aria-hidden>
        <span ref="dottedlinemarker">&nbsp;</span>
        <span ref="dottedline" className="dottedline"></span>
      </span>
    );
  },
};
