import { Properties } from './common';
import { Address } from './Address';

export type DispatchType = 'delivery' | 'premises' | 'takeout';

export type VirtualDispatchType = DispatchType | 'dine-in';

export const DINE_IN: VirtualDispatchType = 'dine-in';

export interface DeliveryProvider {
  configurationId: string;
  estimateId: string;
}

export interface AbstractDispatch {
  type: DispatchType;
  time?: number;
  timeGuarantee?: string;
  charge?: number;
  properties?: Properties;
}

export interface PickupDispatch extends AbstractDispatch {
  type: 'takeout';
  isCurbside?: boolean;
  curbsideAdditionalInformation?: string;
  contactlessDineIn?: ContactlessDineIn;
}

export type ContactlessDineIn = {
  label: string;
  labelValue: string;
};

export interface DeliveryDispatch extends AbstractDispatch {
  type: 'delivery';
  address: Address;
  deliveryProvider?: DeliveryProvider;
}

export interface PremisesDispatch extends AbstractDispatch {
  type: 'premises';
  comment: string;
}

export type Dispatch = PickupDispatch | DeliveryDispatch | PremisesDispatch;
