import {
  Payment,
  Order,
  PaymentType,
  FulfillmentType,
  OnArrival,
  Status,
  Type as ChannelInfoType,
} from '@wix/ambassador-restaurants-v3-order/types';
import { lowerCase, get, padStart } from 'lodash';
import { FieldInfo, FieldValueCallbackWithoutField } from 'json2csv';
import { BasicFieldInfo } from './types';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';

interface FormatField {
  fieldInfo: BasicFieldInfo<Order>;
  timeZone?: string;
}

type MappedFieldValue = (
  fieldInfo: BasicFieldInfo<Order>,
  options: Record<string, any>,
) => FieldInfo<Order>[] | FieldInfo<Order>;

type PaymentFilter = (payment: Payment) => boolean;

const CASH_PAYMENTS_FILTER: PaymentFilter = (payment: Payment): boolean => {
  const OFFLINE = 'offline';
  const isCash = payment.type === PaymentType.CASH;
  const isCashierCash =
    payment.type === PaymentType.WIX_PAYMENTS && payment.method === OFFLINE;
  return isCash || isCashierCash;
};

const CARD_PAYMENTS_FILTER: PaymentFilter = (payment: Payment): boolean => {
  const CREDIT_CARD = 'creditCard';
  const isCredit = payment.type === PaymentType.CREDIT;
  const isCashierCredit =
    payment.type === PaymentType.WIX_PAYMENTS && payment.method === CREDIT_CARD;
  return isCredit || isCashierCredit;
};

function paymentField(
  fieldInfo: BasicFieldInfo<Order>,
  paymentFilter: PaymentFilter,
): FieldValueCallbackWithoutField<Order> {
  return (order: Order) => {
    const payments = get(order, fieldInfo.value);
    return payments === undefined
      ? 0
      : payments
          .filter(paymentFilter)
          .reduce((acc: number, currentPayment: Payment) => {
            const paymentAmount = Number(currentPayment.amount);
            return acc + paymentAmount;
          }, 0);
  };
}

// This function is used to format a date string to a YYYY-MM-DD format: 1.1.1970 -> 1970-1-1
function formatDateString(dateString: string): string {
  const IL_DATE_DELIMITER = /\./g;
  const DATE_STRING_DELIMITER = '-';
  return dateString
    .replace(IL_DATE_DELIMITER, DATE_STRING_DELIMITER)
    .split(DATE_STRING_DELIMITER)
    .reverse()
    .join(DATE_STRING_DELIMITER);
}

function formatDate(
  fieldInfo: BasicFieldInfo<Order>,
  options: Record<string, any>,
): FieldInfo<Order> {
  const DEFAULT_TIME_ZONE = 'America/New_York';
  const DEFAULT_LOCALE = 'he-IL';
  const TWO_DIGITS_NUMBER = '2-digit';
  const LOCALE_STRING_DELIMITER = ',';
  const { timeZone } = options;
  const DATE_STRING_OPTIONS = {
    hour12: false,
    timeZone: timeZone ?? DEFAULT_TIME_ZONE,
    year: 'numeric',
    month: TWO_DIGITS_NUMBER,
    day: TWO_DIGITS_NUMBER,
    hour: TWO_DIGITS_NUMBER,
    minute: TWO_DIGITS_NUMBER,
    second: TWO_DIGITS_NUMBER,
  };
  const value = (order: Order): string | undefined => {
    const time = get(order, fieldInfo.value);
    const date = new Date(time);
    const isDateValid = !isNaN(date.getTime());
    if (isDateValid) {
      // The toLocaleString function should return a date string in the format of: DD.MM.YYYY, HH:MM:SS -> 1.1.1970, 00:00:00
      const localeDateString = date.toLocaleString(
        DEFAULT_LOCALE,
        DATE_STRING_OPTIONS,
      );

      // We will split the toLocaleString format to ['DD.MM.YYYY', ' HH:MM:SS']; with the ',' delimiter.
      // Then we will reconstruct it back to the desired 'YYYY-MM-DD HH:mm:ss' format.
      const [dateString, timeString] = localeDateString.split(
        LOCALE_STRING_DELIMITER,
      );
      const formattedDateString = formatDateString(dateString);
      return `${formattedDateString}${timeString}`;
    } else {
      return undefined;
    }
  };

  return {
    ...fieldInfo,
    value,
  };
}

function formatPayments(fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order>[] {
  const cashValue = paymentField(fieldInfo, CASH_PAYMENTS_FILTER);
  const cardValue = paymentField(fieldInfo, CARD_PAYMENTS_FILTER);

  return [
    { label: 'cash', value: cashValue, default: '0' },
    { label: 'card', value: cardValue, default: '0' },
  ];
}

// This mapping is used to format each and every field to match the V2 Style
// This is temporary and may be changed later on when we will migrate users to view the V3 values
const FieldsMapping: Record<string, MappedFieldValue> = {
  'channelInfo.type': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const channelInfo: string = get(order, fieldInfo.value);
      return channelInfo &&
        channelInfo !== ChannelInfoType.UNSPECIFIED_CHANNEL_TYPE
        ? lowerCase(channelInfo)
        : undefined;
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  createdDate: formatDate,
  'fulfillment.asap': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const isAsap: boolean = get(order, fieldInfo.value);
      switch (isAsap) {
        case true:
          return 'before';
        case false:
          return 'about';
        default:
          return undefined;
      }
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'fulfillment.deliveryDetails.address.onArrival': (
    fieldInfo: BasicFieldInfo<Order>,
  ): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const onArrival: string = get(order, fieldInfo.value, '');
      switch (onArrival) {
        case OnArrival.CALL_ME:
          return 'phone';
        case OnArrival.BUZZ_DOOR:
          return 'buzz';
        default:
          return undefined;
      }
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'fulfillment.deliveryDetails.address.zipCode': (
    fieldInfo: BasicFieldInfo<Order>,
  ): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const zipCode: string = get(order, fieldInfo.value, '');
      return zipCode ? padStart(zipCode, 5, '0') : undefined;
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'fulfillment.promisedTime': formatDate,
  'fulfillment.submitAt': formatDate,
  'fulfillment.type': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const fulfillmentType: string = get(order, fieldInfo.value);
      switch (fulfillmentType) {
        case FulfillmentType.DINE_IN:
          return 'dine-in';
        case FulfillmentType.PICKUP:
          return 'takeout';
        case FulfillmentType.DELIVERY:
          return 'delivery';
        default:
          return undefined;
      }
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  payments: formatPayments,
  status: (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): string | undefined => {
      const status: string = get(order, fieldInfo.value);
      if (status) {
        return status === Status.FULFILLED
          ? lowerCase(Status.ACCEPTED)
          : lowerCase(status);
      } else {
        return undefined;
      }
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.discount': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const discount: string = get(order, fieldInfo.value, '');
      return discount ? -Number(discount) : 0;
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.subtotal': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const subtotal: string = get(order, fieldInfo.value, '0');
      return Number(subtotal);
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.total': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const total: string = get(order, fieldInfo.value, '0');
      return Number(total);
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.delivery': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const delivery: string = get(order, fieldInfo.value, '0');
      return Number(delivery);
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.tip': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const tip: string = get(order, fieldInfo.value, '0');
      return Number(tip);
    };

    return {
      ...fieldInfo,
      value,
    };
  },

  'totals.serviceFees': (
    fieldInfo: BasicFieldInfo<Order>,
  ): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const serviceFees = get(order, fieldInfo.value, '');
      const totalServiceFeesAmount = serviceFees.reduce(
        (prev: number, currServiceFee: CalculatedFee) => {
          return prev + parseFloat(currServiceFee?.fee?.value || '0');
        },
        0,
      );

      return totalServiceFeesAmount
        ? Number(totalServiceFeesAmount.toFixed(2))
        : 0;
    };

    return {
      ...fieldInfo,
      value,
    };
  },
  'totals.tax': (fieldInfo: BasicFieldInfo<Order>): FieldInfo<Order> => {
    const value = (order: Order): number | undefined => {
      const serviceFees = get(order, 'totals.serviceFees', '');
      const tax = parseFloat(get(order, fieldInfo.value, '0'));

      const totalServiceFeesTaxAmount = serviceFees.reduce(
        (prev: number, currServiceFee: CalculatedFee) => {
          return prev + parseFloat(currServiceFee?.tax?.value || '0');
        },
        0,
      );
      const totalTaxAndFees = totalServiceFeesTaxAmount + tax;

      return totalTaxAndFees ? Number(totalTaxAndFees.toFixed(2)) : 0;
    };

    return {
      ...fieldInfo,
      value,
    };
  },
};

export function formatField({
  fieldInfo,
  timeZone,
}: FormatField): FieldInfo<Order>[] | FieldInfo<Order> {
  const fieldGenerator = FieldsMapping[fieldInfo.value];
  return fieldGenerator === undefined
    ? fieldInfo
    : fieldGenerator(fieldInfo, { timeZone });
}
