let locale: string;
let intlImp: typeof Intl;

const arabicDigits = [
  String.fromCharCode(1632),
  String.fromCharCode(1633),
  String.fromCharCode(1634),
  String.fromCharCode(1635),
  String.fromCharCode(1636),
  String.fromCharCode(1637),
  String.fromCharCode(1638),
  String.fromCharCode(1639),
  String.fromCharCode(1640),
  String.fromCharCode(1641),
];

const ARABIC_COMMA = String.fromCharCode(1643);
const arabicRegex = new RegExp(arabicDigits.join('|'), 'g');
const arabicCommaRegex = new RegExp(ARABIC_COMMA, 'g');

const getIntl = () => {
  if (intlImp) {
    return intlImp;
  } else {
    return Intl;
  }
};

export const getLocalSeparator = (_locale = locale) => {
  _locale = _locale.replace(/_/, '-');

  const num = getIntl()
    .NumberFormat(_locale, { style: 'decimal' })
    .format(12.5);

  return num.indexOf(',') > -1 ? ',' : '.';
};

export const parse = (text: string, _locale = locale) => {
  _locale = _locale.replace(/_/, '-');

  // First, test if the current locale is with ',' or '.' as a fraction separator
  // Possible enhancement - to cache the result based on locale
  const num = getIntl()
    .NumberFormat(_locale, { style: 'decimal' })
    .format(12.5);

  if (num.indexOf(',') > -1) {
    text = text.replace(/\./g, '').replace(/\s/g, '').replace(/\,/g, '.');
  } else if (num.indexOf(ARABIC_COMMA) > -1) {
    text = text
      .replace(/\./g, '')
      .replace(/\s/g, '')
      .replace(arabicCommaRegex, '.');
  } else {
    text = text.replace(/\,/g, '').replace(/\s/g, '');
  }

  if (text.match(arabicRegex)) {
    text = text.replace(arabicRegex, (c) => {
      return String(arabicDigits.indexOf(c));
    });
  }

  return parseFloat(text);
};

export const format = (
  number: number,
  opt?: Intl.NumberFormatOptions,
  _locale = locale,
) => {
  _locale = _locale.replace(/_/, '-');

  opt = opt || { style: 'decimal' };

  return getIntl().NumberFormat(_locale, opt).format(number);
};

export const init = (_locale: string, _intlImp: typeof Intl) => {
  intlImp = _intlImp || intlImp || Intl;
  locale = _locale.replace(/_/, '-');
};

export const initIntl = (_intlImp: typeof Intl) => {
  intlImp = _intlImp;
};
