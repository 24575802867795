import { PriceRangeValue } from '../types/Seo';

export function priceWithoutCurrency(priceWithCurrency: string) {
  return priceWithCurrency?.replace(/[^0-9\.]+/g, '');
}

export function getPriceRangeValuesDifferentCases(
  basePrice: string,
  minPrice: string,
  maxPrice: string,
): PriceRangeValue[] {
  const basePriceWithoutCurrency = priceWithoutCurrency(basePrice);
  const minPriceWithoutCurrency = priceWithoutCurrency(minPrice);
  const maxPriceWithoutCurrency = priceWithoutCurrency(maxPrice);
  if (
    allZeroValuesOrNotFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return [{ priceStr: '', priceStrWithoutCurrenfy: '' }]; // all not fill
  }
  if (
    onlyMaxAndMinNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    if (minPrice !== maxPrice) {
      return [
        {
          priceStr: minPrice,
          priceStrWithoutCurrenfy: minPriceWithoutCurrency,
        },
        {
          priceStr: maxPrice,
          priceStrWithoutCurrenfy: maxPriceWithoutCurrency,
        },
      ]; // min+max fill
    } else {
      return [
        {
          priceStr: minPrice,
          priceStrWithoutCurrenfy: minPriceWithoutCurrency,
        },
      ];
    }
  }
  if (
    onlyBaseAndMaxNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return [
      {
        priceStr: basePrice,
        priceStrWithoutCurrenfy: basePriceWithoutCurrency,
      },
      { priceStr: maxPrice, priceStrWithoutCurrenfy: maxPriceWithoutCurrency },
    ]; // base+max fill
  }
  if (
    onlyBaseAndMinNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    if (
      parseFloat(basePriceWithoutCurrency) > parseFloat(minPriceWithoutCurrency)
    ) {
      return [
        {
          priceStr: basePrice,
          priceStrWithoutCurrenfy: basePriceWithoutCurrency,
        },
      ];
    } else {
      return [
        {
          priceStr: minPrice,
          priceStrWithoutCurrenfy: minPriceWithoutCurrency,
        },
      ];
    }
  }
  if (
    allFilledAndNotZeroValues(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    if (
      parseFloat(basePriceWithoutCurrency) > parseFloat(minPriceWithoutCurrency)
    ) {
      if (basePrice !== maxPrice) {
        return [
          {
            priceStr: basePrice,
            priceStrWithoutCurrenfy: basePriceWithoutCurrency,
          },
          {
            priceStr: maxPrice,
            priceStrWithoutCurrenfy: maxPriceWithoutCurrency,
          },
        ];
      } else {
        return [
          {
            priceStr: basePrice,
            priceStrWithoutCurrenfy: basePriceWithoutCurrency,
          },
        ];
      }
    } else {
      if (minPrice !== maxPrice) {
        return [
          {
            priceStr: minPrice,
            priceStrWithoutCurrenfy: minPriceWithoutCurrency,
          },
          {
            priceStr: maxPrice,
            priceStrWithoutCurrenfy: maxPriceWithoutCurrency,
          },
        ];
      } else {
        return [
          {
            priceStr: minPrice,
            priceStrWithoutCurrenfy: minPriceWithoutCurrency,
          },
        ];
      }
    }
    // all fill
  }
  return Valid(basePriceWithoutCurrency)
    ? [
        {
          priceStr: basePrice,
          priceStrWithoutCurrenfy: basePriceWithoutCurrency,
        },
      ]
    : Valid(minPriceWithoutCurrency)
    ? [{ priceStr: minPrice, priceStrWithoutCurrenfy: minPriceWithoutCurrency }]
    : [
        {
          priceStr: maxPrice,
          priceStrWithoutCurrenfy: maxPriceWithoutCurrency,
        },
      ];
}

export function getPriceWithoutCurrencyDifferentCases(
  basePrice: string,
  minPrice: string,
  maxPrice: string,
) {
  const basePriceWithoutCurrency = priceWithoutCurrency(basePrice);
  const minPriceWithoutCurrency = priceWithoutCurrency(minPrice);
  const maxPriceWithoutCurrency = priceWithoutCurrency(maxPrice);
  if (
    allZeroValuesOrNotFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return ''; // how to not return the field?//all not fill
  }
  if (
    onlyMaxAndMinNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return minPriceWithoutCurrency; // min+max fill
  }
  if (
    onlyBaseAndMaxNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return basePriceWithoutCurrency; // base+max fill
  }
  if (
    onlyBaseAndMinNotZeroValuesAndFilled(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return Math.max(
      parseFloat(basePriceWithoutCurrency),
      parseFloat(minPriceWithoutCurrency),
    ).toString(); // base+min fill
  }
  if (
    allFilledAndNotZeroValues(
      basePriceWithoutCurrency,
      minPriceWithoutCurrency,
      maxPriceWithoutCurrency,
    )
  ) {
    return Math.max(
      parseFloat(basePriceWithoutCurrency),
      parseFloat(minPriceWithoutCurrency),
    ).toString(); // all fill
  }
  return Valid(basePriceWithoutCurrency)
    ? basePriceWithoutCurrency
    : Valid(minPriceWithoutCurrency)
    ? minPriceWithoutCurrency
    : maxPriceWithoutCurrency;
}

export function allZeroValuesOrNotFilled(
  basePriceWithoutCurrency: string,
  minPriceWithoutCurrency: string,
  maxPriceWithoutCurrency: string,
) {
  if (
    !Valid(basePriceWithoutCurrency) &&
    !Valid(maxPriceWithoutCurrency) &&
    !Valid(minPriceWithoutCurrency)
  ) {
    return true;
  }
  return false;
}
export function allFilledAndNotZeroValues(
  basePriceWithoutCurrency: string,
  minPriceWithoutCurrency: string,
  maxPriceWithoutCurrency: string,
) {
  if (
    Valid(basePriceWithoutCurrency) &&
    Valid(maxPriceWithoutCurrency) &&
    Valid(minPriceWithoutCurrency)
  ) {
    return true;
  }
  return false;
}
export function onlyBaseAndMaxNotZeroValuesAndFilled(
  basePriceWithoutCurrency: string,
  minPriceWithoutCurrency: string,
  maxPriceWithoutCurrency: string,
) {
  if (
    Valid(basePriceWithoutCurrency) &&
    Valid(maxPriceWithoutCurrency) &&
    !Valid(minPriceWithoutCurrency)
  ) {
    return true;
  }
  return false;
}
export function onlyBaseAndMinNotZeroValuesAndFilled(
  basePriceWithoutCurrency: string,
  minPriceWithoutCurrency: string,
  maxPriceWithoutCurrency: string,
) {
  if (
    Valid(basePriceWithoutCurrency) &&
    !Valid(maxPriceWithoutCurrency) &&
    Valid(minPriceWithoutCurrency)
  ) {
    return true;
  }
  return false;
}
export function onlyMaxAndMinNotZeroValuesAndFilled(
  basePriceWithoutCurrency: string,
  minPriceWithoutCurrency: string,
  maxPriceWithoutCurrency: string,
) {
  if (
    !Valid(basePriceWithoutCurrency) &&
    Valid(maxPriceWithoutCurrency) &&
    Valid(minPriceWithoutCurrency)
  ) {
    return true;
  }
  return false;
}

export function Valid(priceStr: string) {
  if (
    priceStr &&
    !isNaN(parseFloat(priceStr)) &&
    parseFloat(priceStr) !== 0 &&
    parseFloat(priceStr) !== 0.0 &&
    parseFloat(priceStr) !== 0.0
  ) {
    return true;
  }
  return false;
}

export function fromPriceListToPriceRange(priceObjectsList: PriceRangeValue[]) {
  const priceObjectsListSorted: PriceRangeValue[] = priceObjectsList.sort(
    function (a: PriceRangeValue, b: PriceRangeValue) {
      return (
        parseFloat(a.priceStrWithoutCurrenfy) -
        parseFloat(b.priceStrWithoutCurrenfy)
      );
    },
  );

  if (priceObjectsListSorted.length === 0) {
    return '';
  }
  const priceRange =
    priceObjectsListSorted[0].priceStr +
    '-' +
    priceObjectsListSorted[priceObjectsListSorted.length - 1].priceStr;

  return priceRange;
}
