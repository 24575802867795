import { IHttpClient } from '@wix/http-client';
import { PropertiesInfo } from '../types/Restaurant';
import _ from 'lodash';
import { SERVERLESS_BASEURL } from '../constants';

const propertiesUrl = `https://${SERVERLESS_BASEURL}/_api/restaurants/v2.1/settings/properties`;

type PropertiesResponse = {
  properties: PropertiesInfo;
};

export async function getProperties(
  signedInstance: string,
  httpClient?: IHttpClient,
) {
  try {
    if (!httpClient) {
      const response = await fetch(propertiesUrl, {
        headers: {
          Authorization: signedInstance,
        },
      });

      if (response.status === 200) {
        const data: PropertiesResponse = await response.json();
        return data.properties;
      }

      throw new Error(
        `status: ${response.status}, message: ${response.statusText}`,
      );
    } else {
      const response = await httpClient.get<PropertiesResponse>(propertiesUrl, {
        headers: {
          Authorization: signedInstance,
        },
      });

      if (response.status === 200) {
        return response.data.properties;
      }

      throw new Error(
        `status: ${response.status}, message: ${response.statusText}`,
      );
    }
  } catch (error) {
    throw new Error(`status: 500, message: ${error}`);
  }
}

export async function setProperties(
  signedInstance: string,
  newProperties: PropertiesInfo,
  httpClient?: IHttpClient,
) {
  if (!httpClient) {
    const response = await fetch(propertiesUrl, {
      method: 'PUT',
      body: JSON.stringify({ properties: newProperties }),
      headers: {
        Authorization: signedInstance,
      },
    });

    if (response.status === 200) {
      const data: PropertiesResponse = await response.json();
      return data.properties;
    }

    throw new Error(response.statusText);
  } else {
    const response = await httpClient.put<PropertiesResponse>(
      propertiesUrl,
      { properties: newProperties },
      {
        headers: {
          Authorization: signedInstance,
        },
      },
    );

    if (response.status === 200) {
      return response.data.properties;
    }

    throw new Error(response.statusText);
  }
}
