import _ from 'lodash';

export function slugCreatorFactory() {
  const usedStrings: string[] = [];

  return function slugCreator(str: string): string {
    const kebabCase = _.kebabCase(str);
    let result = kebabCase;
    let i = 1;
    while (usedStrings.includes(result)) {
      result = `${kebabCase}-${i++}`;
    }
    usedStrings.push(result);
    return result;
  };
}
